// Styles
import { styled } from '@mui/material/styles';
// material
import { Tab, Tabs, Badge } from '@mui/material';

export const StyledTab = styled(Tab)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'visible',
  fontWeight: '500',
  borderColor: theme.palette.bg.dark,
  backgroundColor: theme.palette.bg.dark,
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginInline: theme.spacing(0.7),
  textTransform: 'inherit!important',
  minHeight: '25px',
  '&:first-of-type': {
    marginLeft: theme.spacing(0),
  },
  '&:last-child': {
    marginRight: theme.spacing(0),
  },
  '&.Mui-selected': {
    fontWeight: '700',
    background: 'rgb(238 238 238)',
  },
  [theme.breakpoints.up('md')]: {
    textTransform: 'uppercase!important',
    marginInline: theme.spacing(3),
    '&:first-of-type': {
      marginLeft: theme.spacing(3),
    },
    '&:last-child': {
      marginRight: theme.spacing(3),
    },
    fontSize: '16px',
    minHeight: '45px',
  },
}));

export const StyledMateriaTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '15px',
  overflow: 'visible',
  [theme.breakpoints.up('md')]: {
    minHeight: '45px',
  },
  '& .MuiTabs-scroller': {
    overflow: 'visible!important'
  }
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: '60%',
    top: '-25%',
    background: theme.palette.secondary.main,
    [theme.breakpoints.up('md')]: {
      top: '-35%',
    },
    padding: '0 8px',
  },
}));