import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';

// components
import HistoryCallModalMenuItemIcon from './HistoryCallModalMenuItem';
import HistoryCallModalPriorityButton from './HistoryCallModalPriorityButton';
// containers
import HistoryCallNewMessageContainer from '../containers/HistoryCallNewMessageContainer';
import HistoryCallModalSelectCollaboratorContainer from '../containers/HistoryCallModalSelectCollaboratorContainer';
import HistoryCallModalSelectFolderContainer from '../containers/HistoryCallModalSelectFolderContainer';
import HistoryCallMessageContainer from '../containers/HistoryCallMessageContainer';
import HistoryCallModalHelperPopoverContainer from '../containers/HistoryCallModalHelperPopoverContainer';
import HistoryCallClientDenominationContainer from '../containers/HistoryCallClientDenominationContainer';
// material
import {
  Box,
  Dialog,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  InputLabel,
  Select,
  FormControl,
  IconButton,
  Grid,
  Divider,
  Slide,
  AppBar,
  Toolbar,
  DialogContent,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MessageIcon from '@mui/icons-material/Message';
// styled
import { StyledMenuItem } from "../styles/HistoryCallModalStyle";
// const
import {
  HISTORY_NORMAL_PRIORITY,
  HISTORY_CALLBACK_PRIORITY,
  HISTORY_URGENT_PRIORITY,
  HISTORY_DEFAULT_PRIORITY,
  HISTORY_STATUS_CLOSED,
  HISTORY_STATUS_OPENED,
  HISTORY_STATUS_DEFAULT,
} from '../redux/calls/reducerCalls'
import { PAGE_CALL_HISTORY } from '../pages';

// doit etre en dehors du composant sinon le dialog ne s'enleve pas du dom a la fermeture
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const HistoryCallModal = ({
  id,
  callId,
  isDisplayedHistoryCallModal,
  saveIsDisplayedHistoryCallModal,
  postHistoryCall,
  historyCalls,
  client,
  historyCallMessages,
  connectedUserUuid,
  callStatus,
  saveHistoryCall,
  deleteCallByCollaboratorUuid,
  saveTabIndex,
  updateHistoryCall,
  saveSnackbar,
}) => {
  // mui tools
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  // le state du ticket sera transmis au middleware uniquement a la creation/maj du ticket
  const [historyCall, setHistoryCall] = useState({
    id,
    object: '',
    description: '',
    priority: HISTORY_DEFAULT_PRIORITY,
    collaboratorUuid: '',
    status: HISTORY_STATUS_DEFAULT,
    // format de la date transmise par firebase si un new Date() est post
    updatedAt: new Date(),
    folderId: 0,
  });
  
  const defaultError = {
    object: undefined,
    description: undefined,
    priority: undefined,
    collaboratorUuid: undefined,
  }
  // gere les erreur de soumission du formulaire
  const [error, setError] = useState(defaultError);

  // consts
  const isNewHistoryCall = historyCall.status === HISTORY_STATUS_DEFAULT;
  const isClosedHistoryCall = historyCall.status === HISTORY_STATUS_CLOSED;
  const isCallEnded = "call_ended" === callStatus;

  const currentHystoryCall = historyCalls.filter(
    (historyCall) => historyCall.id === id
  )[0];

  // si l'historique est déjà présent en bdd met à jour le formuluraire
  useEffect(() => {
    if (currentHystoryCall) {
      setHistoryCall(currentHystoryCall)
    }
  }, [currentHystoryCall])

  // gestions des erreurs du formulaire
  const checkFormError = (status) => {
    let isError = false;
    let newError = {};

    if ('' === historyCall.object) {
      isError = true;
      newError = { ...newError, object: 'Objet non selectionné' }
    }

    if ('' === historyCall.description) {
      isError = true;
      newError = { ...newError, description: 'Saisie de la description obligatoire' }
    }

    const MAX_LENGTH_DESCRIPTION = 750;
    if (historyCall.description.length > MAX_LENGTH_DESCRIPTION) {
      isError = true;
      newError = { ...newError, description: `La description ne peut pas dépasser ${MAX_LENGTH_DESCRIPTION} caractères` }
    }

    if (HISTORY_DEFAULT_PRIORITY === historyCall.priority) {
      isError = true;
      newError = { ...newError, priority: 'Priorité non selectionnée' }
    }

    if (HISTORY_STATUS_CLOSED === status) {
      if (historyCall.collaboratorUuid !== connectedUserUuid) {
        isError = true;
        newError = { ...newError, collaboratorUuid: 'Vous devez être le collaborateur en charge pour clôturer le ticket' }
      }
    }

    if ('' === historyCall.collaboratorUuid) {
      isError = true;
      newError = { ...newError, collaboratorUuid: 'Collaborateur non selectionné' }
    }

    const priorities = [
      HISTORY_NORMAL_PRIORITY,
      HISTORY_CALLBACK_PRIORITY,
      HISTORY_URGENT_PRIORITY,
      HISTORY_DEFAULT_PRIORITY,
    ]
    if (!priorities.includes(historyCall.priority)) {
      isError = true;
      newError = { ...newError, priority: 'Valeur invalide' }
    }
    setError({ ...error, ...newError});

    return isError;
  }

  const handleOnClickSubmitClosedButton = () => {
    // verifie les erreur dans le cas ou l'historique est fermé
    if (!checkFormError(HISTORY_STATUS_CLOSED)) {
      const newHistoryCall = {
        ...historyCall,
        status: HISTORY_STATUS_CLOSED,
        updatedAt: new Date(),
      }
      // differencie si l'appel est terminé ou en cours
      // si l'appel est en cours il sera mis en bdd au moment de call_ended
      if (isCallEnded) {
        postHistoryCall(newHistoryCall);
      } else {
        updateHistoryCall(newHistoryCall);
      }
      saveSnackbar('Clôture du ticket réussie', 'success')
      saveIsDisplayedHistoryCallModal(callId, false)
    }
    else {
      saveSnackbar('Echec de la clôture du ticket', 'error')
    }
  }

  // créer ou met à jour un historique
  const handleOnClickSubmitCreateButton = () => {
    // verifie les erreur dans le cas ou l'historique est ouvert
    if (!checkFormError(HISTORY_STATUS_OPENED)) {
      const newHistoryCall = {
        ...historyCall,
        status: HISTORY_STATUS_OPENED,
        updatedAt: new Date(),
      }
      // differencie si l'appel est terminé ou en cours
      // si l'appel est en cours il sera mis en bdd au moment de call_ended
      if (isCallEnded) {
        postHistoryCall(newHistoryCall);
      } else {
        if (historyCalls.filter(({ id }) => historyCall.id === id)?.[0]) {
          updateHistoryCall(newHistoryCall);
        } else {
          saveHistoryCall(newHistoryCall);
        }
      }
      saveIsDisplayedHistoryCallModal(callId, false)
      saveSnackbar(`${isNewHistoryCall ? 'Création' : 'Mise à jour'} du ticket réussie`, 'success')
    }
    else {
      saveSnackbar(`Echec de la ${isNewHistoryCall ? 'création' : 'mise à jour'} du ticket`, 'error')
    }
  }

  const handleOnClickReopenHistoryCall = () => {
    const newHistoryCall = {
      ...historyCall,
      status: HISTORY_STATUS_OPENED,
      updatedAt: new Date(),
    }

    // differencie si l'appel est terminé ou en cours
    // si l'appel est en cours il sera mis en bdd au moment de call_ended
    if (isCallEnded) {
      postHistoryCall(newHistoryCall);
      if (historyCall.collaboratorUuid !== connectedUserUuid) {
        deleteCallByCollaboratorUuid({ id: callId })
      }
    } else {
      updateHistoryCall(newHistoryCall);
    }
    if (PAGE_CALL_HISTORY === location.pathname) {
      saveTabIndex(PAGE_CALL_HISTORY, HISTORY_STATUS_OPENED)
    }
  }

  // affiche le message d'erreur
  const getEmptyErrorTypography = (error) => (
    undefined !== error && (
      <Typography component="p" sx={{ "textAlign": "left", mb: 1, display: 'flex', alignItems: 'center' }} color="error">
        <ErrorOutlineIcon sx={{ mr: 1 }} />{error}
      </Typography>
    )
  )

  const handleOnCloseModal = () => {
    setError({});
    saveIsDisplayedHistoryCallModal(callId, false);
  }

  // ferme la creation du ticket lorsque l'on click en dehors du PaperComponent du dialog
  const handleOnClickCloseHistoryCall = (e) => {
    if(e.target !== e.currentTarget.querySelector('.MuiDialog-container')) return;
    handleOnCloseModal()
  }

  // permet de repasser historyCall.folderId à sa valeur par defaut (0)
  const resetFolderId = () => {
    setHistoryCall({ ...historyCall, folderId: 0})
  }

  return (
    <Dialog
      open={isDisplayedHistoryCallModal}
      TransitionComponent={Transition}
      maxWidth={'md'}
      fullWidth
      fullScreen={fullScreen}
      onClick={handleOnClickCloseHistoryCall}
      PaperProps={{
        sx: {
          minWidth: `calc(400px - (${theme.spacing(4)} * 4))`,
          textAlign: 'center',
        },
      }}
    >
      <Box 
        sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
        {/* Header */}
        <AppBar sx={{
          position: 'relative',
          background: `linear-gradient(to right bottom, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
          paddingRight: '17px!important',// pour compenser le disable scrow du dialog
        }}>
          <Toolbar>
            <Typography color={'white'} sx={{
              margin: 'auto',
              fontSize: ['24px!important', '30px!important'],
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }}>
              { isNewHistoryCall ? "Création d'un ticket" : "Mise à jour" }
            </Typography>
            <IconButton
              size="large"
              aria-label="close"
              component="div"
              title="fermer"
              onClick={handleOnCloseModal}
              sx={{ position: 'absolute', top: [3, 12], right: [-8, -6], p: 0 }}
            >
              <CloseOutlinedIcon color='white'/>
            </IconButton>
            {/* Affiche la date de creation et le createur du ticket si le ticket a déjà été crée */}
            {
              !isNewHistoryCall && (
                <HistoryCallModalHelperPopoverContainer historyId={id} />
              )
            }
          </Toolbar>
        </AppBar>
        <DialogContent sx={{
          overflowY: 'scroll',
        }}>
          <HistoryCallClientDenominationContainer
            handleOnCloseModal={handleOnCloseModal}
            historyCallId={id}
            resetFolderId={resetFolderId}
            historyCallStatus={historyCall.status}
          />
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ padding: theme.spacing(4), }}
          >
            {/* Formulaire de création */}
            <Box sx={{ mb: historyCallMessages.length === 0 || isNewHistoryCall ? 4 : 0 }} >
              {/* Selection de l'object de l'appel */}
              {/* Object error */}
              {getEmptyErrorTypography(error?.object)}
              <FormControl fullWidth sx={{ marginBottom: 4 }}>
                <InputLabel sx={{ cursor: !isClosedHistoryCall ? 'text' : 'not-allowed' }} id="history-call-object-label">Objet</InputLabel>
                <Select
                  labelId="history-call-object-label"
                  id="history-call-oject-select"
                  value={historyCall.object}
                  label="Objet"
                  readOnly={isClosedHistoryCall}
                  onChange={(event) => {
                    setError({ ...error, object: undefined });
                    setHistoryCall({ ...historyCall, object: event.target.value });
                  }}
                  inputProps={
                    {
                      sx: { cursor: isClosedHistoryCall ? 'not-allowed' : 'text' }
                    }
                  }
                  sx={
                    error?.object && (
                      {
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: 'red',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'red',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'red',
                        },
                      }
                    )
                  }
                >
                  <StyledMenuItem value='Comptabilité'>
                    <HistoryCallModalMenuItemIcon itemName='Comptabilité' />
                  </StyledMenuItem>
                  <StyledMenuItem value='Personnel'>
                    <HistoryCallModalMenuItemIcon itemName='Personnel' />
                  </StyledMenuItem>
                  <StyledMenuItem value='Prise de RDV'>
                    <HistoryCallModalMenuItemIcon itemName='Prise de RDV' />
                  </StyledMenuItem>
                  <StyledMenuItem value='Renseignements'>
                    <HistoryCallModalMenuItemIcon itemName='Renseignements' />
                  </StyledMenuItem>
                  <StyledMenuItem value='Autres...'>
                    <HistoryCallModalMenuItemIcon itemName='Autres...' />
                  </StyledMenuItem>
                </Select>
              </FormControl>
              {/* Description error */}
              {getEmptyErrorTypography(error?.description)}
              {/* Description de l'appel */}
              <TextField
                id="descriptionInput"
                label="Description"
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                value={historyCall.description}
                InputProps={{
                  readOnly: isClosedHistoryCall
                }}
                onChange={(event) => {
                  setError({ ...error, description: undefined });
                  setHistoryCall({ ...historyCall, description: event.target.value });
                }}
                sx={
                  error?.description
                    ? ({
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                      },
                      marginBottom: 4,
                    })
                    : ({
                      marginBottom: 4,
                      textarea: { cursor: isClosedHistoryCall ? 'not-allowed' : 'text' },
                      label: { cursor: isClosedHistoryCall ? 'not-allowed' : 'text' },
                      '&:hover .MuiInputBase-root': {
                        cursor: isClosedHistoryCall ? 'not-allowed' : 'text'
                      },
                    })
                }
              />
              {/* collaborateur error */}
              {getEmptyErrorTypography(error?.collaboratorUuid)}
              {/* Selection du collaborateur */}
              <Box sx={
                error?.collaboratorUuid
                  ? ({
                    border: 'solid 1px red',
                    borderRadius: '4px',
                    marginBottom: 4,
                  })
                  : ({
                    marginBottom: 4,
                  })
              }>
                <HistoryCallModalSelectCollaboratorContainer readOnly={isClosedHistoryCall} historyCall={historyCall} setHistoryCall={setHistoryCall} error={error} setError={setError} defaultCollaboratorUuid={currentHystoryCall?.collaboratorUuid}/>
              </Box>
              {/* Selection d'un dossier */}
              <Box sx={{ marginBottom: 4 }}>
                {client && 0 !== client?.id && (
                  <HistoryCallModalSelectFolderContainer
                    readOnly={isClosedHistoryCall}
                    historyCall={historyCall}
                    setHistoryCall={setHistoryCall}
                    clientId={client?.id ?? 0}
                  />
                )}
              </Box>
              {/* priorité error */}
              {getEmptyErrorTypography(HISTORY_DEFAULT_PRIORITY !== error?.priority ? error?.priority : undefined)}
              {/* Selection de la priorité */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ButtonGroup
                  sx={
                    error?.priority === undefined
                      ? (
                        { textAlign: 'center', width: '100%' }
                      )
                      : (
                        {
                          textAlign: 'center',
                          width: '100%',
                          border: 'solid 1px red',
                          borderRadius: '4px'
                        }
                      )
                  }
                  variant="outlined"
                  aria-label="outlined button group"
                  size="small"
                >
                  <HistoryCallModalPriorityButton
                    readOnly={isClosedHistoryCall}
                    historyCall={historyCall}
                    setHistoryCall={setHistoryCall}
                    color="secondary"
                    newPriority={HISTORY_NORMAL_PRIORITY}
                    error={error}
                    setError={setError}
                  >
                    Normal
                  </HistoryCallModalPriorityButton>
                  <HistoryCallModalPriorityButton
                    readOnly={isClosedHistoryCall}
                    historyCall={historyCall}
                    setHistoryCall={setHistoryCall}
                    color="warning"
                    newPriority={HISTORY_CALLBACK_PRIORITY}
                    error={error}
                    setError={setError}
                  >
                    Rappellera
                  </HistoryCallModalPriorityButton>
                  <HistoryCallModalPriorityButton
                    readOnly={isClosedHistoryCall}
                    historyCall={historyCall}
                    setHistoryCall={setHistoryCall}
                    color="error"
                    newPriority={HISTORY_URGENT_PRIORITY}
                    error={error}
                    setError={setError}
                  >
                    Urgent
                  </HistoryCallModalPriorityButton>
                </ButtonGroup>
              </Box>
            </Box>
            {/* Messages */}
            {/* 
                si le ticket est ouvert 
                ou si le ticket est fermé et a des messages 
                et si ce n'est pas un nouveau ticket
                et si l'appel est terminé
              */}
            {((!isClosedHistoryCall || (isClosedHistoryCall && Object.keys(historyCallMessages).length > 0))
              && !isNewHistoryCall
            ) && isCallEnded
              && (
                <>
                  <Divider variant="middle" sx={{ my: 8, mx: 0, backgroundColor: 'secondary.light' }} />
                  <Box>
                    {/* Title */}
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 3,
                    }}>
                      <MessageIcon color='secondary' sx={{ mr: 2 }} />
                      <Typography>
                        Messages
                      </Typography>
                    </Box>
                    {/* Nouveau message */}
                    {!isClosedHistoryCall &&
                      <HistoryCallNewMessageContainer historyCallId={id} />
                    }
                    {/* Liste messages */}
                    {historyCallMessages.map((historyCallMessage) => (
                      Object.keys(historyCallMessage).length !== 0 && (
                        <HistoryCallMessageContainer key={historyCallMessage.id} historyCallMessage={historyCallMessage} />
                      )
                    ))}
                  </Box>
                </>
              )}
            {/* Validation du formulaire */}
            <Grid container spacing={2}>
              {!isClosedHistoryCall
                ? (
                  <>
                    <Grid item xs={!isNewHistoryCall ? 6 : 12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="success"
                        onClick={handleOnClickSubmitCreateButton}
                        disabled={
                          // si l'historique n'a pas changé
                          JSON.stringify(currentHystoryCall) === JSON.stringify(historyCall)
                          // si l'erreur de formulaire n'a pas été corrigé
                          || JSON.stringify(defaultError) !== JSON.stringify(error)
                        }
                      >
                        {isNewHistoryCall ? 'Créer' : 'Mettre à jour'}
                      </Button>
                    </Grid>
                    {!isNewHistoryCall &&
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={handleOnClickSubmitClosedButton}
                          disabled={JSON.stringify(defaultError) !== JSON.stringify(error)}
                        >
                          Cloturer
                        </Button>
                      </Grid>
                    }
                  </>
                )
                : (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleOnClickReopenHistoryCall}
                    >
                      Ouvrir le ticket
                    </Button>
                  </Grid>
                )
              }
            </Grid>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

HistoryCallModal.propTypes = {
  id: PropTypes.string.isRequired,
  callId: PropTypes.string.isRequired,
  isDisplayedHistoryCallModal: PropTypes.bool.isRequired,
  saveIsDisplayedHistoryCallModal: PropTypes.func.isRequired,
  postHistoryCall: PropTypes.func.isRequired,
  historyCalls: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // object de l'historique
      object: PropTypes.string.isRequired,
      // contenue de l'historique
      description: PropTypes.string.isRequired,
      // indique si l'historique est urgent, a rappeler ou normal
      priority: PropTypes.number.isRequired,
      // collabateur en charge de l'historique
      collaboratorUuid: PropTypes.string.isRequired,
      // status du ticket : HISTORY_STATUS_OPENED, HISTORY_STATUS_CLOSED
      status: PropTypes.number.isRequired,
      // date de la derniere mise à jour de l'historique
      updatedAt: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // identifiant du dossier genapi
      folderId: PropTypes.number.isRequired,
    }).isRequired,
  ),
  // client si le numéro de l'historique existe dans Clients
  client: PropTypes.exact({
    denomination: PropTypes.string.isRequired,
    email: PropTypes.string,
    id: PropTypes.number.isRequired,
    telephones: PropTypes.arrayOf(
      PropTypes.exact({
        typeTelephone: PropTypes.string.isRequired,
        numero: PropTypes.string.isRequired,
      }),
    ),
    tenantId: PropTypes.string.isRequired,
  }),
  // uuid de l'utilisateur connecté
  connectedUserUuid: PropTypes.string.isRequired,
  // permet de sauvegarder un historyCall dans le state calls.
  saveHistoryCall: PropTypes.func.isRequired,
  // supprime le call des historique cloturer
  deleteCallByCollaboratorUuid: PropTypes.func.isRequired,
  // tabIndex indique qu'elle onglet sera affiché
  saveTabIndex: PropTypes.func.isRequired,
   // affiche une snackbar pour la creation/maj d'un ticket
  saveSnackbar: PropTypes.func.isRequired,
}

export default HistoryCallModal;