import { useEffect } from 'react';
import PropTypes from 'prop-types';
// Composants
import Loader from './Loader';

// MUI
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

// icons
import CloseIcon from '@mui/icons-material/Close';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    minWidth: `calc(700px - (${theme.spacing(4)} * 4))`,
    textAlign: 'center',
  }
}));

const WazoOdooLinkDialog = ({
  isDisplayedWazoOdooModal,
  saveIsDisplayedWazoOdooModal,
  isConnectedToOdoo,
  fetchIsConnectedToOdoo,
  isConnectingToOdoo,
  email,
  wazoUserUuid,
}) => {  
  const theme = useTheme();

  useEffect(() => {
    fetchIsConnectedToOdoo(email, wazoUserUuid);
  }, [fetchIsConnectedToOdoo, email, wazoUserUuid])

  useEffect(() => {
    if (!isConnectedToOdoo) {
      saveIsDisplayedWazoOdooModal(true);
    }
  }, [saveIsDisplayedWazoOdooModal, isConnectedToOdoo])

  const handleFetchIsConnectedOdoo = () => {
    fetchIsConnectedToOdoo(email, wazoUserUuid);
  }

  return (
    <>
      <BootstrapDialog
        onClose={() => saveIsDisplayedWazoOdooModal(false)}
        aria-labelledby="customized-dialog-title"
        open={isDisplayedWazoOdooModal}
      >
        <DialogTitle 
          sx={{
            m: 0,
            padding: '0.65rem',
            background: `linear-gradient(to top, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
            height: '2rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{display: 'flex'}}>
            {
              isConnectedToOdoo 
                ? (
                  <WifiIcon sx={{ color: '#FFF', mr: '0.5rem' }} />
                )
                : (
                  <WifiOffIcon sx={{ color: '#FFF', mr: '0.5rem' }} />
                )
            }
            <Typography sx={{ color: '#fff' }}>Connexion à Odoo</Typography>
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => saveIsDisplayedWazoOdooModal(false)}
          title='fermer'
          sx={{
            position: 'absolute',
            right: 0,
            color: 'common.white',
            marginRight: '0.25rem',
            marginTop: '0.25rem',
            '&:hover': {
              borderRadius: '100%',
              background: "#FFFFFF30",
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {
            isConnectedToOdoo 
              ? (
                <>
                  <Typography gutterBottom>
                    Vous êtes actuellement connecté à Odoo.
                  </Typography>
                  <Typography>
                    Vous pouvez fermer cette fenêtre.
                  </Typography>
                </>
              ) 
              : (
                isConnectingToOdoo 
                  ? (
                    <Box>
                      <Loader size={50} color="secondary" message={'Connexion en cours...'}/>
                    </Box>
                  )
                  : (
                    <>
                      <Typography gutterBottom>
                        La tentative de connexion à Odoo a rencontré un problème.
                      </Typography>
                      <Typography gutterBottom>
                        Assurez-vous que l'adresse e-mail utilisée pour vous connecter à votre Softphone Wazo correspond exactement à celle utilisée pour vous connecter à Odoo.
                      </Typography>
                      <Typography>
                        Si le problème persiste, n'hésitez pas à contacter notre service d'assistance au 04 92 04 05 53, option 1, ou par e-mail à interventions@eurofiber.com.
                      </Typography>
                    </>
                  )
              )
          }
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'right'}}>
          {
            isConnectedToOdoo
              ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => saveIsDisplayedWazoOdooModal(false)}
                  disabled={isConnectingToOdoo}
                >
                  Fermer
                </Button>
              ) 
              : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFetchIsConnectedOdoo}
                  disabled={isConnectingToOdoo}
                >
                  Connexion
                </Button>
              )
          }
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

WazoOdooLinkDialog.propTypes = {
  isDisplayedWazoOdooModal: PropTypes.bool.isRequired,
  saveIsDisplayedWazoOdooModal: PropTypes.func.isRequired,
  isConnectedToOdoo: PropTypes.bool.isRequired,
  fetchIsConnectedToOdoo: PropTypes.func.isRequired,
  isConnectingToOdoo: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  wazoUserUuid: PropTypes.string.isRequired,
}

export default WazoOdooLinkDialog;