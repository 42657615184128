// Creation d'un token
const getAuth = async (saveAuthToken, store) => {
  try {
    // Options for the fetch request
    const options = {
      method: 'POST',
      headers: {
        'accept': 'application/ld+json',
        'Content-Type': 'application/ld+json', // Specify the content type as JSON
      },
      body: JSON.stringify({
        username: process.env.REACT_APP_API_LOGIN,
        password: process.env.REACT_APP_API_PASSWORD,
      }) // Convert data to JSON string
    };

    // Get current date
    let currentDate = new Date();
    // Get time in milliseconds and convert to seconds
    let seconds = Math.floor(currentDate.getTime() / 1000);
    // Creation de la date d'expiration du token
    let apiTokenExpiresAt = seconds + 3600;

    const rawAuth = await fetch(`https://${process.env.REACT_APP_API_SERVER}/auth`, options);
    const authJson = await rawAuth.json();
    const auth = authJson['token'];

    store.dispatch(saveAuthToken(
      authJson['token'],
      authJson['refresh_token'],
      authJson['refresh_token_expiration'],
      apiTokenExpiresAt
    ));

    return auth
  } catch (error) {
    console.log('GET_AUTH_TOKEN - req', error);
  }
}

// Genere un nouveau token a partir du refresh token
const refreshToken = async (refresh_token, store, saveAuthToken) => {
  try {
    // Options for the fetch request
    const options = {
      method: 'POST',
      headers: {
        'accept': 'application/ld+json',
        'Content-Type': 'application/ld+json', // Specify the content type as JSON
      },
      body: JSON.stringify({ refresh_token: refresh_token }), // Convert data to JSON string
    };

    // Get current date
    let currentDate = new Date();
    // Get time in milliseconds and convert to seconds
    let seconds = Math.floor(currentDate.getTime() / 1000);
    // Creation de la date d'expiration du token
    // let apiTokenExpiresAt = seconds + 3600;
    let apiTokenExpiresAt = seconds + 30;
    const rawToken = await fetch(`https://${process.env.REACT_APP_API_SERVER}/auth/refresh`, options);
    const authTokenJson = await rawToken.json();
    let auth = authTokenJson['token'];

    store.dispatch(saveAuthToken(
      authTokenJson['token'],
      authTokenJson['refresh_token'],
      authTokenJson['refresh_token_expiration'],
      apiTokenExpiresAt
    ));

    return auth
  } catch (error) {
    console.log('RESET_API_TOKEN - req', error);
  }
}

export const customFetchApi = async (store, saveAuthToken, params, options, handleErrors) => {
  // Get current date
  const currentDate = new Date();
  // Get time in milliseconds and convert to seconds
  const seconds = Math.floor(currentDate.getTime() / 1000);

  let token = store.getState().login.authToken;
  if (store.getState().login.refreshTokenExpiration < seconds) {
    console.log('REFRESH_TOKEN_EXPIRÉ')
    token = await getAuth(saveAuthToken, store);
  }

  if (store.getState().login.apiTokenExpiresAt < seconds) {
    console.log('TOKEN_EXPIRÉ')
    token = await refreshToken(store.getState().login.refreshTokenApi, store, saveAuthToken);
  }

  // Update du token dans les options de la requête
  options.headers["Authorization"] = 'Bearer ' + token;

  try {
    const rawData = await fetch(`https://${process.env.REACT_APP_API_SERVER}${params}`, options);
    return rawData
  } catch (error) {
    console.log(`FETCH_DATA ~ ${params} ~ error:`, error.code, '---', error.name, '---', error)
    handleErrors(`FETCH_DATA ~ ${params}`, error);
  }
}