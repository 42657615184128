import { connect } from "react-redux";
import HistoryCallModalSelectCollaborator from "../components/HistoryCallModalSelectCollaborator";
// actions
// import { fetchUsers } from "../redux/users/actionUsers";

const mapStateToProps = (state) => ({
  users: state.users.users,
  isFetchingUsers: state.users.isFetchingUsers,
})

// const mapDispatchToProps = (dispatch) => ({

// })

export default connect(mapStateToProps)(HistoryCallModalSelectCollaborator)