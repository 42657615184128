import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// components
import Duration from './Duration';
import HistoryCallObjectIcon from './HistoryCallObjectIcon';
import CallNumberMenu from './CallNumberMenu';
import TypographyTooltip from './TypographyTooltip';


//Containers
import ActivityModalContainer from '../containers/ActivityModalContainer';

// Styled Components
import { StyledPaper } from "../styles/CallStyle";

// MUI
import {
  Typography,
  Box,
  Grid,
  Link,
} from '@mui/material';

// MUI ICONS
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EastIcon from '@mui/icons-material/East';
import NotesIcon from '@mui/icons-material/Notes';
import PersonIcon from '@mui/icons-material/Person';

// CONST
import {
  ACTIVITY_NORMAL_PRIORITY,
  ACTIVITY_PLAN_PRIORITY,
  ACTIVITY_URGENT_PRIORITY,
  ACTIVITY_MODERATE_PRIORITY,
} from '../redux/activities/reducerActivities'
import CallTags from './CallTags';

const Call = ({
  activity,
  users,
  callNumber,
  date,
  direction,
  duration,
  id,
  saveIsDisplayedActivityModal,
  tags,
}) => {
  const [displayedTags, setDisplayedTags] = useState([]);

  const borderColor = {
    [ACTIVITY_NORMAL_PRIORITY]: 'secondary.main',
    [ACTIVITY_MODERATE_PRIORITY]: 'warning.main',
    [ACTIVITY_URGENT_PRIORITY]: 'error.main',
    [ACTIVITY_PLAN_PRIORITY]: 'info.main',
  }

  const startTime = new Date(date);
  const formatedStartTime = startTime.toLocaleTimeString('fr-FR');
  const splitedStartTime = formatedStartTime.split(':')
  // Extract the time components
  const hours = splitedStartTime[0];
  const minutes = splitedStartTime[1];
  // Format the time as a string
  const timeString = `${hours}h${minutes}`;

  // ouvre la creation du ticket sauf si l'utilisateur click sur le numéro de telephone
  const handleOnClickOpenActivity = (e) => {
    if (e.target.classList.contains('notTriggerOpenActivity')) return
    saveIsDisplayedActivityModal(id, true);
  }

  const getUser = useCallback(() => (
    users.filter(({ lines }) => lines.includes(callNumber))[0]
  ),
    [callNumber, users],
  );

  useEffect(() => {
    if (activity?.contact) {
      let contactTags = activity.contact.tags;
      let fullTags = contactTags.map(tag => {
        const foundTag = tags.find(objet => '/tags/' + objet.id === tag);
        return foundTag
      })
      setDisplayedTags(fullTags)
    }
  }, [activity, tags])

  const user = getUser();

  const handleClickContactName = (event) => {
    event.preventDefault();
    window.open(event.target.href, '_blank');
    event.stopPropagation();
  }

  return (
   activity && (
    <>
      {/* Call */}
      <StyledPaper
        sx={{
          borderColor: `${borderColor[activity?.priority ?? 0]}`,
          '&:hover': {
            borderColor: `${borderColor[activity?.priority ?? 0]}`,
          },
          position: 'relative',
          marginY: '30px',
        }}
      >
        {/* TAGS */}
        {displayedTags.length !== 0 ?
          <CallTags tags={displayedTags} />
          :
          null
        }
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginY: 'auto',
            height: '100%'
          }}
          onClick={handleOnClickOpenActivity}
        >
          <Grid container sx={{ alignItems: 'center' }}>
            {/* History Call Object Icon &  Caller's name/number */}
            <Grid container item xs={12} md={4} lg={3}
              sx={{
                alignItems: 'center',
                flexDirection: ['row-reverse', null, 'row'],
                marginBottom: [2, null, 0],
                flexWrap: 'nowrap',
              }}
            >
              {/* History Call Object Icon */}
              <Box
                sx={{
                  textAlign: 'end',
                  mt: ['0px', null, '0px'],
                  alignItems: 'center',
                  display: ['block', null, 'flex']
                }}
              >
                <HistoryCallObjectIcon iconeName={activity?.subject ?? "0"} sx={{
                  color: 'secondary.main',
                  fontSize: ['24px', null, '30px'],
                  p: 0,
                }} />
              </Box>
              {/* Caller's name/number */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: ['center', 'center', 'flex-start'],
                  textAlign: ['center', 'center', 'left'],
                  maxWidth: ['80%', '80%', '85%'],
                  width: 'auto',
                  marginX: ['auto', null, 0],
                  ml: ['auto', null, 2, 4]
                }}
              >
                {
                  activity?.contact ? (
                    <TypographyTooltip
                      typographySx={{
                        color: "primary.light",
                        fontSize: '15px',
                      }}
                      direction={direction}
                      client={activity.contact}
                    >
                      <Link sx={{ color: "primary.light", fontSize: '15px', textDecoration: 'none' }} onClick={handleClickContactName} href={activity?.contact?.companyUrl ?? activity?.contact?.url}>{activity?.contact?.companyName ?? activity?.contact?.name}</Link>
                    </TypographyTooltip>
                  )
                    :
                    (
                      <Typography sx={{
                        display: 'inline-block',
                        maxWidth: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis',
                        color: 'primary.light',
                        fontSize: '15px',
                        fontWeight: '550',
                      }}>
                        {/* Si le client n'est pas présent en BDD et que c'est un appel interne */}
                        {
                          'internal' === direction && `${user?.firstname ?? ''} ${user?.lastname ?? ''}`
                        }
                        {/* Si le client n'est pas présent en BDD et que ce n'est pas un appel interne */}
                        {
                          'internal' !== direction && <CallNumberMenu callNumber={callNumber} />
                        }
                      </Typography>
                    )
                }
                {/* Client Name */}

                {/* Client CallNumber */}
                {(activity?.contact || 'internal' === direction) && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: ['0px' , '0px' , '-5px'],
                    }}
                    className='notTriggerOpenHistoryCall'
                  >
                    {/* Si le contact appartient à une société */}
                    { activity?.contact?.contact && (
                      <>
                        <PersonIcon
                          sx={{
                            fontSize: '14px',
                          }}
                          color="secondary"
                          className='notTriggerOpenHistoryCall'
                        />
                        <TypographyTooltip
                          variant='body1'
                          typographySx={{
                            fontSize: '12px!important',
                            mb: '2px',
                            mr: '0.5rem', 
                          }}
                          direction={direction}
                          client={activity.contact}
                          fontWeight={400}
                          className='notTriggerOpenHistoryCall'
                        >
                          <Link sx={{ color: "secondary.main", textDecoration: 'none' }} onClick={handleClickContactName} href={activity?.contact?.url}>{activity?.contact?.name}</Link>
                        </TypographyTooltip>
                      </>
                    ) 
                    }
                    {/* Telephone du contact */}
                    <LocalPhoneIcon
                      sx={{
                        fontSize: '14px',
                      }}
                      color="secondary"
                      className='notTriggerOpenHistoryCall'
                    />
                    <Typography
                      variant='body1'
                      sx={{
                        color: "secondary.main",
                        fontSize: '12px!important',
                        mb: '2px',
                      }}
                      className='notTriggerOpenHistoryCall'
                    >
                      <CallNumberMenu callNumber={callNumber} />
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            {/* Note */}
            <Grid container item xs={12} md={2} lg={3}
              sx={{
                alignItems: 'center',
                flexDirection: ['row-reverse', null, 'row'],
                marginBottom: [2, null, 0],
                paddingRight: ['24px', '24px', 0, 0]
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: ['center', 'center', 'flex-start'],
                  textAlign: ['center', 'center', 'left'],
                  maxWidth: ['80%', '80%', '120%', '150%'],
                  width: 'auto',
                  marginX: ['auto', null, 0],
                  ml: ['auto', null, 2, 4]
                }}
              >
                <NotesIcon color="secondary" />
                <Typography
                  variant='body1'
                  sx={{
                    color: "primary.main",
                    // fontSize: '12px!important',
                    width: '100%',
                    mb: '2px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                  {activity?.note}
                </Typography>
              </Box>
            </Grid>
            {/* Call duration, direction and startime */}
            <Grid container item xs={12} md={6} lg={6}
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              {/* Call duration */}
              <Grid item xs={3} md={3} lg={2} xl={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Duration duration={duration} />
              </Grid>
              {/* Call direction */}
              <Grid item xs={6} md={4} lg={3} xl={3} xxl={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center",
                }}>
                {
                  direction === 'inbound' && (
                    <>
                      <CallReceivedIcon color="secondary" sx={{ m: 0, p: 0 }} />
                      <Typography sx={{ color: "primary.light" }}>Appel Entrant</Typography>
                    </>
                  )
                }
                {
                  direction === 'outbound' && (
                    <>
                      <CallMadeIcon color="secondary" />
                      <Typography sx={{ color: "primary.light" }}>Appel Sortant</Typography>
                    </>
                  )
                }
                {
                  direction === 'internal' && (
                    <>
                      <EastIcon color="secondary" />
                      <Typography sx={{ color: "primary.light" }}>Appel Interne</Typography>
                    </>
                  )
                }
              </Grid>
              {/* Date */}
              <Grid item xs={3} md={3} lg={2} xl={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center",
                }}
              >
                <>
                  <DateRangeIcon color="secondary" />
                  <Typography sx={{ color: "primary.light" }}>{timeString}</Typography>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </StyledPaper >
      <ActivityModalContainer callId={id} {...activity} />
      </>
    )
  )
}

Call.propTypes = {
  activity: PropTypes.exact({
    call: PropTypes.exact({
      callNumber: PropTypes.string.isRequired,
      convId: PropTypes.string,
      customer: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      direction: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
    contact: PropTypes.exact({
      activities: PropTypes.array.isRequired,
      callNumber: PropTypes.string.isRequired,
      mobileNumber: PropTypes.string.isRequired,
      contact: PropTypes.string,
      companyName: PropTypes.string,
      companyUrl: PropTypes.string,
      contacts: PropTypes.array.isRequired,
      customer: PropTypes.string,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      tags: PropTypes.array.isRequired,
    }),
    customer: PropTypes.exact({
      activities: PropTypes.array.isRequired,
      calls: PropTypes.array.isRequired,
      id: PropTypes.number.isRequired,
      wazoUserUuid: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    closed: PropTypes.bool.isRequired,
    note: PropTypes.string.isRequired,
    priority: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
  // numéro de de téléphone de l'appel
  callNumber: PropTypes.string.isRequired,
  // liste des utilisateurs wazo
  users: PropTypes.arrayOf(
    PropTypes.exact({
      uuid: PropTypes.string.isRequired,
      // email de l'utilisateur
      email: PropTypes.string,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      // numéros de ligne appartenant à l'utilisateur
      lines: PropTypes.arrayOf(
        PropTypes.string.isRequired,
      ).isRequired,
      // si la licence CTI est activé
      isActive: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  customer: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  saveIsDisplayedActivityModal: PropTypes.func.isRequired,
}
export default Call;