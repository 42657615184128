import { connect } from "react-redux";

import TabsPanel from "../components/TabsPanel";

const mapStateToProps = (state) => ({
  tabIndex: state.tabs.tabIndex?.[window.location.pathname] ?? 0,
})

// const mapDispatchToProps = (dispatch) => ({

// })

export default connect(mapStateToProps)(TabsPanel)