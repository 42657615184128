import { connect } from "react-redux";
import HistoryCallNewMessage from "../components/HistoryCallNewMessage";
// actions
import {
  postHistoryCallMessage
} from "../redux/calls/actionCalls";

const mapStateToProps = (state) => ({
  userUuid: state.login.uuid,
})

const mapDispatchToProps = (dispatch) => ({
  postHistoryCallMessage: (historyCallMessage) => dispatch(postHistoryCallMessage(historyCallMessage)) 
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCallNewMessage)
