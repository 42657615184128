import { connect } from "react-redux";
import CallsCreated from "../components/CallsCreated";

// Actions

const mapStateToProps = (state) => ({
  calls: state.calls.calls,
})

// const mapDispatchToProps = (dispatch) => ({
// })

export default connect(mapStateToProps)(CallsCreated)
