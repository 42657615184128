// React redux
import { connect } from "react-redux";

import ClientFolderDialog from "../components/ClientFolderDialog";
import { saveIsDisplayedClientFolderDialog, saveFolderDisplayed, saveFiles, fetchFile, uploadFile, saveBinders, saveSelectedBinder } from "../redux/clients/actionClients";

const mapStateToProps = (state) => ({
  isDisplayedClientFolderDialog: state.clients.isDisplayedClientFolderDialog,
  clients: state.clients.clients,
  tabIndex: state.tabs.tabIndex[state.tabs.currentPageName],
  folderDisplayed: state.clients.folderDisplayed,
  files: state.clients.files,
  binders: state.clients.binders,
  selectedBinder: state.clients.selectedBinder,
  isUploadingFile: state.clients.isUploadingFile,
  isFetchingFiles: state.clients.isFetchingFiles,
})

const mapDispatchToProps = (dispatch) => ({
  saveIsDisplayedClientFolderDialog: (isDisplayedClientFolderDialog) => dispatch(saveIsDisplayedClientFolderDialog(isDisplayedClientFolderDialog)),
  saveFolderDisplayed: (folderDisplayed) => dispatch(saveFolderDisplayed(folderDisplayed)),
  saveFiles: (files) => dispatch(saveFiles(files)),
  fetchFile: (fileId, fileName) => dispatch(fetchFile(fileId, fileName)),
  uploadFile: (uploadingFile, folderId, binderId) => dispatch(uploadFile(uploadingFile, folderId, binderId)),
  saveBinders: (binders) => dispatch(saveBinders(binders)),
  saveSelectedBinder: (binderId) => dispatch(saveSelectedBinder(binderId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientFolderDialog)