// React
import React, { useState, useEffect } from 'react'

// PropTypes
import PropTypes from 'prop-types';

// MUI
import {
  Box,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  DialogContent,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';

//Media Query
import useMediaQuery from '@mui/material/useMediaQuery';

// UseTheme
import { useTheme } from '@mui/material/styles';

// Components
import HistoryCallModalMenuItemIcon from './HistoryCallModalMenuItem';
import ActivityContactDenomination from './ActivityContactDenomination';
import ActivityPriorityButton from './ActivityPriorityButton';

// Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// Styled
import { StyledMenuItem } from "../styles/HistoryCallModalStyle";

// Utils
import { formatDate } from '../utils';

// Const
import {
  ACTIVITY_NORMAL_PRIORITY,
  ACTIVITY_MODERATE_PRIORITY,
  ACTIVITY_URGENT_PRIORITY,
  ACTIVITY_PLAN_PRIORITY,
  ACTIVITY_DEFAULT_OBJECT,
  ACTIVITY_MISSED_OBJECT,
  ACTIVITY_FINANCIAL_OBJECT,
  ACTIVITY_PERSONAL_OBJECT,
  ACTIVITY_APPOINTMENT_OBJECT,
  ACTIVITY_INFORMATION_OBJECT,
} from '../redux/activities/reducerActivities';

// doit etre en dehors du composant sinon le dialog ne s'enleve pas du dom a la fermeture
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

// Initialisation des erreurs par defaut
const defaultError = {
  object: undefined,
  description: undefined,
  priority: undefined,
  collaboratorUuid: undefined,
}

const ActivityModal = ({
  isDisplayedActivityModal,
  saveIsDisplayedActivityModal,
  callId,
  contact,
  closed,
  note,
  priority,
  subject,
  updatedAt,
  patchActivity,
  id,
  customer,
  call,
  saveSnackbar,
  closeActivity,
  cancelActivity,
}) => {
  const [date, setDate] = useState('');
  const [activityNote, setActivityNote] = useState('');
  const [activitySubject, setActivitySubject] = useState('');
  const [activityPriority, setActivityPriority] = useState('');

  useEffect(() => {
    if (note) {
      setActivityNote(note);
    }
  }, [note])
  useEffect(() => {
    if (subject) {
      setActivitySubject(subject);
    }
  }, [subject])
  useEffect(() => {
    if (priority) {
      setActivityPriority(priority);
    }
  }, [priority])
  useEffect(() => {
    if (updatedAt) {
      setDate(formatDate(updatedAt));
    }
  }, [updatedAt])

  // mui tools
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // gere les erreur de soumission du formulaire
  const [error, setError] = useState(defaultError);

  // Fermeture de la modal
  const handleOnCloseModal = () => {
    setError({});
    saveIsDisplayedActivityModal(callId, false);
  }

  // affiche le message d'erreur
  const getEmptyErrorTypography = (error) => (
    undefined !== error && (
      <Typography component="p" sx={{ "textAlign": "left", mb: 1, display: 'flex', alignItems: 'center' }} color="error">
        <ErrorOutlineIcon sx={{ mr: 1 }} />{error}
      </Typography>
    )
  )

  // gestions des erreurs du formulaire
  const checkFormError = () => {
    let isError = false;
    let newError = {};


    if ('' === activitySubject) {
      isError = true;
      newError = { ...newError, subject: 'Objet non selectionné' }
    }

    if ('' === activityNote) {
      isError = true;
      newError = { ...newError, note: 'Saisie de la note obligatoire' }
    }

    const MAX_LENGTH_NOTE = 255;
    if (activityNote.length > MAX_LENGTH_NOTE) {
      isError = true;
      newError = { ...newError, note: `La note ne peut pas dépasser ${MAX_LENGTH_NOTE} caractères` }
    }

    if ('' === activityPriority) {
      isError = true;
      newError = { ...newError, priority: 'Priorité non selectionnée' }
    }

    const priorities = [
      ACTIVITY_NORMAL_PRIORITY,
      ACTIVITY_MODERATE_PRIORITY,
      ACTIVITY_URGENT_PRIORITY,
      ACTIVITY_PLAN_PRIORITY,
    ]

    if (!priorities.includes(activityPriority)) {
      isError = true;
      newError = { ...newError, priority: 'Valeur invalide' }
    }
    setError({ ...error, ...newError });

    return isError;
  }

  // ferme la creation du ticket lorsque l'on click en dehors du PaperComponent du dialog
  const handleOnClickCloseActivity = (e) => {
    if (e.target !== e.currentTarget.querySelector('.MuiDialog-container')) return;
    handleOnCloseModal()
  }

  // Format date
  const getNewDateApiFormat = () => {
    let newDate = new Date();

    // Convert it to UTC in the desired format
    let utcDateString = newDate.toISOString().replace(/\.\d+/, '').replace('Z', '+00:00');
    return utcDateString;
  }

  // Cloture d'une activité
  const handleOnClickSubmitClosedButton = () => {
    // verifie les erreur dans le cas ou l'historique est fermé
    if (!checkFormError()) {
      const newDate = getNewDateApiFormat();
      patchActivity({
        id: id,
        note: activityNote,
        subject: activitySubject,
        priority: activityPriority,
        updatedAt: newDate,
        closed: false,
        contact: contact,
        customer: customer,
        call: call,
      });
      closeActivity({
        id: id,
        note: note,
        subject: subject,
        priority: priority,
        updatedAt: newDate,
        closed: true,
        contact: contact,
        customer: customer,
        call: call,
      });
      saveSnackbar("Clôture de l'activité réussie", 'success')
      saveIsDisplayedActivityModal(callId, false);
    }
    else {
      saveSnackbar("Echec de la clôture de l'activité", 'error')
    }
  }

  // créer ou met à jour un historique
  const handleOnClickSubmitCreateButton = () => {
    // verifie les erreur dans le cas ou l'historique est ouvert
    if (!checkFormError()) {
      const newDate = getNewDateApiFormat();

      patchActivity({
        id: id,
        note: activityNote,
        subject: activitySubject,
        priority: activityPriority,
        updatedAt: newDate,
        closed: false,
        contact: contact,
        customer: customer,
        call: call,
      });
      saveIsDisplayedActivityModal(callId, false);
      saveSnackbar("Mise à jour de l'activité réussie.", 'success')
    }
    else {
      saveSnackbar("Echec de la mise à jour de l'activité.", 'error')
    }
  }

  const handleOnDeleteActivity = () => {
    cancelActivity({
      id: id,
      note: note,
      subject: subject,
      priority: priority,
      updatedAt: getNewDateApiFormat(),
      closed: true,
      contact: contact,
      customer: customer,
      call: call,
    })
  }

  return (
    <Dialog
      open={isDisplayedActivityModal}
      TransitionComponent={Transition}
      maxWidth={'md'}
      fullWidth
      fullScreen={fullScreen}
      onClick={handleOnClickCloseActivity}
      PaperProps={{
        sx: {
          minWidth: `calc(400px - (${theme.spacing(4)} * 4))`,
          textAlign: 'center',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        {/* Header */}
        <AppBar sx={{
          position: 'relative',
          background: `linear-gradient(to right bottom, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
          paddingRight: '17px!important',// pour compenser le disable scrow du dialog
        }}>
          <Toolbar>
            <Typography color={'white'} sx={{
              margin: 'auto',
              fontSize: ['24px!important', '30px!important'],
              textTransform: 'uppercase',
              fontWeight: 'bold'
            }}>
              Activité
            </Typography>
            <IconButton
              size="large"
              aria-label="close"
              component="div"
              title="fermer"
              onClick={handleOnCloseModal}
              sx={{ position: 'absolute', top: [3, 12], right: [-8, -6], p: 0 }}
            >
              <CloseOutlinedIcon color='white' />
            </IconButton>
            <Box sx={{
              position: 'absolute',
              top: ['4px', '13px!important'],
              left: ['4px', '13px!important'],
              color: 'white',
            }}>
              <Typography variant="body1" color={'white'}>Mise à jour le {date}</Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{
          overflowY: 'scroll',
        }}>
          {/* Nom et numéro du contact */}
          <ActivityContactDenomination contact={contact} />
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ padding: theme.spacing(4), }}
          >
            {/* Formulaire de création */}
            <Box sx={{ mb: 4 }} >
              {/* Selection de l'object de l'appel */}
              {/* Object error */}
              {getEmptyErrorTypography(error?.subject)}
              <FormControl fullWidth sx={{ marginBottom: 4 }}>
                <InputLabel sx={{ cursor: !closed ? 'text' : 'not-allowed' }} id="activity-subject-label">Objet</InputLabel>
                <Select
                  labelId="activity-subject-label"
                  id="activity-subject-select"
                  value={activitySubject}
                  label="Objet"
                  readOnly={closed}
                  onChange={(event) => {
                    setError({ ...error, subject: undefined });
                    setActivitySubject(event.target.value);
                  }}
                  inputProps={
                    {
                      sx: { cursor: closed ? 'not-allowed' : 'text' }
                    }
                  }
                  sx={
                    error?.subject && (
                      {
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: 'red',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'red',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'red',
                        },
                      }
                    )
                  }
                >
                  <StyledMenuItem value={ACTIVITY_MISSED_OBJECT}>
                    <HistoryCallModalMenuItemIcon itemName={ACTIVITY_MISSED_OBJECT} itemLabel="Appel manqué" />
                  </StyledMenuItem>
                  <StyledMenuItem value={ACTIVITY_FINANCIAL_OBJECT}>
                    <HistoryCallModalMenuItemIcon itemName={ACTIVITY_FINANCIAL_OBJECT} itemLabel="Comptabilité" />
                  </StyledMenuItem>
                  <StyledMenuItem value={ACTIVITY_PERSONAL_OBJECT}>
                    <HistoryCallModalMenuItemIcon itemName={ACTIVITY_PERSONAL_OBJECT} itemLabel="Personnel" />
                  </StyledMenuItem>
                  <StyledMenuItem value={ACTIVITY_APPOINTMENT_OBJECT}>
                    <HistoryCallModalMenuItemIcon itemName={ACTIVITY_APPOINTMENT_OBJECT} itemLabel="Prise de rendez-vous" />
                  </StyledMenuItem>
                  <StyledMenuItem value={ACTIVITY_INFORMATION_OBJECT}>
                    <HistoryCallModalMenuItemIcon itemName={ACTIVITY_INFORMATION_OBJECT} itemLabel="Renseignements" />
                  </StyledMenuItem>
                  <StyledMenuItem value={ACTIVITY_DEFAULT_OBJECT}>
                    <HistoryCallModalMenuItemIcon itemName={ACTIVITY_DEFAULT_OBJECT} itemLabel="Autre demande..." />
                  </StyledMenuItem>
                </Select>
              </FormControl>
              {/* Note error */}
              {getEmptyErrorTypography(error?.note)}
              {/* Note de l'appel */}
              <TextField
                id="noteInput"
                label="note"
                fullWidth
                multiline
                minRows={3}
                maxRows={5}
                value={activityNote}
                InputProps={{
                  readOnly: closed
                }}
                onChange={(event) => {
                  setError({ ...error, note: undefined });
                  setActivityNote(event.target.value);
                }}
                sx={
                  error?.note
                    ? ({
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                      },
                      marginBottom: 4,
                    })
                    : ({
                      marginBottom: 4,
                      textarea: { cursor: closed ? 'not-allowed' : 'text' },
                      label: { cursor: closed ? 'not-allowed' : 'text' },
                      '&:hover .MuiInputBase-root': {
                        cursor: closed ? 'not-allowed' : 'text'
                      },
                    })
                }
              />
              {/* priorité error */}
              {getEmptyErrorTypography(error?.priority ? error?.priority : undefined)}
              {/* Selection de la priorité */}
              <ActivityPriorityButton
                error={error}
                setError={setError}
                readOnly={closed}
                activityPriority={activityPriority}
                setActivityPriority={setActivityPriority}
              />
            </Box>
            {/* Validation du formulaire */}
            { !closed && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={handleOnClickSubmitCreateButton}
                    disabled={
                      JSON.stringify(defaultError) !== JSON.stringify(error)
                    }
                  >
                    Mettre à jour
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleOnClickSubmitClosedButton}
                    disabled={JSON.stringify(defaultError) !== JSON.stringify(error)}
                  >
                    Cloturer
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    onClick={handleOnDeleteActivity}
                    disabled={JSON.stringify(defaultError) !== JSON.stringify(error)}
                  >
                    Supprimer
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}
ActivityModal.propTypes = {
  isDisplayedActivityModal: PropTypes.bool.isRequired,
  saveIsDisplayedActivityModal: PropTypes.func.isRequired,
  callId: PropTypes.string.isRequired,
  // Contact lié à l'appel
  contact: PropTypes.exact({
    activities: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    callNumber: PropTypes.string.isRequired,
    mobileNumber: PropTypes.string.isRequired,
    contact: PropTypes.string,
    companyName: PropTypes.string,
    companyUrl: PropTypes.string,
    contacts: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    customer: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
  }).isRequired,
  // Activité fermé ou non ? 
  closed: PropTypes.bool.isRequired,
  // Note de l'activité
  note: PropTypes.string.isRequired,
  // Priorité de l'activité
  priority: PropTypes.string.isRequired,
  // Objet de l'activité
  subject: PropTypes.string,
  // Date de dernière mise à jour
  updatedAt: PropTypes.string.isRequired,
  // Modification de l'activité
  patchActivity: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  customer: PropTypes.exact({
    activities: PropTypes.array.isRequired,
    calls: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    wazoUserUuid: PropTypes.string.isRequired,
  }),
  call: PropTypes.exact({
    callNumber: PropTypes.string.isRequired,
    convId: PropTypes.string,
    customer: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  // Gestion des alertes
  saveSnackbar: PropTypes.func.isRequired,
}
export default ActivityModal;