import React from 'react'
import PropTypes from 'prop-types'
// components
// containers
// material
import {
  Box,
  Typography,
  Popover,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';

const HistoryCallModalHelperPopover = ({ historyCalls, calls, users, historyId }) => {
  const historyCall = historyCalls.filter(
    ({ id }) => historyId === id,
  )[0]

  const call = calls.filter(
    ({ historyId }) => historyCall.id === historyId,
  )[0]

  const userCallOwner = users.filter(
    ({ uuid }) => call.userId === uuid,
  )[0]

  const formatDateToFr = (date) => date.toLocaleString('fr-Fr').split(' ')[0]
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Gestion Popover
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <InfoIcon
        sx={{
          position: 'absolute',
          top: ['4px', '13px!important'],
          left: ['4px', '13px!important'],
          color: 'white',
        }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box
          sx={{
            padding: 3,
          }}
        >
          {/* createur du ticket*/}
          <Box
            display='flex'
            alignItems='center'
            textAlign='center'
            color={'secondary.light'}
            sx={{ mb: 1 }}
          >
            <PersonIcon />
            <Typography variant="body1" color={'primary'}>Createur du ticket :</Typography>
            &nbsp;
            <Typography variant="body1" color={'secondary'}>{`${userCallOwner.firstname} ${userCallOwner.lastname}`}</Typography>
          </Box>
          {/* Date modification */}
          <Box
            display='flex'
            alignItems='center'
            textAlign='center'
            color={'secondary.light'}
          >
            <EditIcon />
            <Typography variant="body1" color={'primary'}>Dernière modification :</Typography>
            &nbsp;
            <Typography variant="body1" color={'secondary'}>{formatDateToFr(new Date(historyCall.updatedAt.seconds * 1000))}</Typography>

          </Box>
        </Box>
      </Popover>
    </>
  )
}

HistoryCallModalHelperPopover.propTypes = {
  historyId: PropTypes.string.isRequired,
  historyCalls: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // object de l'historique
      object: PropTypes.string.isRequired,
      // contenue de l'historique
      description: PropTypes.string.isRequired,
      // indique si l'historique est urgent, a rappeler ou normal
      priority: PropTypes.number.isRequired,
      // collabateur en charge de l'historique
      collaboratorUuid: PropTypes.string.isRequired,
      // status du ticket : HISTORY_STATUS_OPENED, HISTORY_STATUS_CLOSED
      status: PropTypes.number.isRequired,
      // date de la derniere mise à jour de l'historique
      updatedAt: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // identifiant du dossier genapi
      folderId: PropTypes.number.isRequired,
    }).isRequired,
  ),
  users: PropTypes.arrayOf(
    PropTypes.exact({
      uuid: PropTypes.string.isRequired,
      // email de l'utilisateur
      email: PropTypes.string,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      // numéros de ligne appartenant à l'utilisateur
      lines: PropTypes.arrayOf(
        PropTypes.string.isRequired,
      ).isRequired,
      // si la licence CTI est activé
      isActive: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  calls: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // Identifiant du client Genapi correspondant a cet appel
      clientId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
}

export default HistoryCallModalHelperPopover
