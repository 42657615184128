// MUI
import {
  Typography,
  Box,
  Tooltip
} from '@mui/material';

// Proptypes
import PropTypes from 'prop-types'

// Get background brightness
import { isLightBackground } from '../utils';

const CallTags = ({ tags }) => {
  return (
    <>
      {tags.length !== 0 ?
        tags.map((tag, index) => {

          // Récupère les premières lettres des mots du tag
          const splittedTag = tag.name.split(' ');
          const firstLetters = splittedTag.map(word => word.charAt(0).toUpperCase());
          firstLetters.join(' ');

          // Déterminer la couleur du texte en fonction du fond
          const textColor = isLightBackground(tag.color) ? "#000" : '#fff';

          return (
            <Box
              key={tag.id}
              sx={{
                borderTopRightRadius: '5px',
                borderTopLeftRadius: '5px',
                backgroundColor: tag.color,
                height: '22px',
                width: '60px',
                position: 'absolute',
                top: -22,
                left: 15 + index * 70,
              }}
            >
              <Tooltip title={tag.name} placement="top">
                <Typography
                  variant='body1'
                  sx={{
                    color: textColor,
                    fontWeight: 500,
                    width: '100%',
                    mb: '2px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>
                  {firstLetters}
                </Typography>
              </Tooltip>
            </Box>
          )
        })
        :
        null
      }
    </>
  )

}

CallTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      contact: PropTypes.arrayOf(
        PropTypes.string,
      ),
    }).isRequired
  ).isRequired,
}

export default CallTags;