import { connect } from "react-redux";
import TypographyTooltip from "../components/TypographyTooltip";
// actions
import {
  fetchFullClient,
  fetchFolders,
} from "../redux/clients/actionClients";
import { saveTabIndex } from "../redux/tabs/actionTabs";

const mapStateToProps = (state) => ({
  fullClients: state.clients.fullClients,
  isFetchingFullClient: state.clients.isFetchingFullClient,
  folders: state.clients.folders,
  isFetchingFolders: state.clients.isFetchingFolders,
})

const mapDispatchToProps = (dispatch) => ({
  fetchFullClient: (clientId) => dispatch(fetchFullClient(clientId)),
  fetchFolders: (clientId) => dispatch(fetchFolders(clientId)),
  saveTabIndex: (currentPageName, tabIndex) => dispatch(saveTabIndex(currentPageName, tabIndex)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TypographyTooltip)