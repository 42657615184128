export const SAVE_ACTIVITIES = 'SAVE_ACTIVITIES';
export const SAVE_ACTIVITY = 'SAVE_ACTIVITY';
export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const POST_ACTIVITY = 'POST_ACTIVITY';
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const SAVE_IS_DISPLAYED_ACTIVITY_MODAL = 'SAVE_IS_DISPLAYED_ACTIVITY_MODAL';
export const SAVE_IS_FETCHING_ACTIVITIES = 'SAVE_IS_FETCHING_ACTIVITIES';
export const PATCH_ACTIVITY = 'PATCH_ACTIVITY';
export const CLOSE_ACTIVITY = 'CLOSE_ACTIVITY';
export const CANCEL_ACTIVITY = 'CANCEL_ACTIVITY';

export const saveActivities = (activities) => ({
  type: SAVE_ACTIVITIES,
  activities,
})

export const saveActivity = (activity) => ({
  type: SAVE_ACTIVITY,
  activity,
})

export const updateActivity = (activity) => ({
  type: UPDATE_ACTIVITY,
  activity,
})

export const deleteActivity = (activity) => ({
  type: DELETE_ACTIVITY,
  activity,
})

export const postActivity = (activity) => ({
  type: POST_ACTIVITY,
  activity,
})

export const fetchActivities = (calls) => ({
  type: FETCH_ACTIVITIES,
  calls,
})

export const saveIsDisplayedActivityModal = (callId, isDisplayedActivityModal) => ({
  type: SAVE_IS_DISPLAYED_ACTIVITY_MODAL,
  callId,
  isDisplayedActivityModal,
})

export const saveIsFetchingActivities = (isFetchingActivities) => ({
  type: SAVE_IS_FETCHING_ACTIVITIES,
  isFetchingActivities,
})

export const patchActivity = (activity) => ({
  type: PATCH_ACTIVITY,
  activity,
})

export const closeActivity = (activity) => ({
  type: CLOSE_ACTIVITY,
  activity,
})

export const cancelActivity = (activity) => ({
  type: CANCEL_ACTIVITY,
  activity,
})