// React Redux
import { connect } from "react-redux";

// Components
import WazoOdooLinkDialog from "../components/WazoOdooLinkDialog";
// actions
import { fetchIsConnectedToOdoo, saveIsDisplayedWazoOdooModal } from "../redux/wazoOdoo/actionWazoOdoo";

const mapStateToProps = (state) => ({
  isDisplayedWazoOdooModal: state.wazoOdoo.isDisplayedWazoOdooModal,
  isConnectedToOdoo: state.wazoOdoo.isConnectedToOdoo,
  isConnectingToOdoo: state.wazoOdoo.isConnectingToOdoo,
  email: state.login.email,
  wazoUserUuid: state.login.uuid,
})

const mapDispatchToProps = (dispatch) => ({
saveIsDisplayedWazoOdooModal: (callId, isDisplayedWazoOdooModal) => dispatch(saveIsDisplayedWazoOdooModal(callId, isDisplayedWazoOdooModal)),
fetchIsConnectedToOdoo: (email, uuid) => dispatch(fetchIsConnectedToOdoo(email, uuid))
})

export default connect(mapStateToProps, mapDispatchToProps)(WazoOdooLinkDialog)