export const CONNECT_WEBSOCKET_WAZO = 'CONNECT_WEBSOCKET_WAZO';
export const DISCONNECT_WEBSOCKET_WAZO = 'DISCONNECT_WEBSOCKET_WAZO';
export const SAVE_IS_CONNECTING_WEBSOCKET = 'SAVE_IS_CONNECTING_WEBSOCKET';

export const connectWebsocketWazo = () => ({
  type: CONNECT_WEBSOCKET_WAZO,
})

export const disconnectWebsocketWazo = () => ({
  type: DISCONNECT_WEBSOCKET_WAZO,
})

export const saveIsConnectingWebsocket = (isConnectingWebsocket) => ({
  type: SAVE_IS_CONNECTING_WEBSOCKET,
  isConnectingWebsocket,
})
