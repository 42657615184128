export const SAVE_USERS = 'SAVE_USERS';
export const FETCH_USERS = 'FETCH_USERS';
export const SAVE_IS_FETCHING_USERS = 'SAVE_IS_FETCHING_USERS';
export const DELETE_USERS = 'DELETE_USERS';

export const saveUsers = (users) => ({
  type: SAVE_USERS,
  users,
})

export const fetchUsers = () => ({
  type: FETCH_USERS,
})

export const deleteUsers = () => ({
  type: DELETE_USERS,
})

export const saveIsFetchingUsers = (isFetchingUsers) => ({
  type: SAVE_IS_FETCHING_USERS,
  isFetchingUsers,
})
