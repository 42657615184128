export const SAVE_SNACKBAR = 'SAVE_SNACKBAR';
export const CLEAN_SNACKBAR = 'CLEAN_SNACKBAR';

export const saveSnackbar = (message, variant) => ({
  type: SAVE_SNACKBAR,
  message,
  variant,
})

export const cleanSnackbar = () => ({
  type: CLEAN_SNACKBAR,
})