export const SAVE_TAB_INDEX = 'SAVE_TAB_INDEX';
export const SAVE_CURRENT_TAB_INDEX = 'SAVE_CURRENT_TAB_INDEX';

export const saveTabIndex = (pageName, tabIndex) => ({
  type: SAVE_TAB_INDEX,
  pageName,
  tabIndex,
})

export const saveCurrentTabIndex = (pageName, currentTabIndex) => ({
  type: SAVE_CURRENT_TAB_INDEX,
  pageName,
  currentTabIndex,
})