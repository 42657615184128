import { connect } from "react-redux";
import HistoryCallModalHelperPopover from "../components/HistoryCallModalHelperPopover";
// actions
// import { fetchUsers } from "../redux/users/actionUsers";

const mapStateToProps = (state) => ({
  historyCalls: state.calls.historyCalls,
  calls: [...state.calls.calls, ...state.calls.callsByCollaboratorUuid, ...state.calls.callsByClientId],
  users: state.users.users,
})

// const mapDispatchToProps = (dispatch) => ({

// })

export default connect(mapStateToProps)(HistoryCallModalHelperPopover)