// Redux
import { connect } from "react-redux";

// Component
import Clients from "../components/Clients";

// Actions
import { saveTabIndex } from "../redux/tabs/actionTabs";
import { saveFolderDisplayed, saveIsDisplayedClientFolderDialog } from "../redux/clients/actionClients";

const mapStateToProps = (state) => ({
  clients: state.clients.clients,
})

const mapDispatchToProps = (dispatch) => ({
  saveTabIndex: (currentPageName, tabIndex) => dispatch(saveTabIndex(currentPageName, tabIndex)),
  saveFolderDisplayed: (folderDisplayed) => dispatch(saveFolderDisplayed(folderDisplayed)),
  saveIsDisplayedClientFolderDialog: (isDisplayedClientFolderDialog) => dispatch(saveIsDisplayedClientFolderDialog(isDisplayedClientFolderDialog)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Clients)