// Materia
import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';


export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": { backgroundColor: theme.palette.secondary.light, "& svg": { color:  theme.palette.secondary.dark} },
  "&.Mui-selected": { backgroundColor: theme.palette.secondary.light, "& svg": { color:  theme.palette.secondary.dark},
    "&:hover": { backgroundColor:  theme.palette.secondary.light, "& svg": { color:  theme.palette.secondary.dark} }
  },
}));
