import {
  SAVE_EMAIL,
  SAVE_PASSWORD,
  SAVE_SERVER,
  SAVE_WAZO_TOKEN,
  SAVE_TENANT_UUID,
  SAVE_REFRESH_TOKEN,
  SAVE_UUID,
  SAVE_ERROR,
  SAVE_IS_AUTHENTICATION_LOADING,
  SAVE_EXPIRES_AT,
  SAVE_IS_CONNECTED,
  SAVE_IS_CONNECTING_WITH_CONTEXT,
  SAVE_IS_CHECKING_WAZO_TOKEN,
  SAVE_IS_CHECKING_IS_MOST_RECENT_VERSION,
  SAVE_IS_DISCONNECTING,
  SAVE_IS_CONNECTING_TO_WAZO_APP,
  SAVE_IS_DICONNECT_WAZO_APP,
  SAVE_AUTH_TOKEN,
  SAVE_IS_FETCHING_TOKEN,
} from "./actionLogin";

const initialStateTest = {
  email: '',
  password: '',
  server: '',
  uuid: '',
  token: '',
  refreshToken: '',
  expiresAt: new Date(),
  error: {},
  isAuthenticationLoading: false,
  tenantUuid: '',
  isConnected: false,
  isConnectingWithContext: true,
  isCheckingWazoToken: true,
  isCheckingIsMostRecentVersion: false,
  isDisconnecting: false,
  isConnectingToWazoApp: true,
  isDisconnectWazoApp: false,
  authToken: '',
  refreshTokenApi: '',
  refreshTokenExpiration: 0,
  apiTokenExpiresAt: 0,
  isFetchingToken: false,
}

const reducerLogin = (state = initialStateTest, action) => {
  switch (action.type) {
    case SAVE_EMAIL:
      return {
        ...state,
        email: action.email,
      }

    case SAVE_PASSWORD:
      return {
        ...state,
        password: action.password,
      }

    case SAVE_SERVER:
      return {
        ...state,
        server: action.server,
      }

    case SAVE_UUID:
      return {
        ...state,
        uuid: action.uuid,
      }

    case SAVE_TENANT_UUID:
      return {
        ...state,
        tenantUuid: action.tenantUuid,
      }

    case SAVE_WAZO_TOKEN:
      return {
        ...state,
        token: action.token,
      }

    case SAVE_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.refreshToken,
      }

    case SAVE_EXPIRES_AT:
      return {
        ...state,
        expiresAt: action.expiresAt,
      }

    case SAVE_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case SAVE_IS_AUTHENTICATION_LOADING:
      return {
        ...state,
        isAuthenticationLoading: action.isAuthenticationLoading,
      }

    case SAVE_IS_CONNECTED:
      return {
        ...state,
        isConnected: action.isConnected,
      }

    case SAVE_IS_CONNECTING_WITH_CONTEXT:
      return {
        ...state,
        isConnectingWithContext: action.isConnectingWithContext,
      }

    case SAVE_IS_CHECKING_WAZO_TOKEN:
      return {
        ...state,
        isCheckingWazoToken: action.isCheckingWazoToken,
      }

    case SAVE_IS_CHECKING_IS_MOST_RECENT_VERSION:
      return {
        ...state,
        isCheckingIsMostRecentVersion: action.isCheckingIsMostRecentVersion,
      }

    case SAVE_IS_DISCONNECTING:
      return {
        ...state,
        isDisconnecting: action.isDisconnecting,
      }

    case SAVE_IS_CONNECTING_TO_WAZO_APP:
      return {
        ...state,
        isConnectingToWazoApp: action.isConnectingToWazoApp,
      }

    case SAVE_IS_DICONNECT_WAZO_APP:
      return {
        ...state,
        isDisconnectWazoApp: action.isDisconnectWazoApp,
      }

    case SAVE_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.authToken,
        refreshTokenApi: action.refreshToken,
        refreshTokenExpiration: action.refreshTokenExpiration,
        apiTokenExpiresAt: action.apiTokenExpiresAt,
      }

    case SAVE_IS_FETCHING_TOKEN: {
      return {
        ...state,
        isFetchingToken: action.isFetchingToken,
      }
    }
    default: return state
  }
}
export default reducerLogin;