// Styles
import { styled } from '@mui/material/styles';

// Container
import { Container } from '@mui/system';

// MUI
import { Typography, Grid, Button, Paper } from '@mui/material';

// TextField
import TextField from '@mui/material/TextField';

// Table
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

// Link
import Link from '@mui/material/Link';

// Icons
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

// Custom table cell
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// Custom table row
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(2n)': {
    background: 'linear-gradient(#c9e5ea, #bee3ea)'
  },
}));

// Custom typography
export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  lineHeight: 'inherit',
}));

// Custom link
export const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.primary.light,
  textDecoration: 'none',
  '&:hover': {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
}));

// Custom typography table head
export const StyledTypoTableHead = styled(Typography)(({ theme }) => ({
  color: theme.palette.bg.light,
}));

// Custom phone icon
export const StyledPhoneIcon = styled(LocalPhoneOutlinedIcon)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
  },
}));

// Custom email icon
export const StyledEmailIcon = styled(EmailOutlinedIcon)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
  },
}));

// Custom contact page icon 
export const StyledContactPageIcon = styled(ContactPageOutlinedIcon)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
  },
}));

// Custom open in new icon 
export const StyledOpenInNewIcon = styled(OpenInNewIcon)(({ theme }) => ({
  fontSize: theme.typography.body1,
  color: theme.palette.primary.main,
  marginRight: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    fontSize: '22px',
  },
}));

// Custom text field
export const StyledTextField = styled(TextField)(({ theme }) => ({
  background: theme.palette.bg.light,
  marginBottom: theme.spacing(3),
}));


// Custom container 
export const StyledContainer = styled(Container)(({ theme }) => ({
  minWidth: '600px',
  marginInline: 'auto',
  paddingBottom: ['1rem!important', 0],
}));

// Styled typo
export const StyledTypo = styled(Typography)(({ theme }) => ({
  display: 'inline',
  paddingRight: theme.spacing(0.5),
}));

// Styled inline padding grid
export const StyledInlinePaddingGrid = styled(Grid)(({ theme })=> ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(7)}`,
  [theme.breakpoints.up('lg')]: {
    textAlign: 'left',
    '&:nth-of-type(2n)': {
      textAlign: 'right',
    },
  }, 
}));

// Custom LogOut button
export const StyledLogOutButton = styled(Button)(({ theme }) => ({
  position: "absolute", 
  top: theme.spacing(3),
  right: theme.spacing(3), 
  padding: 0,
  minWidth: "24px",
  [theme.breakpoints.up('sm')]: {
    top: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

// Styled client Paper
export const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "relative",
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  margin: theme.spacing(2),
  borderLeft: '10px solid white',
  boxShadow: theme.shadows[3],
  transition: 'border 0.2s',
  '&:hover': {
    borderLeft: '15px solid white',
    boxShadow: theme.shadows[10],
  },
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  marginInline: 'auto',
}));

// Styled client Paper without hover
export const StyledPaperWithoutHover = styled(Paper)(({ theme }) => ({
  position: "relative",
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  margin: theme.spacing(2),
  borderLeft: '10px solid white',
  boxShadow: theme.shadows[3],
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  marginInline: 'auto',
}));