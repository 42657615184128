// icon
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MenuIcon from '@mui/icons-material/Menu';

// Button
import { Box, Button, MenuItem } from '@mui/material';

// Styles
import { styled } from '@mui/material/styles';

// Custom LogOut button
export const StyledLogOutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  minWidth: "45px",
  maxWidth: "45px",
  [theme.breakpoints.up('sm')]: {
    minWidth: "64px",
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
  }
}));

export const StyledLogOutIcon = styled(LogoutIcon)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '24px',
  },
}));

// Custom Client button
export const StyledMenuButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  minWidth: "45px",
  maxWidth: "45px",
  [theme.breakpoints.up('sm')]: {
    minWidth: "64px",
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
  }
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": { backgroundColor: theme.palette.secondary.light, "& svg": { color:  theme.palette.secondary.dark} },
  "&.Mui-selected": { backgroundColor: theme.palette.secondary.light, "& svg": { color:  theme.palette.secondary.dark},
    "&:hover": { backgroundColor:  theme.palette.secondary.light, "& svg": { color:  theme.palette.secondary.dark} }
  },
}));

// Custom Image Box
export const StyledImageBox = styled(Box)(({ theme }) => ({
  maxHeight: 80,
  maxWidth: 235,
  [theme.breakpoints.up('sm')]: {
    maxHeight: 233,
    maxWidth: 285 ,
  },
}));

export const StyledPersonOutlineIcon = styled(PersonOutlineIcon)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '24px',
  },
}));

export const StyledNotificationsNoneIcon = styled(NotificationsNoneIcon)(({ theme }) => ({
  fontSize: '18px',
  [theme.breakpoints.up('sm')]: {
    fontSize: '24px',
  },
}));

export const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  fontSize: '18px',
  color: 'white',
  [theme.breakpoints.up('sm')]: {
    fontSize: '24px',
  },
}));