// Redux
import { connect } from "react-redux";
// Component
import Client from "../components/Client";
// actions
import { 
  fetchFullClient,
} from "../redux/clients/actionClients";

const mapStateToProps = (state) => ({
  fullClients: state.clients.fullClients,
  isFetchingFullClient: state.clients.isFetchingFullClient,
  isFetchingClientError: state.clients.isFetchingClientError,
})

const mapDispatchToProps = (dispatch) => ({
  fetchFullClient: (clientId) => dispatch(fetchFullClient(clientId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Client)