import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// components
import ClientFolderBody from "./ClientFolderBody";
// material
import {
  TextField,
  Autocomplete,
  CircularProgress,
  Paper,
  Snackbar,
  IconButton,
  Grid,
  Box,
  Popover,
} from '@mui/material';
// icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';

const HistoryCallModalSelectFolder = ({
  folders,
  historyCall,
  setHistoryCall,
  isFetchingFolders,
  readOnly,
  fetchFolders,
  clientId,
}) => {
  useEffect(() => {
    if (clientId) {
      fetchFolders(clientId)
    }
  }, [fetchFolders, clientId])

  const currentFolder = folders.filter((folder) => folder.id === historyCall.folderId)[0] ?? null;

  const options = folders.map((option) => {
    const folderName = option.nom;
    return {
      folderName,
      ...option,
    };
  });

  // rajoute le hover quand on passe la souris sur une option
  // le collaboteur selectionné sera aussi en couleur
  const CustomPaper = ({ children }) => (
    <Paper
      sx={{
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option[aria-selected='true']": {
            bgcolor: "secondary.main",
            "&.Mui-focused": {
              bgcolor: "secondary.dark",
            }
          }
        },
        "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
          bgcolor: "secondary.light",
        }
      }}
    >
      {children}
    </Paper>
  );

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (currentFolder?.nom) {
      setOpen(true);
      navigator.clipboard.writeText(currentFolder?.intitule ?? '');
    }
  };

  const FolderPopover = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    const openPopover = Boolean(anchorEl);

    return (
      <>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={openPopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Box
            sx={{
              padding: 3,
              boxShadow: 4,
              width: ['320px', '350px', '350px', '800px'],
              border: 'solid 2px',
              borderColor: 'secondary.main',
            }}
          >
            <ClientFolderBody folder={currentFolder} />
          </Box>
        </Popover>
        <VisibilityIcon
          sx={{ color: 'secondary.light' }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      </>
    )
  }

  const CopyFolderNameButton = () => (
    <>
      <IconButton disabled={!!!currentFolder?.nom} onClick={handleClick} color="primary">
        <ContentCopyIcon />
      </IconButton>
      <Snackbar
        message="Nom du dossier copié"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems='center'
    >
      <Grid
        item
        xs={11}
      >
        <Autocomplete
          value={currentFolder}
          onChange={(e, folder) => setHistoryCall({ ...historyCall, folderId: folder?.id ?? 0 })}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.intitule}
          options={options}
          noOptionsText={"Aucun dossier n'a été trouvé"}
          groupBy={(option) => option.folderName}
          loading={isFetchingFolders}
          autoSelect
          readOnly={readOnly}
          PaperComponent={CustomPaper}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.intitule}
              </li>
            );
          }}
          renderInput={(params, option) => (
            <TextField
              {...params}
              label={'Dossier'}
              sx={{
                '&:hover .MuiInputBase-root': { cursor: !readOnly ? 'text' : 'not-allowed' },
                '&:hover .MuiSvgIcon-root': { cursor: !readOnly ? 'text' : 'not-allowed' },
                input: { cursor: !readOnly ? 'text' : 'not-allowed' },
                label: {
                  cursor: !readOnly
                    ? 'text'
                    : 'not-allowed',
                  background: 'white',
                  padding: '0 4px'
                },
                position: 'relative',
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isFetchingFolders
                      ? <CircularProgress sx={{ color: "secondary.main" }} size={20} />
                      : null
                    }
                    {params.InputProps.endAdornment}
                    {
                      currentFolder && <FolderPopover folder={currentFolder} />
                    }
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={1}
      >
        <CopyFolderNameButton />
      </Grid>
    </Grid>
  );
}

HistoryCallModalSelectFolder.propTypes = {
  folders: PropTypes.arrayOf(
    PropTypes.exact({
      actions: PropTypes.arrayOf(
        PropTypes.exact({
          nom: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
          method: PropTypes.string.isRequired,
          titre: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          schemaUrl: PropTypes.string.isRequired,
        }),
      ),
      clerc: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      clercSecondaire: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      clientId: PropTypes.number.isRequired,
      dateCreation: PropTypes.node.isRequired,
      dateDernierAcces: PropTypes.node,
      dateModification: PropTypes.node.isRequired,
      dateOuvertureDossier: PropTypes.node.isRequired,
      datePrevisionnelleSignature: PropTypes.node,
      id: PropTypes.number.isRequired,
      idDossierParent: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
      liens: PropTypes.arrayOf(
        PropTypes.exact({
          href: PropTypes.string.isRequired,
          relation: PropTypes.string.isRequired,
          verbe: PropTypes.string.isRequired,
        }),
      ),
      montantPrevisionnel: PropTypes.number,
      nature: PropTypes.string.isRequired,
      nom: PropTypes.string.isRequired,
      notaire: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      numero: PropTypes.string.isRequired,
      secretaire: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      service: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      typeNature: PropTypes.string.isRequired,
      valeurMetierEtat: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
    }).isRequired,
  ).isRequired,
  fetchFolders: PropTypes.func.isRequired,
  // historique d'un appel
  historyCall: PropTypes.exact({
    id: PropTypes.string.isRequired,
    // object de l'historique
    object: PropTypes.string.isRequired,
    // contenue de l'historique
    description: PropTypes.string.isRequired,
    // indique si l'historique est urgent, a rappeler ou normal
    priority: PropTypes.number.isRequired,
    // collabateur en charge de l'historique
    collaboratorUuid: PropTypes.string.isRequired,
    // status du ticket : 0=HISTORY_STATUS_OPENED, 1=HISTORY_STATUS_CLOSED
    status: PropTypes.number.isRequired,
    // date de la derniere mise à jour de l'historique
    updatedAt: PropTypes.exact({
      seconds: PropTypes.number.isRequired,
      nanoseconds: PropTypes.number.isRequired,
    }).isRequired,
    // identifiant du dossier genapi
    folderId: PropTypes.number.isRequired,
  }).isRequired,
  // si le select est modifiable ou non
  readOnly: PropTypes.bool.isRequired,
  // si l'app est en train de recupérer les dossiers de Genapi
  isFetchingFolders: PropTypes.bool.isRequired,
  // identifie un client genapi
  clientId: PropTypes.number.isRequired,
}

export default HistoryCallModalSelectFolder;