import React from 'react'
import PropTypes from 'prop-types';
// components
import CallNumberMenu from './CallNumberMenu';
// Icons
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddBoxIcon from '@mui/icons-material/AddBox';

// Styles
import {
  StyledTypo,
  StyledInlinePaddingGrid,
} from "../styles/ClientsStyle";
// MUI
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";

// import de full client au chargement du compos

const ClientBody = ({ client }) => {
  return (
    <>
      {/* Coordonnées */}
      {(client.adresse?.voie || client.email || client.telephones[0])
        ? (
          <Box sx={{ mb: 2 }}>
            {/* Titre Coordonnées */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ContactPageIcon sx={{ color: 'secondary.light' }} />
              <Typography variant="h2" color='primary.light'>Coordonnées</Typography>
            </Box>
            {/* Body Coordonnées */}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
            >
              {/* Adresse */}
              {
                client.adresse?.typeVoie?.intitule ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>Adresse :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>
                      {` 
                        ${client.adresse?.numero ?? ''} 
                        ${client.adresse?.typeVoie?.intitule ?? ''} 
                        ${client.adresse?.voie ?? ''}
                        ${client.adresse?.complementAdresse ?? ''}, 
                        ${client.adresse?.commune?.codePostal ?? ''}
                        ${client.adresse?.commune?.nom ?? ''}
                        ${client.adresse?.pays.intitule ?? ''}
                      `}
                    </StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
              {/* Email */}
              {
                client.email ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>Email :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>{client.email}</StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
              {/* Telephone 1 */}
              {
                client.telephones[0] ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>{client.telephones[0].typeTelephone} :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>
                      <CallNumberMenu callNumber={client.telephones[0].numero} />
                    </StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
              {/* Telephone 2 */}
              {
                client.telephones[1] ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>{client.telephones[1].typeTelephone} :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>
                      <CallNumberMenu callNumber={client.telephones[1].numero} />
                    </StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
            </Grid>
          </Box>
        ) : (
          null
        )
      }
      {/* Informations sur la société */}
      {(client.formeSociete?.intitule || client.siren || client.typeClient || client.registreCommerce)
        ? (
          <Box sx={{ mb: 2 }}>
            <Box display='flex' alignItems='center'>
              <ApartmentIcon sx={{ color: 'secondary.light' }} />
              <Typography variant="h2" color={'primary.light'}>Informations sur la Société</Typography>
            </Box>
            <Grid
              container
              justifyContent="space-between"
            >
              {/* Forme de la Société */}
              {
                client.formeSociete?.intitule ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>Forme de la Société :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>{client.formeSociete.intitule}</StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
              {/* Siren */}
              {
                client.siren ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>Siren :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>{client.siren}</StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
              {/* Type de client */}
              {
                client.typeClient ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>Type de client :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>{client.typeClient}</StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
              {/* Registre commerce */}
              {
                client.registreCommerce ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>Registre Commerce :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>{client.registreCommerce}</StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
            </Grid>
          </Box>
        )
        : (
          null
        )
      }
      {/* Informations supplémentaires */}
      {(client.dateNaissance || client.etatMarital?.intitule)
        ? (
          <Box sx={{ mb: 2 }}>
            <Box display='flex' alignItems='center'>
              <AddBoxIcon sx={{ color: 'secondary.light' }} />
              <Typography variant="h2" color={'primary.light'}>Informations supplémentaires</Typography>
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
            >
              {/* Date de naissance */}
              {
                client.dateNaissance ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>Date de naissance :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>{client.dateNaissance.jour}/{client.dateNaissance.mois}/{client.dateNaissance.annee}</StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
              {/* État marital */}
              {
                client.etatMarital?.intitule ?
                  <StyledInlinePaddingGrid item xs={12} lg={6}>
                    <StyledTypo variant="body1" color={'primary'}>État marital :</StyledTypo>
                    <StyledTypo variant="body1" color={'secondary'}>{client.etatMarital.intitule}</StyledTypo>
                  </StyledInlinePaddingGrid>
                  : null
              }
            </Grid>
          </Box>
        )
        : (
          null
        )
      }
    </>
  )
}

ClientBody.propTypes = {
  client: PropTypes.exact({
    adresse: PropTypes.exact({
      commune: PropTypes.exact({
        codeInsee: PropTypes.string,
        codePostal: PropTypes.string.isRequired,
        nom: PropTypes.string.isRequired,
      }),
      complementAdresse: PropTypes.string.isRequired,
      extensionNumero: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string,
      }),
      numero: PropTypes.string.isRequired,
      pays: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      typeVoie: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      voie: PropTypes.string.isRequired,
    }),
    civilite: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    dateCreation: PropTypes.string,
    dateDeces: PropTypes.string,
    dateDernierAcces: PropTypes.string,
    dateModification: PropTypes.string,
    dateNaissance: PropTypes.exact({
      jour: PropTypes.number,
      mois: PropTypes.number,
      annee: PropTypes.number.isRequired,
    }),
    denomination: PropTypes.string.isRequired,
    email: PropTypes.string,
    etatMarital: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    formeSociete: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    id: PropTypes.number.isRequired,
    numero: PropTypes.string,
    registreCommerce: PropTypes.string,
    siren: PropTypes.string,
    telephones: PropTypes.arrayOf(
      PropTypes.exact({
        typeTelephone: PropTypes.string.isRequired,
        numero: PropTypes.string.isRequired,
      }),
    ),
    typeClient: PropTypes.string,
    dateRecuperation: PropTypes.exact({
      seconds: PropTypes.number.isRequired,
      nanoseconds: PropTypes.number.isRequired,
    }),
    tenantId: PropTypes.string,
  }).isRequired,
}

export default ClientBody