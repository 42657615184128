export const sampleContacts = [
  {
    "@id": "/contacts/7",
    "@type": "Contact",
    "id": 7,
    "name": "Siccardi Damien",
    "callNumber": "+33492042527",
    "url": "http://localhost:8069/web#id=7&model=res.partner",
    "contacts": [],
    "activities": [],
    "customer": "/customers/6"
  },
  {
    "@id": "/contacts/10",
    "@type": "Contact",
    "id": 10,
    "name": "Deco Addict",
    "callNumber": "+33493783910",
    "url": "http://localhost:8069/web#id=10&action=144&model=res.partner",
    "contacts": [
      "/contacts/26"
    ],
    "activities": []
  },
  {
    "@id": "/contacts/26",
    "@type": "Contact",
    "id": 26,
    "name": "Brandon Freeman",
    "callNumber": "+33660237869",
    "url": "http://localhost:8069/web#id=26&action=144&model=res.partner",
    "contact": "/contacts/10",
    "contacts": [],
    "activities": [
      "/activities/1",
      "/activities/3",
      "/activities/4",
      "/activities/5",
      "/activities/6",
      "/activities/7",
      "/activities/8",
      "/activities/9",
      "/activities/10",
      "/activities/11",
      "/activities/12",
      "/activities/13",
      "/activities/14",
      "/activities/49"
    ]
  },
  {
    "@id": "/contacts/10",
    "@type": "Contact",
    "id": 10,
    "name": "Tameldou Sophyane",
    "callNumber": "0628558963",
    "url": "http://localhost:8069/web#id=7&model=res.partner",
    "contacts": [],
    "activities": [],
    "customer": "/customers/6"
  },
  {
    "@id": "/contacts/11",
    "@type": "Contact",
    "id": 11,
    "name": "LeBron James",
    "callNumber": "0660237869",
    "url": "http://localhost:8069/web#id=7&model=res.partner",
    "contacts": [],
    "activities": [],
    "customer": "/customers/6"
  },
  {
    "@id": "/contacts/12",
    "@type": "Contact",
    "id": 12,
    "name": "Kobe Bryant",
    "callNumber": "310",
    "url": "http://localhost:8069/web#id=7&model=res.partner",
    "contacts": [],
    "activities": [
      "/activities/5",
      "/activities/6",
      "/activities/7",
      "/activities/8",
      "/activities/9",
    ],
    "customer": "/customers/5"
  },
  {
    "@id": "/contacts/13",
    "@type": "Contact",
    "id": 13,
    "name": "Karl Malone",
    "callNumber": "310",
    "url": "http://localhost:8069/web#id=7&model=res.partner",
    "contacts": [],
    "activities": [],
    "customer": "/customers/5"
  },
  {
    "@id": "/contacts/14",
    "@type": "Contact",
    "id": 14,
    "name": "Robert Parish",
    "callNumber": "311",
    "url": "http://localhost:8069/web#id=7&model=res.partner",
    "contacts": [],
    "activities": [
      "/activities/5",
      "/activities/6",
      "/activities/7",
      "/activities/8",
      "/activities/9",
    ],
    "customer": "/customers/5"
  },
  {
    "@id": "/contacts/15",
    "@type": "Contact",
    "id": 15,
    "name": "Stephen Curry",
    "callNumber": "0624845741",
    "url": "http://localhost:8069/web#id=7&model=res.partner",
    "contacts": [],
    "activities": [
      "/activities/5",
      "/activities/6",
      "/activities/7",
      "/activities/8",
      "/activities/9",
    ],
    "customer": "/customers/6"
  },
  {
    "@id": "/contacts/16",
    "@type": "Contact",
    "id": 16,
    "name": "Giánnis Antetokoúnmpo",
    "callNumber": "0644845841",
    "url": "http://localhost:8069/web#id=7&model=res.partner",
    "contacts": [],
    "activities": [],
    "customer": "/customers/6"
  }
]