export const getUniqueListBy = (arr, key) => [...new Map(arr.map(item => [item[key], item])).values()]

/**  Quand l'utilisateur se connecte, le token a une durée de vie de 7 jours (604800 * 1000) secondes
il expirera 3 jours avant sa fin de vie (3 * 86400 * 1000 = 259200 * 1000 secondes)
*/
export const getIsExpired = (expiresAt, options) => {
  if (undefined === expiresAt) {
    return true;
  }
  if ('Invalid Date' === expiresAt.toString()) {
    return true;
  }

  const currentOptions = {
    timeBeforeExpiration: options?.timeBeforeExpiration ?? 259200,
    debug: options?.debug ?? false,
  }
  const isExpired = new Date().getTime() + (currentOptions.timeBeforeExpiration * 1000) > expiresAt.getTime()

  if (currentOptions.debug) {
    console.log({ ...currentOptions })
    console.log({ expiresAt });
    console.log({ 'new Date()': new Date() })
    console.log({ 'result': isExpired })
  }

  return isExpired;
};

export const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
  str = str.toLowerCase(); // convert string to lowercase
  str = str.replace(/[^a-z0-9\u00C0-\u024F\u1E00-\u1EFF.&' -]/g, '') // remove any non-alphanumeric characters execpt character with accent and / & '
    //  .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
  return str;
}

export const timer = ms => new Promise(res => setTimeout(res, ms))

export const addWeeks = (date, weeks) => {
  date.setDate(date.getDate() + 7 * weeks);

  return date;
}

export const getTwoDigitTime = (time) => time.toString().padStart(2, '0');

export const formatDate = (dateToChange) => {
  // Input date string
  const inputDateString = dateToChange;

  // Create a new Date object with the input date string
  const date = new Date(inputDateString);

  // Extract date components
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Formatted date string
  const formattedDateString = `${day}/${month}/${year} à ${hours}h${minutes}`;

  return formattedDateString;
}

// Suppression de @id, @Context et @type
export const deleteUselessObjectKeysFromApi = (objectToFormat) => {
  delete objectToFormat['@context'];
  delete objectToFormat['@id'];
  delete objectToFormat['@type'];
  return objectToFormat
}

export const isLightBackground = (color) => {
  // Convertir la couleur hexadécimale en valeurs RVB
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);
  // Calculer la luminosité en fonction des valeurs RVB
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  // Retourner true si la luminosité est supérieure à un seuil, indiquant un fond clair
  return brightness > 150;
};

/**
 * Récupère la date antérieur au nombre de jour retirer en ne prenant pas en compte les jours ouvrés.
 * 
 * @param {Date} date 
 * @param {Integer} daysToSubtract 
 * @returns 
 */
export const getPreviousWorkingDate = (date, daysToSubtract) => {
  function isWeekday(date) {
    const day = date.getDay();
    return day !== 0 && day !== 6; // 0: Dimanche, 6: Samedi
  }

  const previousDate = new Date(date);
  let daysSubtracted = 0;
  while (daysSubtracted < daysToSubtract) {
      previousDate.setDate(previousDate.getDate() - 1);
      if (isWeekday(previousDate)) {
          daysSubtracted++;
      }
  }

  return previousDate;
} 