// React
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// TreeView
import { TreeView } from '@mui/x-tree-view/TreeView';

// Mui Material
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
  Link,
  Breadcrumbs,
} from '@mui/material';

// Icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MailIcon from '@mui/icons-material/Mail';
import UndoIcon from '@mui/icons-material/Undo';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';

// Components
import TreeNode from './TreeNode';
import Loader from './Loader';

// Styles
import { StyledTypo } from "../styles/ClientsStyle";
import { styled } from '@mui/material/styles';
import FilesTable from './FilesTable';
import HeaderFilesTypes from './HeaderFilesType';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// Creation de l'arbre a donnée de classeur 
const createTree = (binders) => {
  // Crée un dictionnaire pour stocker les éléments en fonction de leur 'attachedTo'
  const dataDict = {};
  binders.forEach(item => {
    const parentId = item.idClasseurParent;
    if (!dataDict[parentId]) {
      dataDict[parentId] = [];
    }
    dataDict[parentId].push(item);
  });

  // Fonction récursive pour construire la structure d'arborescence
  function buildTree(parentId) {
    if (!dataDict[parentId]) {
      return [];
    }

    const tree = dataDict[parentId].map(item => {
      const children = buildTree(item.id);
      if (children.length > 0) {
        item.children = children;
      }
      return item;
    });

    return tree;
  }
  // Appelle la fonction pour créer l'arborescence
  const tree = buildTree(0); // 0 est le niveau racine
  return tree
}

const ClientFolderDialog = ({
  isDisplayedClientFolderDialog,
  saveIsDisplayedClientFolderDialog,
  saveFolderDisplayed,
  folderDisplayed,
  saveFiles,
  files,
  fetchFile,
  uploadFile,
  binders,
  saveBinders,
  selectedBinder,
  saveSelectedBinder,
  isFetchingFiles,
  isUploadingFile,
}) => {

  const listeTypeDoc = [
    { id: 0, nom: "Tous", intitule: "tous", icon: "" },
    { id: 1, nom: "Actes", intitule: "Acte", icon: <ReceiptLongIcon color="white" /> },
    { id: 2, nom: "Courriers", intitule: "Courrier", icon: <MailIcon color="white" /> },
    { id: 3, nom: "Pièces", intitule: "EDocument", icon: <DocumentScannerIcon color="white" /> },
    { id: 4, nom: "Sous-produits", intitule: "SousProduit", icon: <ShoppingCartIcon color="white" /> },
  ]
  const [formatedBinders, setFormatedBinders] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [countFilesByBinder, setCountFilesByBinder] = useState({});
  const [binderAncestors, setBinderAncestors] = useState([]);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dateCreation');
  const [type, setType] = useState('tous');

  const iconMapping = {
    Courrier: <MailIcon color={"primary"} />,
    Acte: <ReceiptLongIcon color={"primary"} />,
    EDocument: <DocumentScannerIcon color={"primary"} />,
    SousProduit: <ShoppingCartIcon color={"primary"} />
  };

  // Fonction de formatage de date
  function formatDate(dateString) {
    const date = dateString.split('T');
    return new Date(date[0]).toLocaleDateString("fr");
  }

  // Reset sort
  useEffect(() => {
    setOrder('desc');
    setOrderBy('dateCreation');
  }, [])

  // Creation de l'arbre de donnée
  useEffect(() => {
    if (Object.keys(binders).length !== 0) {
      const tree = createTree(binders)
      setFormatedBinders(tree)
    }
  }, [binders])

  // Filtre des fichiers par classeur et type
  useEffect(() => {
    var newList = files;
    if (Object.entries(files).length !== 0) {
      if (selectedBinder) {
        newList = files.filter((file) => file.idClasseurParent === parseInt(selectedBinder))
      }
      newList = newList.map(file => ({
        ...file,
        "dateCreation": formatDate(file.dateCreation),
      }));
    }

    if (Object.entries(newList).length !== 0 && type !== 'tous') {
      newList = newList.filter((fileByType) => fileByType.type === type);
    }

    // Reset sort
    setOrder('desc');
    setOrderBy('dateCreation');

    setFilteredFiles(newList)
  }, [selectedBinder, files, type])

  // Sort files
  useEffect(() => {
    if (Object.entries(filteredFiles).length !== 0) {

      filteredFiles.sort(function (a, b) {

        var valueA = a[orderBy];
        var valueB = b[orderBy];

        if (order === 'asc') {
          if (valueA < valueB) {
            return -1;
          } else if (valueA > valueB) {
            return 1;
          }
          return 0;
        } else {
          if (valueA > valueB) {
            return -1;
          } else if (valueA < valueB) {
            return 1;
          }
          return 0;
        }
      });
    }
  }, [filteredFiles, orderBy, order, selectedBinder])

  // Nombre de fichier/classeur par classeur
  useEffect(() => {
    var newCount = {}
    // Count fichier
    if (Object.entries(files).length !== 0) {
      files.forEach(file => {
        newCount[file.idClasseurParent] = true;
      });
    }
    // Count classeur
    if (Object.keys(binders).length !== 0) {
      binders.forEach(binder => {
        newCount[binder.idClasseurParent] = true;
      });
    }
    setCountFilesByBinder(newCount);

  }, [files, binders])

  // trouve l'enfant d'un classeur
  const findChildren = (binders, children) => {
    return binders.find(item => item.id === children);
  }

  // Recupere tous les parents d'un classeur séléctioné
  useEffect(() => {
    if (Object.keys(binders).length !== 0 && selectedBinder !== '') {
      const ancestors = [];
      var firstBinderReached = false;

      var children = parseInt(selectedBinder);
      var count = 20;
      var start = 0;

      while (!firstBinderReached && start < count) {
        var parentItem = findChildren(binders, children);

        ancestors.unshift(parentItem.id);
        if (parentItem.idClasseurParent === 0) {
          firstBinderReached = true;
        } else {
          children = parentItem.idClasseurParent;
        }
        start += 1;
      }
      setBinderAncestors(ancestors);
    } else {
      setBinderAncestors([]);
    }

  }, [selectedBinder, binders])

  // Gestion du tri
  const handleRequestSort = (value) => {
    const isAsc = orderBy === value && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(value);
  };

  // Gestion de la fermeture du dialog
  const onCloseDialog = () => {
    saveIsDisplayedClientFolderDialog(false);
    saveFolderDisplayed({});
    saveFiles([]);
    saveBinders([]);
    saveSelectedBinder('');
    setBinderAncestors([]);
    setOrder('desc');
    setOrderBy('dateCreation');
    setType('tous');
  }

  // Gestion filtre par type
  const handleChange = (selectedType) => {
    setType(selectedType);
  };

  // console.log(isFetchingFiles, isUploadingFile, filteredFiles)

  return (
    <>
      <Dialog
        open={isDisplayedClientFolderDialog}
        maxWidth="lg"
        fullWidth
        onClose={onCloseDialog}
        sx={{ minWidth: '800px' }}
      >
        {/* Nom du dossier */}
        <Box textAlign='center'>
          <Typography variant="h1" color={'primary'} sx={{ display: ' inline-block', minWidth: '500px' }}>{folderDisplayed.intitule}</Typography>
        </Box>
        <DialogContent sx={{ paddingTop: '0px', minHeight: '50vh' }}>
          {/* Titre */}
          <DialogContentText sx={{ paddingBottom: '20px' }}>
            Vous avez la faculté de récupérer les documents qui ont été incorporés à votre logiciel de gestion des relations clients.
            Il s'agit des documents contenus dans le dossier dénommé <span style={{ fontWeight: 'bold' }}>{folderDisplayed.intitule}</span>.<br />
            <br />
            Veuillez noter que seuls les documents électroniques (EDocument) sont autorisés à être envoyés vers votre logiciel de gestion des actes notariés.
          </DialogContentText>
          {!isFetchingFiles ?
            <Grid container spacing={2} sx={{ backgroundColor: 'white', borderRadius: '5px', boxShadow: 3, height: '60vh' }}>
              <Grid item xs={4} sx={{
                backgroundColor: '#FFFFFF',
                overflow: 'auto',
                padding: '0 !important',
                borderEndStartRadius: '5px',
                borderStartStartRadius: '5px',
                borderRight: '1px solid lightgrey',
              }}>
                {/* Header Classeur */}
                <AppBar
                  position="sticky"
                  color='secondary'
                >
                  <Toolbar>
                    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                      <Box display="flex" alignItems="center">
                        <FolderIcon sx={{ paddingRight: "5px" }} />
                        <Typography variant="h6" noWrap component="div">
                          Mes Classeurs
                        </Typography>
                      </Box>

                      <IconButton
                        color="secondary"
                        size="small"
                        aria-label="reset filter"
                        component="div"
                        title="réinitialisation des filtres"
                        onClick={() => saveSelectedBinder('')}
                      >
                        <RestartAltIcon color={'white'} sx={{ marginBlock: 'auto', fontSize: ['20px', null, '26px'] }} />
                      </IconButton>
                    </Box>
                  </Toolbar>
                </AppBar>
                {/* Arbre de classeur */}
                {(formatedBinders && Object.keys(formatedBinders).length !== 0 && Object.keys(binders).length !== 0) ?
                  <Box sx={{ height: '45vh', flexGrow: 1, maxWidth: 300 }}>
                    <TreeView
                      selected={selectedBinder}
                      aria-label="icon expansion"
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      sx={{ paddingTop: '10px' }}
                    >
                      <TreeNode tree={formatedBinders} saveSelectedBinder={saveSelectedBinder} countFilesByBinder={countFilesByBinder} />
                    </TreeView>
                  </Box>
                  :
                  // Pas de dossier trouvé
                  <Box sx={{ height: '50vh', flexGrow: 1, maxWidth: 300, padding: '10px' }}>
                    <StyledTypo variant="h4" color={'secondary.light'}>Aucun classeur ne réside dans ce répertoire.</StyledTypo>
                  </Box>
                }
              </Grid>
              <Grid item xs={8} sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 !important',
                borderStartEndRadius: '5px',
                borderEndEndRadius: '5px',
                borderLeft: '1px solid lightgrey',
              }}>
                {/* Header Documents */}
                <AppBar position="sticky" sx={{ borderStartEndRadius: '5px', boxShadow: '0px 0px 0px' }}>
                  <Toolbar>
                    {(binderAncestors && Object.keys(binderAncestors).length !== 0 && Object.keys(binders).length !== 0) ?
                      <Box>
                        <Breadcrumbs aria-label="breadcrumb" color="#fff">
                          <Link underline="hover" variant="h6" noWrap component="div" color="#fff" sx={{ cursor: 'pointer' }} onClick={() => {
                            saveSelectedBinder('');
                          }}>
                            Mes Documents
                          </Link>
                          {binderAncestors.map((binder) => {
                            const openedBinder = binders.find(item => item.id === binder);
                            return (
                              <Link key={openedBinder.id} underline="hover" variant="h6" noWrap component="div" color="#fff" sx={{ cursor: 'pointer' }} onClick={() => {
                                saveSelectedBinder(openedBinder.id.toString());
                              }}>
                                {openedBinder.nom}
                              </Link>
                            )
                          })}
                        </Breadcrumbs>
                      </Box>
                      :
                      <Box display="flex" alignItems="center">
                        <InsertDriveFileIcon sx={{ paddingRight: "5px" }} />
                        <Typography variant="h6" noWrap component="div">
                          Mes Documents
                        </Typography>
                      </Box>
                    }
                  </Toolbar>
                </AppBar>
                {/* Tableau de fichier */}
                {(isFetchingFiles || isUploadingFile) ?
                  (
                    <>
                      <HeaderFilesTypes listeTypeDoc={listeTypeDoc} handleChange={handleChange} type={type} />
                      <Box position={"relative"} sx={{height: '45vh'}}>
                        <Loader size={100} color="secondary" message={"Chargement des fichiers"}/>
                      </Box>
                    </>
                  )
                  :
                  (filteredFiles && Object.keys(filteredFiles).length !== 0)
                    ? (
                      <>
                        <HeaderFilesTypes listeTypeDoc={listeTypeDoc} handleChange={handleChange} type={type} />
                        {/* Tableau affichant les fichiers */}
                        <FilesTable
                          orderBy={orderBy}
                          order={order}
                          filteredFiles={filteredFiles}
                          iconMapping={iconMapping}
                          handleRequestSort={handleRequestSort}
                          fetchFile={fetchFile}
                        />
                      </>
                    )
                    : (
                      <>
                        {/* Pas de fichier trouvé */}
                        <HeaderFilesTypes listeTypeDoc={listeTypeDoc} handleChange={handleChange} type={type} />
                        <Box sx={{ height: '45vh' }}>
                          <StyledTypo variant="h4" color={'secondary.light'} sx={{ padding: '10px' }}>Aucun document ne réside dans ce répertoire.</StyledTypo>
                        </Box>
                      </>
                    )
                }
              </Grid>
            </Grid>
            :
            <Box sx={{ height: '60vh'}}>
              <Loader  size={100} color="secondary" message={"Chargement des fichiers"}/>
            </Box>
            
          }
        </DialogContent>
        {/* Boutons */}
        <DialogActions>
          {/* Upload dans un classeur iNOT */}
          <Button component="label" variant="contained" color="primary" startIcon={<FileUploadIcon />} sx={{ margin: '5px' }}>
            Envoyer un fichier
            <VisuallyHiddenInput type="file" onChange={(e) => {
              uploadFile(e.target.files[0], folderDisplayed.id, selectedBinder);
              // Réinitialiser l'input après l'upload
              e.target.value = "";
            }} />
          </Button>
          {/* Fermeture du dialog */}
          <Button variant="contained" color="secondary" startIcon={<UndoIcon />} onClick={onCloseDialog} sx={{ margin: '5px' }}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ClientFolderDialog.propTypes = {
  // Affichage du Dialog/Modal
  isDisplayedClientFolderDialog: PropTypes.bool.isRequired,
  // Gestion de l'affichage du Dialog/Modal
  saveIsDisplayedClientFolderDialog: PropTypes.func.isRequired,
  // Gestion du dossier affiché
  saveFolderDisplayed: PropTypes.func.isRequired,
  // Dossier affiché
  folderDisplayed: PropTypes.exact({
    // Clerc attaché au dossier
    clerc: PropTypes.exact({
      id: PropTypes.number.isRequired,
      identite: PropTypes.string.isRequired,
      initiales: PropTypes.string.isRequired,
    }),
    // Clerc secondaire attaché au dossier
    clercSecondaire: PropTypes.exact({
      id: PropTypes.number.isRequired,
      identite: PropTypes.string.isRequired,
      initiales: PropTypes.string.isRequired,
    }),
    clientId: PropTypes.number,
    // Date de création du dossier
    dateCreation: PropTypes.array,
    // Date du dernier accès au dossier
    dateDernierAcces: PropTypes.array,
    // Date de dernière modification
    dateModification: PropTypes.array,
    // Date d'ouverture du dossier
    dateOuvertureDossier: PropTypes.array,
    // Date de signature previsionnelle
    datePrevisionnelleSignature: PropTypes.array,
    // Id du dossier
    id: PropTypes.number,
    // Id du dossier parent
    idDossierParent: PropTypes.number,
    // Intitule du dossier
    intitule: PropTypes.string,
    // Montant previsionnel
    montantPrevisionnel: PropTypes.number,
    // Nature du dossier
    nature: PropTypes.string,
    // Nom du dossier
    nom: PropTypes.string,
    // Notaire
    notaire: PropTypes.exact({
      id: PropTypes.number.isRequired,
      identite: PropTypes.string.isRequired,
      initiales: PropTypes.string.isRequired,
    }),
    // Numero
    numero: PropTypes.string,
    // Secretaire
    secretaire: PropTypes.exact({
      id: PropTypes.number.isRequired,
      identite: PropTypes.string.isRequired,
      initiales: PropTypes.string.isRequired,
    }),
    // Service
    service: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    // Type nature
    typeNature: PropTypes.string,
    // Etat du dossier
    valeurMetierEtat: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
  }),
  // Sauvegarde des fichiers 
  saveFiles: PropTypes.func.isRequired,
  // Fichiers attachés au dossier
  files: PropTypes.arrayOf(
    PropTypes.exact({
      dateCreation: PropTypes.string.isRequired,
      dateModification: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      idClasseurParent: PropTypes.number,
      intitule: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      uploadTermine: PropTypes.bool.isRequired,
    }).isRequired,
  ),
  // Récupère les fichiers attachés au dossier
  fetchFile: PropTypes.func.isRequired,
  // envoie d'un fichier vers INOT
  uploadFile: PropTypes.func.isRequired,
  // Classeurs
  binders: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      idClasseurParent: PropTypes.number.isRequired,
      idSousDossierParent: PropTypes.number.isRequired,
      nom: PropTypes.string.isRequired,
      ordre: PropTypes.number.isRequired,
      children: PropTypes.array,
    }).isRequired,
  ),
  // Sauvegarde des classeurs
  saveBinders: PropTypes.func.isRequired,
  // Classeur séléctionné
  selectedBinder: PropTypes.string.isRequired,
  // Gestion du classeur séléctionné
  saveSelectedBinder: PropTypes.func.isRequired,
  // Gestion de l'Affichage des fichiers
  isFetchingFiles: PropTypes.bool.isRequired,
  isUploadingFile: PropTypes.bool.isRequired,
}

export default ClientFolderDialog;
