// actions
import {
  FETCH_USERS,
  saveUsers,
  saveIsFetchingUsers,
} from "./actionUsers";
// Affichage / Gestion des erreurs
import { saveSnackbar } from '../snackbar/actionSnackbar';

const middlewareUsers = (store) => (next) => (action) => {
  const handleErrors = (tryName, error) => {
    console.log(`Oups ! Une erreur inattendue s'est produite : ${tryName} ~ ${error.name} ~ ${error.code}`, 'error')
    store.dispatch(saveSnackbar(`Oups ! Une erreur inattendue s'est produite : ${error.name} ~ ${error.code}`, 'error'));
  }

  switch (action.type) {
    case FETCH_USERS:
      const getUsers = async () => {
        try {
          const rawUsers = await fetch(`https://${store.getState().login.server}/api/confd/1.1/users`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "X-Auth-Token": store.getState().login.token,
              "Wazo-Tenant": store.getState().login.tenantUuid
            },
          })

          const usersJson = await rawUsers.json();
          
          const users = usersJson.items
            .filter((user) => user.enabled)
            .map(({ lines, uuid, firstname, lastname, email }) => ({ lines: lines.map(({ caller_id_num }) => caller_id_num).filter((line) => null !== line), uuid, firstname, lastname, email, isActive: true }))
          ;

          store.dispatch(saveUsers(users));
        } catch (error) {
          console.log("getUsers ~ error:", error)
          handleErrors('FETCH_USERS', error);
        } finally {
          store.dispatch(saveIsFetchingUsers(false))
        }
      }
      store.dispatch(saveIsFetchingUsers(true))
      getUsers();

      next(action)
      break


    default: next(action)
  }
}

export default middlewareUsers;