import {
  SAVE_CALLS,
  REPLACE_CALLS,
  SAVE_CALL,
  UPDATE_CALL,
  DELETE_CALL,
  SAVE_IS_FETCHING_CALLS,
  SAVE_IS_FETCHING_MORE_CALLS,
  SAVE_HISTORY_CALLS,
  SAVE_HISTORY_CALL,
  UPDATE_HISTORY_CALL,
  SAVE_IS_DISPLAYED_CALL_HISTORY_MODAL,
  SAVE_CALLS_BY_COLLABORATOR_UUID,
  SAVE_SEARCHED_CALLS_BY_COLLABORATOR_UUID,
  UPDATE_CALL_BY_COLLABORATOR_UUID,
  SAVE_CALLS_BY_COLLABORATOR_UUID_LAST_VISIBLE,
  SAVE_SEARCH_CALLS_BY_UUID_LAST_VISIBLE,
  DELETE_CALL_BY_COLLABORATOR_UUID,
  REPLACE_CALLS_BY_COLLABORATOR_UUID,
  REPLACE_SEARCHED_CALLS_BY_COLLABORATOR_UUID,
  SAVE_IS_DISPLAYED_CALL_CLIENT_DIALOG,
  SAVE_HISTORY_CALL_MESSAGE,
  SAVE_BLANK_HISTORY_CALL_MESSAGE,
  SAVE_CALLS_BY_CLIENT_ID,
  REPLACE_CALLS_BY_CLIENT_ID,
  UPDATE_CALL_BY_CLIENT_ID,
  SAVE_IS_DISPLAYED_CALLS_SEARCH_DIALOG,
  SAVE_CALL_FILTERS,
  DELETE_FILTER,
  DELETE_CALLS,
  DELETE_HISTORY_CALLS,
  DELETE_HISTORY_CALL_MESSAGE,
  DELETE_HISTORY_CALL_MESSAGES,
  DELETE_CALLS_BY_UUID,
  DELETE_CALLS_BY_CLIENT_ID,
  DELETE_CALLS_BY_COLABORATOR_UUID,
  ADD_MORE_CALL_PERIOD,
} from "./actionCalls";

import { getUniqueListBy } from '../../utils';

import {
  PAGE_CALLS,
  PAGE_CALL_HISTORY,
} from '../../pages';

/** nombre d'appel supplémentaire a chaque requete vers firebase */
export const MORE_CALL = 20;
/** Priorité de l'historique de l'appel */
export const HISTORY_DEFAULT_PRIORITY = -1;
export const HISTORY_NORMAL_PRIORITY = 0;
export const HISTORY_CALLBACK_PRIORITY = 1;
export const HISTORY_URGENT_PRIORITY = 2;

/** status de l'historique */
export const HISTORY_STATUS_DEFAULT = -1;
export const HISTORY_STATUS_OPENED = 0;
export const HISTORY_STATUS_CLOSED = 1;

const initialStateTest = {
  calls: [
    // {
    //   id: '183273393.435',
    //   callNumber: '0971181066',
    //   username: '',
    //   userExtension: '0971181065',
    //   direction: 'inbound',
    //   startTime: '09:56:33',
    //   heldStartTime: '09:57:00',
    //   date: '2023-05-05T09:57:00',
    //   status: 'call_created',
    //   userId: '0000-0000000-000',
    //   historyId: '1111-111111-111',
    // },
  ],
  callsByCollaboratorUuid: [
    // ...calls
  ],
  callsByCollaboratorUuidLastVisble: null,
  isFetchingCalls: false,
  isFetchingMoreCalls: false,
  isDisplayedHistoryCallModal: false,
  isDisplayedCallClientDialog: false,
  callsByClientId: [
    // ...calls
  ],
  isDisplayedCallsSearchDialog: false,
  filters: {},
  searchedCallsByUuidLastVisible: null,
  searchedCallsByCollaboratorUuid: [
    // ...calls
  ],
}

const reducerCalls = (state = initialStateTest, action) => {

  switch (action.type) {
    case SAVE_CALLS:
      return {
        ...state,
        calls: [
          ...state.calls,
          ...action.calls,
        ]
      }

    case REPLACE_CALLS:
      return {
        ...state,
        calls: action.calls,
      }

    case SAVE_CALL:
      return {
        ...state,
        calls: [
          ...state.calls,
          action.call,
        ]
      }

    case UPDATE_CALL:
      const newCalls = state.calls.reduce(
        (accumulator, currentCall) => {
          // si il s'agit du call à update
          if (action.call.id === currentCall.id) {
            // recupère le calls en construction et met à jour ce call
            return [...accumulator, { ...currentCall, ...action.call }]
          }
          // sinon continue de contruire calls
          return [...accumulator, currentCall]
        },
        // lors de la 1ere itération le calls en contruction est un tableau vide
        [],
      )

      return {
        ...state,
        calls: newCalls,
      }

    case DELETE_CALL:
      return {
        ...state,
        calls: state.calls.filter((id) => id !== action.call.id)
      }

    case SAVE_IS_FETCHING_CALLS:
      return {
        ...state,
        isFetchingCalls: action.isFetchingCalls,
      }

    case SAVE_IS_FETCHING_MORE_CALLS:
      return {
        ...state,
        isFetchingMoreCalls: action.isFetchingMoreCalls,
      }

    case SAVE_HISTORY_CALLS:
      const uniqueHistoryCalls = getUniqueListBy([...state.historyCalls, ...action.historyCalls], 'id')

      return {
        ...state,
        historyCalls: uniqueHistoryCalls,
      }

    case SAVE_HISTORY_CALL:
      return {
        ...state,
        historyCalls: [
          ...state.historyCalls,
          action.historyCall,
        ]
      }

    case UPDATE_HISTORY_CALL:
      const newHistoryCalls = state.historyCalls.reduce(
        (accumulator, currentHistoryCall) => {
          // si il s'agit du historyCall à update
          if (action.historyCall.id === currentHistoryCall.id) {
            // recupère le historyCall en construction et met à jour cet historyCall
            return [...accumulator, { ...currentHistoryCall, ...action.historyCall }]
          }
          // sinon continue de contruire historyCall
          return [...accumulator, currentHistoryCall]
        },
        // lors de la 1ere itération le historyCall en contruction est un tableau vide
        [],
      )

      return {
        ...state,
        historyCalls: newHistoryCalls,
      }

    case SAVE_IS_DISPLAYED_CALL_HISTORY_MODAL:
      return {
        ...state,
        isDisplayedHistoryCallModal: {
          ...state.isDisplayedHistoryCallModal,
          [action.callId]: action.isDisplayedHistoryCallModal,
        },
      }

    case SAVE_IS_DISPLAYED_CALL_CLIENT_DIALOG:
      return {
        ...state,
        isDisplayedCallClientDialog: {
          ...state.isDisplayedCallClientDialog,
          [action.callId]: action.isDisplayedCallClientDialog,
        },
      }

    case SAVE_CALLS_BY_COLLABORATOR_UUID:
      const callsList = [
        ...state.callsByCollaboratorUuid,
        ...action.callsByCollaboratorUuid
      ];

      const uniqueCalls = getUniqueListBy([...callsList], 'historyId')

      return {
        ...state,
        callsByCollaboratorUuid: uniqueCalls,
      }

    case SAVE_SEARCHED_CALLS_BY_COLLABORATOR_UUID:
      const searchedCallsList = [
        ...state.searchedCallsByCollaboratorUuid,
        ...action.searchedCallsByCollaboratorUuid
      ];

      const uniqueSearchedCalls = getUniqueListBy([...searchedCallsList], 'historyId')

      return {
        ...state,
        searchedCallsByCollaboratorUuid: uniqueSearchedCalls,
      }

    case UPDATE_CALL_BY_COLLABORATOR_UUID:
      const newCallByCollaboratorUuid = state.callsByCollaboratorUuid.reduce(
        (accumulator, currentCallByCollaboratorUuid) => {
          // si il s'agit du call à update
          if (action.callByCollaboratorUuid.id === currentCallByCollaboratorUuid.id) {
            // recupère le calls en construction et met à jour ce call
            return [...accumulator, { ...currentCallByCollaboratorUuid, ...action.callByCollaboratorUuid }]
          }
          // sinon continue de contruire calls
          return [...accumulator, currentCallByCollaboratorUuid]
        },
        // lors de la 1ere itération le calls en contruction est un tableau vide
        [],
      )

      return {
        ...state,
        callsByCollaboratorUuid: newCallByCollaboratorUuid,
      }

    case SAVE_CALLS_BY_COLLABORATOR_UUID_LAST_VISIBLE:
      return {
        ...state,
        callsByCollaboratorUuidLastVisble: action.callsByCollaboratorUuidLastVisble,
      }

    case SAVE_SEARCH_CALLS_BY_UUID_LAST_VISIBLE:
      return {
        ...state,
        searchedCallsByUuidLastVisible: action.searchedCallsByUuidLastVisible,
      }

    case REPLACE_CALLS_BY_COLLABORATOR_UUID:
      return {
        ...state,
        callsByCollaboratorUuid: action.callsByCollaboratorUuid,
      }

    case REPLACE_SEARCHED_CALLS_BY_COLLABORATOR_UUID:
      return {
        ...state,
        searchedCallsByCollaboratorUuid: action.searchedCallsByCollaboratorUuid,
      }

    // Suppression des appels par collaborator id
    case DELETE_CALL_BY_COLLABORATOR_UUID:
      return {
        ...state,
        callsByCollaboratorUuid: state.callsByCollaboratorUuid.filter(({ id }) => id !== action.callByCollaboratorUuid.id)
      }

    case SAVE_HISTORY_CALL_MESSAGE:
      const historyCallMessages = state.historyCallMessages?.[action.historyCallMessage.historyCallId]
        && Object.keys(state.historyCallMessages?.[action.historyCallMessage.historyCallId]).length !== 0
        ? (
          [
            action.historyCallMessage,
            ...state.historyCallMessages[action.historyCallMessage.historyCallId],
          ]
        )
        : (
          [action.historyCallMessage]
        )

      const uniqueHistoryCallMessages = getUniqueListBy([...historyCallMessages], 'id')

      return {
        ...state,
        historyCallMessages: {
          ...state.historyCallMessages,
          [action.historyCallMessage.historyCallId]: uniqueHistoryCallMessages,
        }
      }

    case SAVE_BLANK_HISTORY_CALL_MESSAGE:
      return {
        ...state,
        historyCallMessages: {
          ...state.historyCallMessages,
          [action.historyCallId]: state.historyCallMessages?.[action.historyCallId] ?? [],
        }
      }

    case DELETE_HISTORY_CALL_MESSAGE:
      return {
        ...state,
        historyCallMessages: {
          ...state.historyCallMessages,
          [action.historyCallMessage.historyCallId]: state.historyCallMessages[action.historyCallMessage.historyCallId].filter(
            (historyCallMessage) => historyCallMessage.id !== action.historyCallMessage.id
          )
        }
      }

    // Sauvegarde les appels par client id
    case SAVE_CALLS_BY_CLIENT_ID:
      return {
        ...state,
        callsByClientId: [
          ...state.callsByClientId,
          ...action.callsByClientId,
        ]
      }

    // Remplacer les appels par client id
    case REPLACE_CALLS_BY_CLIENT_ID:
      return {
        ...state,
        callsByClientId: action.callsByClientId,
      }

    case UPDATE_CALL_BY_CLIENT_ID:
      const newCallByClientId = state.callsByClientId.reduce(
        (accumulator, currentCallByClientId) => {
          // si il s'agit du call à update
          if (action.callByClientId.id === currentCallByClientId.id) {
            // recupère le calls en construction et met à jour ce call
            return [...accumulator, { ...currentCallByClientId, ...action.callByClientId }]
          }
          // sinon continue de contruire calls
          return [...accumulator, currentCallByClientId]
        },
        // lors de la 1ere itération le calls en contruction est un tableau vide
        [],
      )

      return {
        ...state,
        callsByClientId: newCallByClientId,
      }


    case SAVE_IS_DISPLAYED_CALLS_SEARCH_DIALOG:
      return {
        ...state,
        isDisplayedCallsSearchDialog: action.isDisplayedCallsSearchDialog,
      }

    case SAVE_CALL_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.currentPageName]: {
            ...action.filters,
            ...action.defaultFilters
          }
        }
      }

    case DELETE_FILTER:
      if(PAGE_CALLS === action.currentPageName){
        localStorage.removeItem('callsExpireAt');
      } else if(PAGE_CALL_HISTORY === action.currentPageName){
        localStorage.removeItem('callsByCollaboratorUuidExpireAt');
      }else{
        localStorage.removeItem('callsByClientIdExpireAt');
      }

      
      delete state.filters[action.currentPageName];
      return {
        ...state,
        filters: state.filters,
      }

    case DELETE_CALLS:
      return {
        ...state,
        calls: [],
      }

    case DELETE_HISTORY_CALLS:
      return {
        ...state,
        historyCalls: [],
      }

    case DELETE_HISTORY_CALL_MESSAGES:
      return {
        ...state,
        historyCallMessages: [],
      }

    case DELETE_CALLS_BY_UUID:
      return {
        ...state,
        callsByCollaboratorUuid: [],
        callsByCollaboratorUuidLastVisble: null,
        searchedCallsByUuidLastVisible: null,
        searchedCallsByCollaboratorUuid: [],
      }

    case DELETE_CALLS_BY_COLABORATOR_UUID:
      return {
        ...state,
        callsByCollaboratorUuid: [],
      }

    case DELETE_CALLS_BY_CLIENT_ID:
      return {
        ...state,
        callsByClientId: [],
      }

    case ADD_MORE_CALL_PERIOD:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.currentPageName]: {
            ...state.filters[action.currentPageName],
            moreCalls: state.filters[action.currentPageName].moreCalls + 1,
          }
        }
      }

    default: return state
  }
}
export default reducerCalls;