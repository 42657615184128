// React
import React from 'react'
import { useLocation } from 'react-router-dom';

// MUI
import { Box, IconButton } from '@mui/material';

// Components
import Loader from './Loader';

// Icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const MoreCallsButton = ({
  isFetchingMoreCalls,
  filters,
  addMoreCallPeriod,
  searchCalls,
  fetchCalls,
}) => { 
  const location = useLocation();

  /**
   * Recupère les recuderCalls.MORE_CALL appels supplémentaire depuis la base de données
   */
  const handleOnClickMoreButton = () => {
    if (filters[location.pathname]) {
      addMoreCallPeriod(location.pathname);
      searchCalls(true);
    } else {
      fetchCalls();
    }
  }

  return (
    <Box sx={{ textAlign: 'center' }}>
      {/* more button */}
        { isFetchingMoreCalls
          ? (
            <Loader top="15%" size={30} color="secondary" />
          )
          : (
            filters[location.pathname]?.startDateFilter || filters[location.pathname]?.endtDateFilter ?
              ''
            : (
              <IconButton
                color="secondary"
                size="large"
                aria-label="more history icon"
                component="div"
                title="anciens historiques"
                onClick={handleOnClickMoreButton}
              >
                <MoreHorizIcon />
              </IconButton>
            )
        )
      }
    </Box>
  )
}

export default MoreCallsButton;