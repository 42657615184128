// React Redux
import { connect } from "react-redux";

// Components
import Call from "../components/Call";
// actions
import { saveIsDisplayedActivityModal } from "../redux/activities/actionActivities";

const mapStateToProps = (state, ownProps) => {
  const activity = state.activities.activities.filter((activityDisplayed) => activityDisplayed.call.id === ownProps.id)[0]

  return ({
    activity: activity,
    users: state.users.users,
    tags: state.contacts.tags,
  })
}

const mapDispatchToProps = (dispatch) => ({
  saveIsDisplayedActivityModal: (callId, isDisplayedActivityModal) => dispatch(saveIsDisplayedActivityModal(callId, isDisplayedActivityModal)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Call)