// Connect
import { connect } from "react-redux";

// Composant
import ActivityModal from "../components/ActivityModal";

// Actions
import {
  patchActivity,
  saveIsDisplayedActivityModal,
  closeActivity,
  cancelActivity,
} from "../redux/activities/actionActivities";
import { saveSnackbar } from '../redux/snackbar/actionSnackbar';

const mapStateToProps = (state, ownProps) => ({
  isDisplayedActivityModal: state.activities.isDisplayedActivityModal?.[ownProps.callId] ?? false,
})

const mapDispatchToProps = (dispatch) => ({
  saveIsDisplayedActivityModal: (callId, isDisplayedActivityModal) => dispatch(saveIsDisplayedActivityModal(callId, isDisplayedActivityModal)),
  patchActivity: (activity) => dispatch(patchActivity(activity)),
  saveSnackbar: (message, variant) => dispatch(saveSnackbar(message, variant)),
  closeActivity: (activity) => dispatch(closeActivity(activity)),
  cancelActivity: (activity) => dispatch(cancelActivity(activity)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityModal)