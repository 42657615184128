import { useEffect, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
// components 
import ClientBody from './ClientBody';
import Loader from "./Loader";
// MUI
import {
  Box,
  Popover,
  Typography,
} from "@mui/material";

// Icons
import DateRangeIcon from '@mui/icons-material/DateRange';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

// Styles
import {
  StyledTypo,
  StyledLogOutButton,
  StyledPaperWithoutHover,
} from "../styles/ClientsStyle";

const Client = ({
  fullClients,
  fetchFullClient,
  isFetchingFullClient,
  fetchingFullClientError,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // Gestion popover ( dates importantes )
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const routeParams = useParams();
  
  const getSelectedClient = useCallback(() => (
    fullClients.filter((client) => client.id === parseInt(routeParams.clientId))[0]
  ), [fullClients, routeParams.clientId])

  // Function Format dates
  const formatDateTime = useCallback((dateTime) => {
    const [date, time] = dateTime.split('T');
    const [year, month, day] = date.split('-');
    const newDate = day + '/' + month + '/' + year;
    const newTime = time.substring(0, time.lastIndexOf(":"));

    return [newDate, newTime]
  }, [])

  useEffect(() => {
    const selectedClient = getSelectedClient();

    if (!selectedClient && !fetchingFullClientError) {
      fetchFullClient(routeParams.clientId)
    }
  }, [fetchFullClient, routeParams.clientId, getSelectedClient, fetchingFullClientError])

  const selectedClient = getSelectedClient();

  return (
    <>
      {
        isFetchingFullClient
          ? (
            <Loader size={200} color="secondary" />
          )
          : (          
            selectedClient && (
              <>
                <Typography variant='h1' component='h1' color="primary" sx={{ textAlign: 'center'}}>{selectedClient.denomination}</Typography>
                <StyledPaperWithoutHover>
                  {/* Calandrier */}
                  <StyledLogOutButton
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    <DateRangeIcon />
                  </StyledLogOutButton>
                  <ClientBody client={selectedClient} />
                  {/* contenu pour les dates du Calandrier */}
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Box textAlign='center'>
                      <StyledTypo variant="h3" color={'primary.light'}>Dates importantes</StyledTypo>
                    </Box>
                    <Box sx={{ flexGrow: 1, margin: '10px' }}>
                      {/* Date de création */}
                      {
                        selectedClient.dateCreation ?
                          <Box display='flex' alignItems='center' textAlign='center' color={'secondary.light'} sx={{ padding: '5px', paddingTop: '10px' }}>
                            <CreateNewFolderIcon />
                            <StyledTypo variant="body1" color={'primary'}>Création :</StyledTypo>
                            <StyledTypo variant="body1" color={'secondary'}>{formatDateTime(selectedClient.dateCreation)[0]} à {formatDateTime(selectedClient.dateCreation)[1]}</StyledTypo>
                          </Box>
                          : null
                      }
                      {/* Date dernier accès */}
                      {
                        selectedClient.dateDernierAcces ?
                          <Box display='flex' alignItems='center' textAlign='center' color={'secondary.light'} sx={{ padding: '5px', paddingTop: '10px' }}>
                            <DriveFolderUploadIcon />
                            <StyledTypo variant="body1" color={'primary'}>Dernier accès :</StyledTypo>
                            <StyledTypo variant="body1" color={'secondary'}>{formatDateTime(selectedClient.dateDernierAcces)[0]} à {formatDateTime(selectedClient.dateDernierAcces)[1]}</StyledTypo>
                          </Box>
                          : null
                      }
                      {/* Date de modification */}
                      {
                        selectedClient.dateModification ?
                          <Box display='flex' alignItems='center' textAlign='center' color={'secondary.light'} sx={{ padding: '5px', paddingTop: '10px' }}>
                            <ChangeCircleIcon />
                            <StyledTypo variant="body1" color={'primary'}>Modification :</StyledTypo>
                            <StyledTypo variant="body1" color={'secondary'}>{formatDateTime(selectedClient.dateModification)[0]} à {formatDateTime(selectedClient.dateModification)[1]}</StyledTypo>
                          </Box>
                          : null
                      }
                    </Box>
                  </Popover>
                </StyledPaperWithoutHover>
              </>
            )
          )
      }
    </>
  )
}

Client.propTypes = {
  // liste des clients detaillés
  fullClients: PropTypes.arrayOf(
    PropTypes.exact({
      actions: PropTypes.arrayOf(
        PropTypes.exact({
          nom: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
          method: PropTypes.string.isRequired,
          titre: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          schemaUrl: PropTypes.string.isRequired,
        }),
      ),
      adresse: PropTypes.exact({
        commune: PropTypes.exact({
          codeInsee: PropTypes.string,
          codePostal: PropTypes.string.isRequired,
          nom: PropTypes.string.isRequired,
        }),
        complementAdresse: PropTypes.string.isRequired,
        extensionNumero: PropTypes.exact({
          id: PropTypes.number.isRequired,
          intitule: PropTypes.string,
        }),
        numero: PropTypes.string.isRequired,
        pays: PropTypes.exact({
          id: PropTypes.number.isRequired,
          intitule: PropTypes.string.isRequired,
        }),
        typeVoie: PropTypes.exact({
          id: PropTypes.number.isRequired,
          intitule: PropTypes.string.isRequired,
        }),
        voie: PropTypes.string.isRequired,
      }),
      civilite: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      dateCreation: PropTypes.string.isRequired,
      dateDeces: PropTypes.string,
      dateDernierAcces: PropTypes.string,
      dateModification: PropTypes.string.isRequired,
      dateNaissance: PropTypes.exact({
        jour: PropTypes.number,
        mois: PropTypes.number,
        annee: PropTypes.number.isRequired,
      }),
      denomination: PropTypes.string.isRequired,
      email: PropTypes.string,
      etatMarital: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      formeSociete: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      id: PropTypes.number.isRequired,
      liens: PropTypes.arrayOf(
        PropTypes.exact({
          href: PropTypes.string.isRequired,
          relation: PropTypes.string.isRequired,
          verbe: PropTypes.string.isRequired,
        }),
      ),
      numero: PropTypes.string.isRequired,
      registreCommerce: PropTypes.string,
      siren: PropTypes.string,
      telephones: PropTypes.arrayOf(
        PropTypes.exact({
          typeTelephone: PropTypes.string.isRequired,
          numero: PropTypes.string.isRequired,
        }),
      ),
      typeClient: PropTypes.string.isRequired,
      dateRecuperation: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }),
    }).isRequired,
  ).isRequired,
  // recupere un client avec tous les details
  fetchFullClient: PropTypes.func.isRequired,
  // est en train de recupérer un client detaillés
  isFetchingFullClient: PropTypes.bool.isRequired,
}

export default Client;