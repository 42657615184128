export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const SAVE_CONTACTS = 'SAVE_CONTACTS';
export const REPLACE_CONTACTS = 'REPLACE_CONTACTS';
export const DELETE_CONTACTS = 'DELETE_CONTACTS';
export const SAVE_IS_FETCHING_CONTACTS = 'SAVE_IS_FETCHING_CONTACTS';
export const SAVE_FETCHING_CONTACTS_ERROR = 'SAVE_FETCHING_CONTACTS_ERROR';
export const FETCH_TAGS = 'FETCH_TAGS';
export const SAVE_TAGS = 'SAVE_TAGS';
export const SAVE_IS_FETCHING_TAGS = 'SAVE_IS_FETCHING_TAGS';

// Recuperation des contacts
export const fetchContacts = () => ({
  type: FETCH_CONTACTS,
})

// Sauvegarde des contacts dans le state
export const saveContacts = (data) => ({
  type: SAVE_CONTACTS,
  data
})

// remplace la liste des contacts par data
export const replaceContacts = (data) => ({
  type: REPLACE_CONTACTS,
  data
})

// Suppression des contactes
export const deleteContacts = () => ({
  type: DELETE_CONTACTS,
})

// si l'app est en train de recupérer les contactes
export const saveIsFetchingContacts = (isFetchingContacts) => ({
  type: SAVE_IS_FETCHING_CONTACTS,
  isFetchingContacts
})

// Traite une erreur lors de la récupération des contactes
export const saveIsFetchingContactsError = (isFetchingContactsError) => ({
  type: SAVE_FETCHING_CONTACTS_ERROR,
  isFetchingContactsError

})

// Recuperation des tags
export const fetchTags = () => ({
  type: FETCH_TAGS,
})

// Sauvegarde des tags dans le state
export const saveTags = (data) => ({
  type: SAVE_TAGS,
  data
})

export const saveIsFetchingTags = (isFetchingTags) => ({
  type: SAVE_IS_FETCHING_TAGS,
  isFetchingTags
})