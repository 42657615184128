import {
  SAVE_IS_CONNECTING_WEBSOCKET,
} from "./actionWebsocketWazo"

const initialStateTest = {
  websocketWazo: {},
  isConnectingWebsocket: false,
}

const reducerWebsocketWazo = (state = initialStateTest, action) => {

  switch (action.type) {
    case SAVE_IS_CONNECTING_WEBSOCKET:
      return {
        ...state,
        isConnectingWebsocket: action.isConnectingWebsocket,
      }


    default: return state
  }
}
export default reducerWebsocketWazo;