import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import PropTypes from 'prop-types'
// components
import ClientBody from './ClientBody';
import CallNumberMenu from './CallNumberMenu';
import Loader from "./Loader";
// containers
import HistoryCallModalSelectClientContainer from '../containers/HistoryCallModalSelectClientContainer';
// material
import {
  Box,
  Typography,
  Popover,
  IconButton,
} from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// const
import { PAGE_CALLS, PAGE_CALL_HISTORY, PAGE_CLIENT } from '../pages';

const HistoryCallClientDenomination = ({
  handleOnCloseModal,
  historyCallId,
  historyCallStatus,
  calls,
  callsByCollaboratorUuid,
  callsByClientId,
  fullClients,
  resetFolderId,
  users,
  fetchFullClient,
  isFetchingFullClient,
}) => {
  const [isEditingClient, setIsEditingClient] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setHovered] = useState(false);

  // CONST
  const [textDecoration, setTextDecoration] = useState('none');
  const [fontWeight, setFontWeight] = useState('100');
  const navigate = useNavigate();
  const location = useLocation()
  const routeParams = useParams();

  // recupère le call en fonction de la page et de l'historyId
  let call = [];

  if (PAGE_CALLS === location.pathname) {
    call = [...calls].filter(
      ({ historyId }) => historyCallId === historyId
    )[0]
  }
  if (PAGE_CALL_HISTORY === location.pathname) {
    call = [...callsByCollaboratorUuid].filter(
      ({ historyId }) => historyCallId === historyId
    )[0]
  }
  if (PAGE_CLIENT.replace(':clientId', routeParams.clientId) === location.pathname) {
    call = [...callsByClientId].filter(
      ({ historyId }) => historyCallId === historyId
    )[0]
  }

  const getSelectedClient = useCallback(() => {
    if (!fullClients) {
      return undefined;
    }
    return fullClients.filter((client) => client.id === parseInt(call.clientId))[0]
  }, [fullClients, call.clientId])

  useEffect(() => {
    if (call?.clientId) {
      const selectedClient = getSelectedClient();
      if (!selectedClient) {
        fetchFullClient(call.clientId)
      }
    }
  }, [call.clientId, fetchFullClient, getSelectedClient])

  const client = getSelectedClient();

  const handleOnClickClient = () => {
    if (PAGE_CLIENT === location.pathname) return
    navigate(`/clients/${client.id}`);
    handleOnCloseModal();
  }
  // Set fontWeight et underline
  useEffect(() => {
    if (isHovered) {
      if ('internal' !== call?.direction) {
        setTextDecoration('underline');
        setFontWeight('700');
      }
    } else {
      setTextDecoration('none');
      setFontWeight('100');
    }
  }, [isHovered, call?.direction]);

  // Gestion Popover
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setHovered(true);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHovered(false);
  };
  const open = Boolean(anchorEl);

  const user = users.filter(({ lines }) => lines.includes(call.callNumber))?.[0];
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '76px'
    }}>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          width: 'fit-content',
          marginX: 'auto',
          alignItems: 'center',
          minHeight: '40px',
        }}
      >
        {/* Nom du client */}
        {isEditingClient
          ? (
            <HistoryCallModalSelectClientContainer
              client={client}
              call={call}
              setIsEditingClient={setIsEditingClient}
              isEditingClient={isEditingClient}
              historyCallId={historyCallId}
              resetFolderId={resetFolderId}
            />
          )
          : (
            <>
              {
                isFetchingFullClient
                  ? (
                    <Loader top={'5px'} size={25} color="secondary" />
                  )
                  : (
                    client && 'internal' !== call.direction && (
                      <Typography
                        sx={{
                          color: 'primary.light',
                          cursor: PAGE_CLIENT === location.pathname ? 'cursor' : 'pointer',
                          maxWidth: ['250px', '400px', '400px', '100%'],
                          marginX: 'auto',
                          '&:hover': {
                            color: 'primary.main',
                          },
                          fontWeight: fontWeight,
                          textDecoration: textDecoration,
                        }}
                        id="transition-modal-title"
                        variant="h5"
                        component="h1"
                        onClick={handleOnClickClient}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        {client.denomination}
                      </Typography>
                    )
                  )
              }
              {
                'internal' === call.direction && (
                  <Typography
                    id="transition-modal-title"
                    variant="h5"
                    sx={{
                      color: 'primary.light',
                    }}
                  >
                    {user?.firstname ?? ''} {user?.lastname ?? ''}
                  </Typography>
                )
              }
              {
                !isFetchingFullClient && !client && 'internal' !== call.direction && (
                  <Typography
                    id="transition-modal-title"
                    variant="h5"
                    sx={{
                      color: 'primary.light',
                    }}
                  >
                    <CallNumberMenu callNumber={call.callNumber} />
                  </Typography>
                )

              }
            </>
          )
        }
        {
          "internal" !== call.direction && (
            <Box
              sx={{
                cursor: `${1 === historyCallStatus ? 'not-allowed' : 'inherit'}`,
                position: 'absolute',
                right: '-30px',
              }}
              title={`${1 === historyCallStatus ? 'ticket clôturé' : "Changer d'interlocuteur"}`}
            >
              <IconButton
                color="secondary"
                size="large"
                aria-label="change user icon"
                component="div"
                sx={{
                  p: 0,
                  pb: 0.3,
                }}
                disabled={1 === historyCallStatus}
                onClick={() => setIsEditingClient(!isEditingClient)}
              >
                <ManageAccountsIcon />
              </IconButton>
            </Box>
          )
        }
      </Box>
      {/* Numéro du client */}
      {(client || 'internal' === call.direction) &&
        <>
          <Typography
            id="transition-modal-title"
            variant="h6"
            sx={{
              textAlign: 'center',
              color: 'secondary.main',
              mb: 4,
              lineHeight: 1,
            }}
          >
            <CallNumberMenu callNumber={call.callNumber} />
          </Typography>
          {
            client && (
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Box
                  sx={{
                    padding: 3,
                    boxShadow: 4,
                    width: ['300px', '350px', '450px', '800px'],
                    border: 'solid 2px',
                    borderColor: 'secondary.main',
                  }}
                >
                  <ClientBody client={client} />
                </Box>
              </Popover>
            )
          }
        </>
      }
    </Box>
  )
}

HistoryCallClientDenomination.propTypes = {
  // permet de refermer la modal si l'utilisteur est redirigé vers le client
  handleOnCloseModal: PropTypes.func.isRequired,
  historyCallId: PropTypes.string.isRequired,
  // liste des clients detaillés
  fullClients: PropTypes.arrayOf(
    PropTypes.exact({
      adresse: PropTypes.exact({
        commune: PropTypes.exact({
          codeInsee: PropTypes.string,
          codePostal: PropTypes.string.isRequired,
          nom: PropTypes.string.isRequired,
        }),
        complementAdresse: PropTypes.string.isRequired,
        extensionNumero: PropTypes.exact({
          id: PropTypes.number.isRequired,
          intitule: PropTypes.string,
        }),
        numero: PropTypes.string.isRequired,
        pays: PropTypes.exact({
          id: PropTypes.number.isRequired,
          intitule: PropTypes.string.isRequired,
        }),
        typeVoie: PropTypes.exact({
          id: PropTypes.number.isRequired,
          intitule: PropTypes.string.isRequired,
        }),
        voie: PropTypes.string.isRequired,
      }),
      civilite: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      dateCreation: PropTypes.string.isRequired,
      dateDeces: PropTypes.string,
      dateDernierAcces: PropTypes.string,
      dateModification: PropTypes.string.isRequired,
      dateNaissance: PropTypes.exact({
        jour: PropTypes.number,
        mois: PropTypes.number,
        annee: PropTypes.number.isRequired,
      }),
      denomination: PropTypes.string.isRequired,
      email: PropTypes.string,
      etatMarital: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      formeSociete: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      id: PropTypes.number.isRequired,
      numero: PropTypes.string.isRequired,
      registreCommerce: PropTypes.string,
      siren: PropTypes.string,
      telephones: PropTypes.arrayOf(
        PropTypes.exact({
          typeTelephone: PropTypes.string.isRequired,
          numero: PropTypes.string.isRequired,
        }),
      ),
      typeClient: PropTypes.string.isRequired,
      dateRecuperation: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }),
    }).isRequired,
  ).isRequired,
  calls: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // Identifiant du client Genapi correspondant a cet appel
      clientId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  callsByClientId: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // Identifiant du client Genapi correspondant a cet appel
      clientId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  callsByCollaboratorUuid: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // Identifiant du client Genapi correspondant a cet appel
      clientId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  // permet de repasser historyCall.folderId à sa valeur par defaut (0)
  resetFolderId: PropTypes.func.isRequired,
  // liste des utilisateurs wazo
  users: PropTypes.arrayOf(
    PropTypes.exact({
      uuid: PropTypes.string.isRequired,
      // email de l'utilisateur
      email: PropTypes.string,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      // numéros de ligne appartenant à l'utilisateur
      lines: PropTypes.arrayOf(
        PropTypes.string.isRequired,
      ).isRequired,
      // si la licence CTI est activé
      isActive: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  // recupere un client avec tous les details
  fetchFullClient: PropTypes.func.isRequired,
  // est en train de recupérer un client detaillés
  isFetchingFullClient: PropTypes.bool.isRequired,
}

export default HistoryCallClientDenomination
