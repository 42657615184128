import React, { useState } from 'react'
import PropTypes from 'prop-types'

// MUI
import {
  Box,
  Typography,
  Menu,
} from '@mui/material';
// MUI ICONS
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import {ClickAwayListener} from '@mui/base/ClickAwayListener';
// Styled components
import {
  StyledMenuItem,
} from "../styles/LandingStyle"

const CallNumberMenu = ({ callNumber }) => {
  const [contextMenu, setContextMenu] = useState(null);
  const [isHovered, setHovered] = useState(false);

  // CONST
  const textDecoration = isHovered ? 'underline' : 'none';
  const fontWeight = isHovered ? 'bold' : 'none';

  // Gestion onHover
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null,
    );
    
  };

  const handleClose = (event) => {
    setContextMenu(null);
  };

  return (
    <>
      <Box
        className='notTriggerOpenActivity'
        onClick={handleContextMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        component={'span'}
        sx={{
          textDecoration: textDecoration,
          cursor: 'pointer',
          fontWeight: fontWeight,
        }}
      >
        {callNumber}
      </Box>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        className='notTriggerOpenActivity'
        PopoverClasses={{ root: 'notTriggerOpenActivity' }}
        hideBackdrop={true}
        MenuListProps={{ sx: { py: 0 } }}
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >

        <StyledMenuItem className='notTriggerOpenActivity'>
          <ClickAwayListener
            onClickAway={handleClose}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', py: 1 }} >
              <LocalPhoneIcon className='notTriggerOpenActivity' sx={{ fontSize: 21 }} color='secondary' />
              <Typography
                className='notTriggerOpenActivity'
                variant="body1"
                component='a'
                href={`tel:${callNumber}`}
                onClick={handleClose}
                sx={{
                  paddingInline: 2,
                  color: 'inherit',
                  textDecoration: 'inherit',
                }}
              >
                {`Appeler le ${callNumber}`}
              </Typography>
            </Box>
          </ClickAwayListener>
        </StyledMenuItem>
      </Menu>
    </>
  )
}

CallNumberMenu.propTypes = {
  // numero de telephone a contacter
  callNumber: PropTypes.string.isRequired,
}

export default CallNumberMenu