// actions
import {
  FETCH_CALLS,
  FETCH_MORE_CALLS,
  // saveSearchedCallsByCollaboratorUuid,
  SEARCH_CALLS,
  POST_CALL,
  saveCalls,
  replaceCalls,
  saveIsFetchingCalls,
  POST_HISTORY_CALL,
  // saveHistoryCalls,
  FETCH_CALLS_BY_COLLABORATOR_UUID,
  POST_HISTORY_CALL_MESSAGE,
  REMOVE_HISTORY_CALL_MESSAGE,
  // saveCallsByCollaboratorUuidLastVisible,
  FETCH_CALLS_BY_CLIENT_ID,
  FETCH_MORE_CALLS_BY_CLIENT_ID,
  saveIsFetchingMoreCalls,
  // saveCallsByClientId,
  // replaceCallsByClientId,
  // replaceSearchedCallsByCollaboratorUuid,
  // savesearchedCallsByUuidLastVisible,
} from "./actionCalls";

import { testCalls } from "../../testCalls";
// Affichage / Gestion des erreurs
import { saveSnackbar } from '../snackbar/actionSnackbar';

import { deleteUselessObjectKeysFromApi } from "../../utils";

// consts
// import { MORE_CALL } from "./reducerCalls";
// import { PAGE_CALL_HISTORY } from "../../pages";

const middlewareCalls = (store) => (next) => (action) => {
  const handleErrors = (tryName, error) => {
    const currMessage = error.message ?? 'inconnu';
    const currStatus = error.status ?? error.code ?? 'inconnu';

    console.log(`Oups ! Une erreur inattendue s'est produite : ${tryName} ~ ${currMessage} ~ ${currStatus}`, 'error');
    store.dispatch(saveSnackbar(`Oups ! Une erreur inattendue s'est produite : ${currMessage} ~ ${currStatus}`, 'error'));
  }

  switch (action.type) {
    case POST_CALL:
      const uploadCall = async () => {

      }

      uploadCall();

      next(action)
      break

    case FETCH_CALLS:
      const getCalls = async () => {

        let params = {
          page: 0,
          start: 0,
          length: 50,
        }
        let allCalls = [];
        do {
          try {
            params.page += 1;
            // Options for the fetch request
            const options = {
              method: 'GET',
              headers: {
                'accept': 'application/ld+json',
                'Content-Type': 'application/ld+json', // Specify the content type as JSON
                'Authorization': 'Bearer ' + store.getState().login.authToken,
              },
            };

            const rawCalls = await fetch(`https://localhost/calls?page=${params.page}`, options);
            const callsItems = await rawCalls.json();
            let calls = callsItems['hydra:member'];
            params.start += calls.length;
            params.length = callsItems['hydra:totalItems'];
            calls.map((call) => {
              call = deleteUselessObjectKeysFromApi(call);
              return call
            });
            allCalls = allCalls.concat(calls);
            // store.dispatch(replaceCalls(calls));
          } catch (error) {
            console.log("FETCH_CALLS ~ error:", error.code, '---', error.name, '---', error)

            handleErrors('FETCH_CALLS', error);
          } finally {
            store.dispatch(saveIsFetchingCalls(false))
          }
        } while (params.start < params.length)
        store.dispatch(replaceCalls(allCalls))
      }

      store.dispatch(saveIsFetchingCalls(true))
      getCalls();

      next(action)
      break

    case FETCH_MORE_CALLS:
      const getMoreCalls = async () => {
        try {
          const callsList = testCalls;

          store.dispatch(saveCalls(callsList));

        } catch (error) {
          handleErrors('FETCH_MORE_CALLS', error);
        } finally {
          store.dispatch(saveIsFetchingMoreCalls(false))
        }
      }

      store.dispatch(saveIsFetchingMoreCalls(true))
      getMoreCalls();

      next(action)
      break

    case SEARCH_CALLS:
      // const currentFilters = { ...store.getState().calls.filters };
      // const filters = { ...currentFilters[window.location.pathname] }

      const getSearchedCalls = async () => {

      }

      store.dispatch(saveIsFetchingCalls(true))
      getSearchedCalls();

      next(action)
      break


    // est appelé au moment de la mise à jour ou la fermeture d'un historique
    // est appelé au moment ou un appel se temrine dans le cas ou l'utilisateur a saisie l'historique
    case POST_HISTORY_CALL:
      const uploadHistoryCall = async () => {
      }

      uploadHistoryCall();

      next(action)
      break

    case FETCH_CALLS_BY_COLLABORATOR_UUID:
      // recupère la liste des historique des l'utilisateur connectés
      const getHistoryCallsByCollaboratorUiid = async () => {

      }

      getHistoryCallsByCollaboratorUiid()

      next(action)
      break

    case POST_HISTORY_CALL_MESSAGE:
      const uploadHistoryCallsMessage = async () => {

      }

      uploadHistoryCallsMessage();

      next(action)
      break

    case REMOVE_HISTORY_CALL_MESSAGE:
      const removeHistoryCallsMessage = async () => {

      }

      removeHistoryCallsMessage();

      next(action)
      break

    case FETCH_CALLS_BY_CLIENT_ID:
      // Récupère la liste des appels à partir d'un client séléctionné 
      const getCallsByClientId = async () => {

      }
      getCallsByClientId();

      next(action);
      break;

    case FETCH_MORE_CALLS_BY_CLIENT_ID:
      const getMoreCallsByClientId = async () => {

      }

      store.dispatch(saveIsFetchingMoreCalls(true))
      getMoreCallsByClientId();

      next(action)
      break

    default: next(action)
  }
}

export default middlewareCalls;