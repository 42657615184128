import { connect } from "react-redux";
import Tabs from "../components/Tabs";
// actions
import { saveTabIndex } from "../redux/tabs/actionTabs";

const mapStateToProps = (state) => ({
  tabsIndex: state.tabs.tabIndex,
})

const mapDispatchToProps = (dispatch) => ({
  saveTabIndex: (currentPageName, tabIndex) => dispatch(saveTabIndex(currentPageName, tabIndex)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tabs)