// React Redux
import { connect } from "react-redux";
// Component
import Calls from "../components/Calls";
// Actions
import { fetchActivities } from "../redux/activities/actionActivities";
import { fetchTags } from "../redux/contacts/actionContacts";

const mapStateToProps = (state) => ({
    activities: state.activities.activities,
    isFetchingActivities: state.activities.isFetchingActivities,
    isFetchingTags: state.contacts.isFetchingTags,
    isFetchingToken: state.login.isFetchingToken,
})

const mapDispatchToProps = (dispatch) => ({
    fetchActivities: () => dispatch(fetchActivities()),
    fetchTags: () => dispatch(fetchTags()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calls)