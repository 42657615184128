// React
import React, { useState, useEffect } from 'react'

// React router
import { useLocation } from 'react-router-dom';

// PropTypes
import PropTypes from 'prop-types'

// Mui
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  IconButton,
} from '@mui/material';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import UndoIcon from '@mui/icons-material/Undo';
import DoneIcon from '@mui/icons-material/Done';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

// Date picker
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-gb';

// Time picker
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


// const
import { PAGE_CALL_HISTORY } from '../pages';

const CallsSearchDialog = ({
  isDisplayedCallsSearchDialog,
  saveIsDisplayedCallsSearchDialog,
  saveCallFilters,
  fetchCalls,
  searchCalls,
  defaultFilters,
  clients,
  deleteFilter,
  deleteCalls,
  users,
}) => {
  const location = useLocation();

  const [clientFilter, setClientFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState('');
  const [collaboratorFilter, setCollaboratorFilter] = useState('');

  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');

  const [startDurationFilter, setStartDurationFilter] = useState('');
  const [endDurationFilter, setEndDurationFilter] = useState('');

  const [collaboratorDisabled, setCollaboratorDisabled] = useState(false);
  const [clientNumberDisabled, setClientNumberDisabled] = useState(false);

  const filters = {
    clientFilter: clientFilter,
    phoneFilter: phoneFilter,
    collaboratorFilter: collaboratorFilter,
    startDateFilter: startDateFilter,
    endDateFilter: endDateFilter,
    startDurationFilter: startDurationFilter,
    endDurationFilter: endDurationFilter,
    moreCalls: 1,
  }

  // Désactiver les filtres en fonction de ceux séléctionné
  useEffect(() => {
    if (clientFilter !== '' || phoneFilter !== '') {
      setClientNumberDisabled(true)
    } else {
      setClientNumberDisabled(false)
    }

    if (collaboratorFilter !== '') {
      setCollaboratorDisabled(true)
    } else {
      setCollaboratorDisabled(false)
    }
  }, [clientFilter, phoneFilter, collaboratorFilter])

  const currentClients = [...clients]

  const options = currentClients.map((option) => {
    const firstLetter = option.denomination[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter)
        ? ' Retirer' // espace au début rajouté volontairement afin que retirer soit en 1er dans la liste
        : firstLetter,
      ...option,
    };
  });

  const userOptions = users.map((userOption) => {
    const firstLetter = userOption.firstname[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter)
        ? ' Retirer' // espace au début rajouté volontairement afin que retirer soit en 1er dans la liste
        : firstLetter,
      ...userOption,
    };
  });

  const resetFilters = () => {
    setClientFilter('');
    setPhoneFilter('');
    setCollaboratorFilter('');
    setStartDateFilter('');
    setEndDateFilter('');
    setStartDurationFilter('');
    setEndDurationFilter('');
  }

  return (
    <>
      <Box sx={{ display: 'flex', textAlign: 'right', width: '100%', justifyContent: ["center", "center", "right"], marginBottom: '1rem' }}>
        <Box sx={{ position: 'relative' }}>
          <IconButton
            color="secondary"
            size="small"
            aria-label="reset filter"
            component="div"
            title="réinitialisation des filtres"
            sx={{ position: 'absolute', left: '-35px' }}
            onClick={() => {
              resetFilters();
              deleteFilter(location.pathname);
              deleteCalls();
              if (location.pathname === PAGE_CALL_HISTORY) {
                fetchCalls(defaultFilters.collaboratorUuid, 1);
                fetchCalls(defaultFilters.collaboratorUuid, 0);
              } else {
                fetchCalls();
              }

            }}
          >
            <RestartAltIcon color={'secondary'} sx={{ marginBlock: 'auto', fontSize: ['24px', null, '30px'] }} />
          </IconButton>

          <Button variant="contained" color={'secondary'} sx={{ marginInline: '0.5rem', paddingInline: '5px' }} onClick={() => saveIsDisplayedCallsSearchDialog(true)}>Recherche avancée <SearchIcon sx={{ paddingLeft: '5px' }} /></Button>
        </Box>
      </Box>
      <Dialog open={isDisplayedCallsSearchDialog} onClose={() => saveIsDisplayedCallsSearchDialog(false)}>
        {/* <DialogTitle>Subscribe</DialogTitle> */}
        <Typography variant="h1" color='primary' sx={{ padding: '16px 24px !important' }} >Recherche avancée</Typography>
        <DialogContent sx={{ paddingTop: '0px' }}>
          <DialogContentText>
            Vous pouvez effectuer des recherches précises en utilisant différents critères tels que la date,
            l'heure, la durée de l'appel, le numéro de téléphone ou le client associé à l'appel.
          </DialogContentText>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h2" color='primary.light' sx={{ paddingInline: '0px !important', paddingTop: '20px' }}>Filtre par Client</Typography>
                <Box sx={{ paddingInline: '15px' }}>
                  <Autocomplete
                    disabled={collaboratorDisabled}
                    disablePortal
                    id="combo-box-demo"
                    onChange={(e, option) => setClientFilter(option?.id || '')}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    sx={{ width: '90%' }}
                    autoSelect
                    getOptionLabel={(option) => option.denomination}
                    groupBy={(option) => option.firstLetter}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.denomination}
                        </li>
                      );
                    }}
                    renderInput={(params, option) => (
                      <TextField
                        {...params}
                        variant='standard'
                        autoFocus
                        label="Client"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h2" color='primary.light' sx={{ paddingInline: '0px !important', paddingTop: '20px' }}>Filtre par Numéro</Typography>
                <Box sx={{ paddingInline: '15px' }}>
                  <TextField
                    disabled={collaboratorDisabled}
                    id="standard-search"
                    label="Numéro"
                    type="search"
                    variant="standard"
                    sx={{ width: '90%' }}
                    onChange={(e) => setPhoneFilter(e.currentTarget.value)}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Typography variant="h2" color='primary.light' sx={{ paddingInline: '0px !important', paddingTop: '20px' }}>Filtre par Collaborateur</Typography>
          <Grid sx={{ paddingInline: '15px', width: '50%' }}>
            <Autocomplete
              disabled={clientNumberDisabled}
              disablePortal
              id="combo-box-demo"
              onChange={(e, option) => setCollaboratorFilter(option?.lines || '')}
              isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
              options={userOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
              sx={{ width: '89%' }}
              autoSelect
              getOptionLabel={(option) => `${option?.firstname}${option?.lastname !== null ? ' ' + option.lastname : ''}`}
              groupBy={(option) => option.firstLetter}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.uuid}>
                    {option.firstname} {option.lastname}
                  </li>
                );
              }}
              renderInput={(params, option) => (
                <TextField
                  {...params}
                  variant='standard'
                  autoFocus
                  label="Collaborateur"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Typography variant="h2" color='primary.light' sx={{ paddingInline: '0px !important', paddingTop: '20px' }}>Filtre par Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
            <DemoContainer components={['DatePicker']}>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <DatePicker
                    label="Début"
                    onChange={(e) => setStartDateFilter(e)}
                  />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <DatePicker
                    label="Fin"
                    onChange={(e) => setEndDateFilter(e)}
                  />
                </Grid>
              </Grid>
            </DemoContainer>
            <Typography variant="h2" color='primary.light' sx={{ paddingInline: '0px !important', paddingTop: '20px' }}>Filtre par Durée</Typography>
            <DemoContainer components={['TimePicker']}>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <TimePicker
                    ampm={false}
                    label="Supérieur à ( hh:mm:ss ) "
                    views={['hours', 'minutes', 'seconds']}
                    format="HH:mm:ss"
                    onChange={(e) => setStartDurationFilter(e)}
                  />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <TimePicker
                    ampm={false}
                    label="Inférieur à ( hh:mm:ss ) "
                    views={['hours', 'minutes', 'seconds']}
                    format="HH:mm:ss"
                    onChange={(e) => setEndDurationFilter(e)}
                  />
                </Grid>
              </Grid>
            </DemoContainer>
          </LocalizationProvider>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" startIcon={<UndoIcon />} onClick={() => saveIsDisplayedCallsSearchDialog(false)}>Annuler</Button>
          <Button variant="contained" color="success" startIcon={<DoneIcon />} onClick={() => {
            saveCallFilters({}, {}, location.pathname);
            saveCallFilters(defaultFilters, filters, location.pathname);
            searchCalls();
            saveIsDisplayedCallsSearchDialog(false);
            resetFilters();
          }}>Valider</Button>
        </DialogActions>
      </Dialog>
    </>

  )
}

CallsSearchDialog.propTypes = {
  // Indique si le dialog est affiché
  isDisplayedCallsSearchDialog: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  // affiche/cache le dialog pour les clients liés à l'appels
  saveIsDisplayedCallsSearchDialog: PropTypes.func.isRequired,
  saveCallFilters: PropTypes.func.isRequired,
  fetchCalls: PropTypes.func.isRequired,
  defaultFilters: PropTypes.object.isRequired,
}
export default CallsSearchDialog;