import { connect } from "react-redux";

// Component
import HistoryCallModalSelectClient from "../components/HistoryCallModalSelectClient";

// actions
import {
  postCall,
  updateCallByCollaboratorUuid,
  updateCallByClientId,
  saveIsDisplayedHistoryCallModal,
} from "../redux/calls/actionCalls";
import { saveSnackbar } from '../redux/snackbar/actionSnackbar';
import {
  saveTabIndex,
} from "../redux/tabs/actionTabs";

const mapStateToProps = (state) => ({
  clients: state.clients.clients,
  historyCalls: state.calls.historyCalls,
})

const mapDispatchToProps = (dispatch) => ({
  postCall: (call) => dispatch(postCall(call)),
  updateCallByCollaboratorUuid: (callByCollaboratorUuid) => dispatch(updateCallByCollaboratorUuid(callByCollaboratorUuid)),
  updateCallByClientId: (callByClientId) => dispatch(updateCallByClientId(callByClientId)),
  saveIsDisplayedHistoryCallModal: (callId, isDisplayedHistoryCallModal) => dispatch(saveIsDisplayedHistoryCallModal(callId, isDisplayedHistoryCallModal)),
  saveSnackbar: (message, variant) => dispatch(saveSnackbar(message, variant)),
  saveTabIndex: (currentPageName, tabIndex) => dispatch(saveTabIndex(currentPageName, tabIndex)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCallModalSelectClient)