import { connect } from "react-redux";
import CallInProgress from "../components/CallInProgress";

// Actions
// import { fetchClients } from "../redux/clients/actionClients";

const mapStateToProps = (state) => ({
    calls: state.calls.calls,
})

// const mapDispatchToProps = (dispatch) => ({

// })

export default connect(mapStateToProps)(CallInProgress)
