// Actions
import {
  FETCH_ACTIVITIES,
  saveActivities,
  saveIsFetchingActivities,
  PATCH_ACTIVITY,
  updateActivity,
  CLOSE_ACTIVITY,
  CANCEL_ACTIVITY,
  deleteActivity,
} from "./actionActivities";
import { saveAuthToken } from "../login/actionLogin";
// Affichage / Gestion des erreurs
import { saveSnackbar } from '../snackbar/actionSnackbar';

// Utils
import { deleteUselessObjectKeysFromApi, getPreviousWorkingDate } from "../../utils";

// Fetch + gestion token 
import { customFetchApi } from "../../customFetchApi";

const middlewareActivities = (store) => (next) => (action) => {
  const handleErrors = (tryName, error) => {
    const currMessage = error.message ?? 'inconnu';
    const currStatus = error.status ?? error.code ?? 'inconnu';

    console.log(`Oups ! Une erreur inattendue s'est produite : ${tryName} ~ ${currMessage} ~ ${currStatus}`, 'error');
    store.dispatch(saveSnackbar(`Oups ! Une erreur inattendue s'est produite : ${currMessage} ~ ${currStatus}`, 'error'));
  }

  switch (action.type) {
    case FETCH_ACTIVITIES:
      const getActivities = async () => {
        console.log('FETCH_ACTIVITIES');

        const previousWorkingDate = getPreviousWorkingDate(new Date(), 2);
        const formatedPreviousDate = previousWorkingDate.toISOString();

        const customerUuid = store.getState().login.uuid;
        let token = store.getState().login.authToken;
        // Options for the fetch request
        const options = {
          method: 'GET',
          headers: {
            'accept': 'application/ld+json',
            'Content-Type': 'application/ld+json', // Specify the content type as JSON
            'Authorization': 'Bearer ' + token,
          },
        };

        let params = {
          page: 0,
          start: 0,
          length: 50,
        }
        let allActivities = [];
        do {
          try {
            params.page += 1;
            const rawActivities = await customFetchApi(store, saveAuthToken , `/activities?page=${params.page}&canceled=false&customer.wazoUserUuid=${customerUuid}&updatedAt[after]=${formatedPreviousDate}`, options, handleErrors);
            const activitiesJson = await rawActivities.json();

            let activities = activitiesJson['hydra:member'];

            params.start += activities.length;
            params.length = activitiesJson['hydra:totalItems'];

            const promises = activities.map(async activity => {
              let newContact = activity.contact;
              let newCall = activity.call;
              let newCustomer = activity.customer;
              try {
                const rawContact = await customFetchApi(store, saveAuthToken, newContact, options, handleErrors);
                newContact = await rawContact.json();

                const rawCall = await customFetchApi(store, saveAuthToken, newCall, options, handleErrors);
                newCall = await rawCall.json();

                const rawCustomer = await customFetchApi(store, saveAuthToken, newCustomer, options, handleErrors);
                newCustomer = await rawCustomer.json();

                // Suppression de @id, @Context et @id
                newContact = deleteUselessObjectKeysFromApi(newContact);
                newCall = deleteUselessObjectKeysFromApi(newCall);
                newCustomer = deleteUselessObjectKeysFromApi(newCustomer);

                // si le contact appartient à une société
                if (newContact?.contact) {
                  const rawCompanyContact = await customFetchApi(store, saveAuthToken, newContact?.contact, options, handleErrors);
                  const companyContact = await rawCompanyContact.json();
                  newContact = { ...newContact, companyName: companyContact.name, companyUrl: companyContact.url }
                }
              } catch (error) {
                console.log(`FETCH_DATA ~ error:`, error.code, '---', error.name, '---', error)
                handleErrors(`FETCH_DATA`, error);
              }
              return {
                id: activity.id,
                call: {...newCall, callNumber: newCall.callNumber.replace(/^\+33/, "0")},
                contact: { ...newContact, callNumber: newContact.callNumber.replace(/^\+33/, "0")},
                customer: newCustomer,
                closed: activity.closed,
                note: activity.note,
                priority: activity.priority,
                subject: activity.subject,
                updatedAt: activity.updatedAt,
              };
            });
            // Utilisation de Promise.all() pour attendre la résolution de toutes les promesses
            Promise.all(promises)
              // eslint-disable-next-line
              .then(objects => {
                allActivities = allActivities.concat(objects);
                if (allActivities.length === params.length) {
                  store.dispatch(saveActivities(allActivities));
                  store.dispatch(saveIsFetchingActivities(false));
                }
              })
              .catch(error => {
                console.error("Error:", error);
                store.dispatch(saveIsFetchingActivities(false));
              });
          } catch (error) {
            console.log("FETCH_ACTIVITIES ~ error:", error.code, '---', error.name, '---', error)
            handleErrors('FETCH_ACTIVITIES', error);
            store.dispatch(saveIsFetchingActivities(false));
            break;
          }
        } while (params.start < params.length)
      }
      store.dispatch(saveIsFetchingActivities(true));
      getActivities();

      next(action)
      break

    case PATCH_ACTIVITY:
      store.dispatch(saveIsFetchingActivities(true));
      const replaceActivity = async () => {
        console.log('PATCH_ACTIVITY', action.activity)
        try {

          let token = store.getState().login.authToken;
          // Options for the fetch request
          const options = {
            method: 'PATCH',
            headers: {
              'accept': 'application/ld+json',
              'Content-Type': 'application/merge-patch+json', // Specify the content type as JSON
              'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
              ...action.activity,
              contact: `/contacts/${action.activity.contact.id}`,
              customer: `/customers/${action.activity.customer.id}`,
              call: `/calls/${action.activity.call.id}`,
            }) // Convert data to JSON string
          };

          customFetchApi(store, saveAuthToken, `/activities/${action.activity.id}`, options, handleErrors);
          store.dispatch(updateActivity(action.activity))
        } catch (error) {
          console.log("PATCH_ACTIVITY ~ error:", error.code, '---', error.name, '---', error)
          handleErrors('PATCH_ACTIVITY', error);
        } finally {
          store.dispatch(saveIsFetchingActivities(false))
        }
      }

      replaceActivity();
      next(action)
      break

    case CLOSE_ACTIVITY:
      store.dispatch(saveIsFetchingActivities(true));
      const closingActivity = async () => {
        console.log('CLOSE_ACTIVITY', action.activity)
        try {
          let token = store.getState().login.authToken;

          // Options for the fetch request
          const options = {
            method: 'GET',
            headers: {
              'accept': 'application/ld+json',
              'Content-Type': 'application/merge-patch+json', // Specify the content type as JSON
              'Authorization': 'Bearer ' + token,
            },
          };
          customFetchApi(store, saveAuthToken, `/activities/${action.activity.id}/close`, options, handleErrors);
          store.dispatch(updateActivity(action.activity))
        } catch (error) {
          console.log("CLOSE_ACTIVITY ~ error:", error.code, '---', error.name, '---', error)

          handleErrors('CLOSE_ACTIVITY', error);
        } finally {
          store.dispatch(saveIsFetchingActivities(false))
        }
      }

      closingActivity();
      next(action)
      break

    case CANCEL_ACTIVITY:
      store.dispatch(saveIsFetchingActivities(true));

      (async () => {
        console.log('CANCEL_ACTIVITY', action.activity)
        try {
          let token = store.getState().login.authToken;

          // Options for the fetch request
          const options = {
            method: 'GET',
            headers: {
              'accept': 'application/ld+json',
              'Content-Type': 'application/merge-patch+json', // Specify the content type as JSON
              'Authorization': 'Bearer ' + token,
            },
          };
          await customFetchApi(store, saveAuthToken, `/activities/${action.activity.id}/cancel`, options, handleErrors);
          
          store.dispatch(deleteActivity(action.activity))
        } catch (error) {
          console.log("CANCEL_ACTIVITY ~ error:", error.code, '---', error.name, '---', error)

          handleErrors('CANCEL_ACTIVITY', error);
        } finally {
          store.dispatch(saveIsFetchingActivities(false))
        }
      })()

      next(action)
      break

    default: next(action)
  }
}
export default middlewareActivities;