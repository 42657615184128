// React
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// components
import CallNumberMenu from './CallNumberMenu';
import ClientBody from './ClientBody';
// MUI component
import Paper from '@mui/material/Paper';
import { Box, Typography, Popover } from '@mui/material';
import Link from '@mui/material/Link';

// MUI Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';

import BadgeIcon from '@mui/icons-material/Badge';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
// Styled component
import {
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  StyledLink,
  StyledTypoTableHead,
  StyledPhoneIcon,
  StyledEmailIcon,
  StyledContactPageIcon,
  StyledOpenInNewIcon,
  StyledTextField,
  StyledContainer,
} from '../styles/ClientsStyle';
// consts
const DEFAULT_ROWS_PER_PAGE = 20;

const Clients = ({ clients, saveTabIndex, saveFolderDisplayed, saveIsDisplayedClientFolderDialog }) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [visibleRows, setVisibleRows] = useState(null);
  const [filteredClients, setFilteredClients] = useState(null);
  const [phoneFilter, setPhoneFilter] = useState('');
  const [denominationFilter, setDenominationFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setHovered] = useState(false);
  const [hoveredClient, setHoveredClient] = useState({});

  // CONST
  const [textDecoration, setTextDecoration] = useState('none');
  const [fontWeight, setFontWeight] = useState('');
  // Initialisation du tableau 
  useEffect(() => {
    // trie les clients par ordre alphabetique
    clients.sort(
      (a, b) => {
        const clientNameA = a.denomination.trim();
        const clientNameB = b.denomination.trim();
        return clientNameA.localeCompare(clientNameB)
      }
    )

    let rowsOnMount = (filteredClients ?? clients).slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
    );

    setVisibleRows(rowsOnMount);
  }, [clients, filteredClients]);

  // Gestion du changement de page du tableau
  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);

      const updatedRows = (filteredClients ?? clients).slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage,
      );

      setVisibleRows(updatedRows);
    },
    [clients, rowsPerPage, filteredClients],
  );

  // Gestion du filtre client
  const handleFilter = e => {
    setFilteredClients(clients.filter(client => {
      let clientName = client.denomination.toLowerCase();
      let search = e.target.value.toLowerCase();
      setDenominationFilter(search);
      let data = clientName.includes(search);

      return data
    }));

    setPage(0);
  }

  // Gestion du filtre client par numero de telephone
  const handlePhoneFilter = e => {
    setFilteredClients(clients.filter(client => {
      let data = false
      let clientPhoneNumber = client.telephones;
      // eslint-disable-next-line 
      clientPhoneNumber.map(phoneNumber => {
        let formatedPhoneNumber = phoneNumber.numero.replace(/\./g, '');
        formatedPhoneNumber = formatedPhoneNumber.replace(/\s/g, '');
        let search = e.target.value;
        setPhoneFilter(search);
        if (formatedPhoneNumber.includes(search)) {
          data = true
        }
      })
      return data
    }));

    setPage(0);
  }

  // Gestion du filtre client par email
  const handleEmailFilter = e => {
    setFilteredClients(clients.filter(client => {
      let data = false
      let clientEmail = client.email;
      let search = e.target.value.toLowerCase();
      setEmailFilter(search);
      if(clientEmail && clientEmail.includes(search)){
        data = true
      }

      return data
    }));

    setPage(0);
  }

  // Gestion du changement du nombre de client affiché par page
  // eslint-disable-next-line
  const handleChangeRowsPerPage = useCallback(
    (event) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);

      setPage(0);

      const updatedRows = clients.slice(
        0 * updatedRowsPerPage,
        0 * updatedRowsPerPage + updatedRowsPerPage,
      );

      setVisibleRows(updatedRows);
    },
  )

  const handleOnClickClient = (clientId) => {
    saveFolderDisplayed({});
    saveIsDisplayedClientFolderDialog(false);
    saveTabIndex(`/clients/${clientId}`, 0);
    navigate(`/clients/${clientId}`);
  }

  // Set fontWeight et underline
  useEffect(() => {
    if (isHovered) {
      setTextDecoration('underline');
      setFontWeight('700');
    } else {
      setTextDecoration('');
      setFontWeight('');
    }
  }, [isHovered]);

  // Gestion Popover
  const handlePopoverOpen = (event, client) => {
    setAnchorEl(event.currentTarget);
    setHovered(true);
    setHoveredClient(client);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHovered(false);
    setHoveredClient({});
  };
  const open = Boolean(anchorEl);

  return (
    <div>
      <StyledContainer>
        {/* Filtre */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <StyledTextField
            disabled={(phoneFilter !== '' || emailFilter !== '' ? true : false)}
            id="outlined-basic"
            label={
              <label>
                <Box display="flex" alignItems="center">
                  <BadgeIcon sx={{ paddingRight: '5px' }} />
                  Recherche par dénomination...
                </Box>
              </label>
            }
            variant="outlined"
            onChange={handleFilter}
            sx={{ width: '33%' }}
            type="search"
          />
          <StyledTextField
            disabled={(emailFilter !== '' || denominationFilter !== '' ? true : false)}
            id="outlined-basic"
            label={
              <label>
                <Box display="flex" alignItems="center">
                  <LocalPhoneIcon sx={{ paddingRight: '5px' }} />
                  Recherche par numéro...
                </Box>
              </label>
            }
            variant="outlined"
            onChange={handlePhoneFilter}
            sx={{ width: '33%' }}
            type="search"
          />
          <StyledTextField
            disabled={(phoneFilter !== '' || denominationFilter !== '' ? true : false)}
            id="outlined-basic"
            label={
              <label>
                <Box display="flex" alignItems="center">
                  <EmailIcon sx={{ paddingRight: '5px' }} />
                  Recherche par email...
                </Box>
              </label>
            }
            variant="outlined"
            onChange={handleEmailFilter}
            sx={{ width: '33%' }}
            type="search"
          />
        </Box>
        <TableContainer component={Paper}>
          {/* Liste des clients */}
          <Table aria-label="customized table">
            {/* Titre du tableau */}
            <TableHead>
              <TableRow>
                <StyledTableCell width="40%">
                  <Box display='flex' alignItems='center'>
                    <StyledContactPageIcon />
                    <StyledTypoTableHead variant="h2">Dénomination</StyledTypoTableHead>
                  </Box>
                </StyledTableCell>
                <StyledTableCell width="25%" align="left">
                  <Box display='flex' alignItems='center'>
                    <StyledPhoneIcon />
                    <StyledTypoTableHead variant="h2">Numéro</StyledTypoTableHead>
                  </Box>
                </StyledTableCell>
                <StyledTableCell width="35%" sx={{ fontSize: 24 }} align="right">
                  <Box display='flex' alignItems='center'>
                    <StyledEmailIcon />
                    <StyledTypoTableHead variant="h2">Email</StyledTypoTableHead>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {/* Clients */}
            <TableBody>
              {visibleRows
                ? visibleRows.map((client) => (
                  <StyledTableRow key={client.id}>
                    <StyledTableCell component="th" scope="row">
                      <Link underline="none" onClick={() => handleOnClickClient(client.id)}>
                        <Box display='flex' alignItems='center' sx={{
                          'color': 'primary.light',
                          '&:hover': {
                            cursor: 'pointer',
                            'color': 'primary.main',
                          }
                        }}>
                          <StyledOpenInNewIcon sx={{ color: 'secondary.main' }} />
                          <StyledTypography
                            variant="body1"
                            alignContent='center'
                            onMouseEnter={(e) => handlePopoverOpen(e, client)}
                            onMouseLeave={handlePopoverClose}
                            sx={{
                              'color': 'primary.light',
                              '&:hover': {
                                cursor: 'pointer',
                                'color': 'primary.main',
                                fontWeight: fontWeight,
                                textDecoration: textDecoration,
                              },

                            }}
                          >
                            {client.denomination}
                          </StyledTypography>
                        </Box>
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {
                        client.telephones[0] ?
                          <Typography variant="body1"><CallNumberMenu callNumber={client.telephones[0].numero}></CallNumberMenu></Typography>
                          : ''
                      }
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {
                        client.email ?
                          <StyledLink href={`mailto: ${client.email}`}><Typography variant="body1">{client.email}</Typography></StyledLink>
                          : ''
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                ))
                : null}
            </TableBody>
            <TableFooter>
              {/* Pagination */}
              <TableRow>
                <TablePagination
                  sx={{ display: "inline-flex", overflow: 'inherit' }}
                  count={(filteredClients ?? clients).length}
                  onPageChange={handleChangePage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  labelRowsPerPage='Element par page :'
                  colSpan={3}
                  // pour changer "to" en "sur" => ex : "1-20 sur 100"
                  labelDisplayedRows={function defaultLabelDisplayedRows({ from, to, count }) { return `${from}–${to} sur ${count !== -1 ? count : `more than ${to}`}`; }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </StyledContainer>
      {
        hoveredClient && Object.keys(hoveredClient).length !== 0 && (
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box
              sx={{
                padding: 3,
                boxShadow: 4,
                width: ['300px', '350px', '450px', '800px'],
                border: 'solid 2px',
                borderColor: 'secondary.main',
              }}
            >
              <ClientBody client={hoveredClient} />
            </Box>
          </Popover>
        )
      }
    </div>
  )
}

Clients.propTypes = {
  // Liste des clients obtenue via l'api GENAPI
  clients: PropTypes.arrayOf(
    PropTypes.exact({
      denomination: PropTypes.string.isRequired,
      email: PropTypes.string,
      id: PropTypes.number.isRequired,
      telephones: PropTypes.arrayOf(
        PropTypes.exact({
          typeTelephone: PropTypes.string.isRequired,
          numero: PropTypes.string.isRequired,
        }),
      ),
      tenantId: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  // permet de changer d'onglet
  saveTabIndex: PropTypes.func.isRequired,
}

export default Clients;