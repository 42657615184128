import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
// components
import ClientFolderBody from "./ClientFolderBody";
import Loader from './Loader';

// MUI
import {
  Typography,
  Box,
  Popover,
  Divider,
} from "@mui/material";

// Icon
import DateRangeIcon from '@mui/icons-material/DateRange';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PageviewIcon from '@mui/icons-material/Pageview';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

// Styles
import {
  StyledTypo,
  StyledLogOutButton,
  StyledPaper
} from "../styles/ClientsStyle";

import ClientFolderDialogContainer from "../containers/ClientFolderDialogContainer";

const ClientFolders = ({
  folders,
  fetchFolders,
  isFetchingFolders,
  saveIsDisplayedClientFolderDialog,
  saveFolderDisplayed,
  fetchFiles,
  fetchBinders,
  saveSelectedBinder,
}) => {
  const [formatedFolders, setformatedFolders] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Gestion Popover
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // Récupération des paramètre de l'url (id client)
  const routeParams = useParams();

  // Function Format dates
  const formatDateTime = (dateTime) => {
    if (typeof dateTime === 'string' || dateTime instanceof String) {
      const [date, time] = dateTime.split('T');
      const [year, month, day] = date.split('-');
      const newDate = day + '/' + month + '/' + year;
      const newTime = time.substring(0, time.lastIndexOf(":"));
      return [newDate, newTime]
    }
    return dateTime
  }

  // Récupération des dossiers
  useEffect(() => {
    fetchFolders(parseInt(routeParams.clientId));
  }, [fetchFolders, routeParams.clientId])

  // Format dates
  useEffect(() => {
    folders.map((folder) => {
      // Création
      if (folder.dateCreation) {
        folder.dateCreation = formatDateTime(folder.dateCreation);
      }

      // Date dernier acces 
      if (folder.dateDernierAcces) {
        folder.dateDernierAcces = formatDateTime(folder.dateDernierAcces);
      }

      // Date modification
      if (folder.dateModification) {
        folder.dateModification = formatDateTime(folder.dateModification);
      }

      // Date ouverture dossier
      if (folder.dateOuvertureDossier) {
        folder.dateOuvertureDossier = formatDateTime(folder.dateOuvertureDossier);
      }

      // Date previsionnelle signature
      if (folder.datePrevisionnelleSignature) {
        folder.datePrevisionnelleSignature = formatDateTime(folder.datePrevisionnelleSignature);
      }

      return folder;
    })

    const currentFolders = folders.reduce(
      (acc, curr) => {
        const newData = acc[curr.nom] !== undefined
          ? [...acc[curr.nom], curr]
          : [curr]

        return {
          ...acc,
          [curr.nom]: newData,
        }
      },
      {},
    )

    setformatedFolders(currentFolders)
  }, [folders])

  return (
    <>
      {isFetchingFolders
        ? (
          <Loader size={200} color="secondary" />
        )
        : (
          <Box sx={{ textAlign: 'center', paddingBottom: ['1rem!important', 0] }}>
            <ClientFolderDialogContainer />
            {
              (formatedFolders && Object.keys(formatedFolders).length !== 0) ?
                Object.keys(formatedFolders).map((folderName) => (
                  <Box key={folderName}>
                    {/* Date des appels */}
                    <Typography variant="h1" color="secondary" sx={{ fontWeight: 'bold' }}>{folderName}</Typography>
                    {/* Liste des appels par jour */}
                    <Box sx={{ textAlign: 'left' }} >
                      {
                        formatedFolders[folderName].map((folder) => (
                          <StyledPaper key={folder.id} sx={{cursor: 'pointer'}} onClick={() => {
                            saveIsDisplayedClientFolderDialog(true);
                            saveFolderDisplayed(folder);
                            fetchFiles(folder.id);
                            fetchBinders(folder.id);
                            saveSelectedBinder('');
                          }
                          }>
                            {/* Bouton Popover dates importantes */}
                            <StyledLogOutButton
                              aria-owns={open ? 'mouse-over-popover' : undefined}
                              aria-haspopup="true"
                              onMouseEnter={handlePopoverOpen}
                              onMouseLeave={handlePopoverClose}
                            ><DateRangeIcon /></StyledLogOutButton>
                            {/* Titre (nom du sous dossier) */}
                            <Box textAlign='center'>
                              <Typography variant="h2" color={'primary'} sx={{ display: ' inline-block', width: ['220px', '100%'] }}>{folder.intitule}</Typography>
                            </Box>
                            <Divider variant="middle" sx={{ my: 2, mx: 0, color: 'secondary' }} />
                            {/* contient les information du dossiers */}
                            <ClientFolderBody folder={folder} />
                            {/* Popover dates importantes */}
                            <Popover
                              id="mouse-over-popover"
                              sx={{
                                pointerEvents: 'none',
                              }}
                              open={open}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                            >
                              <Box textAlign='center'>
                                <StyledTypo variant="h3" color={'primary.light'}>Dates importantes</StyledTypo>
                              </Box>
                              <Box sx={{ flexGrow: 1, margin: '10px' }}>
                                {/* date creation */}
                                {
                                  folder.dateCreation ?
                                    <Box display='flex' alignItems='center' textAlign='center' color={'secondary.light'} sx={{ padding: '5px', paddingTop: '10px' }}>
                                      <CreateNewFolderIcon />
                                      <StyledTypo variant="body1" color={'primary'}>Création</StyledTypo>
                                      <StyledTypo variant="body1" color={'secondary'}>{folder.dateCreation[0]}</StyledTypo>
                                    </Box>
                                    : null
                                }
                                {/* Date dernier accès */}
                                {
                                  folder.dateDernierAcces ?
                                    <Box display='flex' alignItems='center' textAlign='center' color={'secondary.light'} sx={{ padding: '5px', paddingTop: '10px' }}>
                                      <DriveFolderUploadIcon />
                                      <StyledTypo variant="body1" color={'primary'}>Dernier accès</StyledTypo>
                                      <StyledTypo variant="body1" color={'secondary'}>{folder.dateDernierAcces[0]} à {folder.dateDernierAcces[1]}</StyledTypo>
                                    </Box>
                                    : null
                                }
                                {/* Date modification */}
                                {
                                  folder.dateModification ?
                                    <Box display='flex' alignItems='center' textAlign='center' color={'secondary.light'} sx={{ padding: '5px', paddingTop: '10px' }}>
                                      <ChangeCircleIcon />
                                      <StyledTypo variant="body1" color={'primary'}>Modification</StyledTypo>
                                      <StyledTypo variant="body1" color={'secondary'}>{folder.dateModification[0]} à {folder.dateModification[1]}</StyledTypo>
                                    </Box>
                                    : null
                                }
                                {/* Date d'ouverture du dossier */}
                                {
                                  folder.dateOuvertureDossier ?
                                    <Box display='flex' alignItems='center' textAlign='center' color={'secondary.light'} sx={{ padding: '5px', paddingTop: '10px' }}>
                                      <PageviewIcon />
                                      <StyledTypo variant="body1" color={'primary'}>Ouverture du dossier</StyledTypo>
                                      <StyledTypo variant="body1" color={'secondary'}>{folder.dateOuvertureDossier[0]}</StyledTypo>
                                    </Box>
                                    : null
                                }
                                {/* date previsionnelle signature */}
                                {
                                  folder.datePrevisionnelleSignature ?
                                    <Box display='flex' alignItems='center' textAlign='center' color={'secondary.light'} sx={{ padding: '5px', paddingTop: '10px' }}>
                                      <HistoryEduIcon />
                                      <StyledTypo variant="body1" color={'primary'}>Signature prévisionnelle</StyledTypo>
                                      <StyledTypo variant="body1" color={'secondary'}>{folder.datePrevisionnelleSignature[0]}</StyledTypo>
                                    </Box>
                                    : null
                                }
                              </Box>
                            </Popover>
                          </StyledPaper>
                        ))
                      }
                    </Box>
                  </Box>
                ))
                :
                // Pas de dossier trouvé
                <Box textAlign='center'>
                  <StyledTypo variant="h3" color={'primary.light'}>Pas de dossier !</StyledTypo>
                </Box>
            }
          </Box>
        )
      }
    </>
  )
}

ClientFolders.propTypes = {
  // Dossiers
  folders: PropTypes.arrayOf(
    PropTypes.exact({
      clerc: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      clercSecondaire: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      clientId: PropTypes.number.isRequired,
      dateCreation: PropTypes.node.isRequired,
      dateDernierAcces: PropTypes.node,
      dateModification: PropTypes.node.isRequired,
      dateOuvertureDossier: PropTypes.node.isRequired,
      datePrevisionnelleSignature: PropTypes.node,
      id: PropTypes.number.isRequired,
      idDossierParent: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
      montantPrevisionnel: PropTypes.number,
      nature: PropTypes.string.isRequired,
      nom: PropTypes.string.isRequired,
      notaire: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      numero: PropTypes.string.isRequired,
      secretaire: PropTypes.exact({
        id: PropTypes.number.isRequired,
        identite: PropTypes.string.isRequired,
        initiales: PropTypes.string.isRequired,
      }),
      service: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
      typeNature: PropTypes.string.isRequired,
      valeurMetierEtat: PropTypes.exact({
        id: PropTypes.number.isRequired,
        intitule: PropTypes.string.isRequired,
      }),
    }).isRequired,
  ).isRequired,
  // si les dossier sont en cours de telechargement depuis l'API
  isFetchingFolders: PropTypes.bool.isRequired,
}

export default ClientFolders;