// PropTypes
import PropTypes from 'prop-types';

// MUI
import {
  Box,
  Typography,
  Link,
} from '@mui/material';

//Composant
import CallNumberMenu from './CallNumberMenu';

const ActivityContactDenomination = ({
  contact,
}) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '76px'
    }}>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          width: 'fit-content',
          marginX: 'auto',
          alignItems: 'center',
          minHeight: '40px',
        }}
      >
        {
          contact?.name ?
            <Typography
              sx={{
                maxWidth: ['250px', '400px', '400px', '100%'],
                marginX: 'auto',
              }}
              id="transition-modal-title"
              variant="h5"
              component="h1"
            >
              {/* {contact.name} */}
              <Link 
                sx={{ 
                  textDecoration: 'none',
                  color: 'primary.light',
                  '&:hover': {
                    color: 'primary.main',
                    textDecoration: 'underline',
                  },
                }}
                href={contact?.companyUrl ?? contact?.url}
                target='_blank'
              >
                {contact?.companyName ?? contact?.name}
              </Link>
            </Typography>
            :
            null
        }
      </Box>
      {
        contact?.callNumber ?
          <Typography
            id="transition-modal-title"
            variant="h6"
            sx={{
              textAlign: 'center',
              color: 'secondary.main',
              mb: 4,
              lineHeight: 1,
            }}
          >
            <CallNumberMenu callNumber={contact.callNumber} />
          </Typography>
          :
          null
      }

    </Box>
  )
}
ActivityContactDenomination.propTypes = {
  contact: PropTypes.exact({
    activities: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    callNumber: PropTypes.string.isRequired,
    mobileNumber: PropTypes.string.isRequired,
    contact: PropTypes.string,
    companyName: PropTypes.string,
    companyUrl: PropTypes.string,
    contacts: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    customer: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
  }).isRequired,
}

export default ActivityContactDenomination;