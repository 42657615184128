import { SAVE_TAB_INDEX, SAVE_CURRENT_TAB_INDEX } from "./actionTabs";
// consts
import { PAGE_DEFAULT } from "../../pages";
const initialStateTest = {
  tabIndex: { [PAGE_DEFAULT]: 0 },
  currentTabIndex: { [PAGE_DEFAULT]: 0 }, 
}

const reducerTabs = (state = initialStateTest, action) => {
  switch (action.type) {
    case SAVE_TAB_INDEX:
      return {
        ...state,
        tabIndex: { ...state.tabIndex, [action.pageName] : action.tabIndex },
        currentTabIndex: { [action.pageName] : action.tabIndex },
      }
    
    case SAVE_CURRENT_TAB_INDEX:
      return {
        ...state,
        currentTabIndex: { ...state.currentTabIndex, [action.pageName] : action.currentTabIndex },
      }
    
    default: return state
  }
}
export default reducerTabs;