import { connect } from "react-redux";
import MoreCallsButton from "../components/MoreCallsButton";
// actions

const mapStateToProps = (state) => ({
  isFetchingMoreCalls: state.calls.isFetchingMoreCalls,
})

// const mapDispatchToProps = (dispatch) => ({
// })

export default connect(mapStateToProps)(MoreCallsButton)