// React
import React, { useCallback, useEffect, useState } from 'react'

// PropTypes
import PropTypes from 'prop-types'

// MUI
import { Box, Typography } from '@mui/material';

// Container
import CallContainer from '../containers/CallContainer'

// Composants
import Loader from './Loader';

const Calls = ({
  activities,
  fetchActivities,
  isFetchingActivities,
  isClosed,
  isFetchingTags,
  fetchTags,
  isFetchingToken,
}) => {
  // Calls formatés
  const [formatedCalls, setFormatedCalls] = useState([]);

  // Chargement des activités
  useEffect(() => {
    if(!isFetchingToken){
      fetchActivities();
      fetchTags();
    }

    // eslint-disable-next-line
  }, [isFetchingToken])

  // Récupération des appels présents dans les activités
  useEffect(() => {
    if (activities.length !== 0) {
      const newCalls = [];
      activities.forEach(activity => {
        if (activity.closed === isClosed) {
          newCalls.push({ ...activity.call, updatedAt: activity.updatedAt })
        }
      });
      setFormatedCalls(newCalls);
    } else {
      setFormatedCalls([]);
    }
  }, [activities, isClosed])

  // Format date
  const formatDateToFr = (date) => date.toLocaleString('fr-Fr').split(' ')[0]

  // Sort et reduce des appels
  const formatCalls = useCallback(() => {
    if (0 === formatedCalls.length) {
      return [];
    }
    formatedCalls.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    });

    return formatedCalls.reduce(
      (acc, curr) => {
        const date = formatDateToFr(new Date(curr.updatedAt))
        const newData = acc[date] !== undefined
          ? [...acc[date], curr]
          : [curr]

        return {
          ...acc,
          [date]: newData,
        }
      },
      {},
    )
  }, [formatedCalls])

  return (
    <Box sx={{ textAlign: 'center' }}>
      <>
        {isFetchingActivities || isFetchingTags
          ? (
            <Loader top="15%" size={200} color="secondary" />
          )
          :
          (
            <>
              {formatCalls() &&
                Object.keys(formatCalls()).map((dayCalls) => (
                  <Box key={dayCalls}>
                    {/* Date des appels */}
                    <Typography color="secondary" sx={{ fontWeight: 'bold', marginY: '30px'}}>
                      {dayCalls === formatDateToFr(new Date()) ? "Aujourd'hui" : dayCalls}
                    </Typography>
                    {/* Liste des appels par jour */}
                    <Box>
                      {
                        formatCalls()[dayCalls].map(
                          (call) => <CallContainer key={call.id} {...call} isClosed={isClosed} />
                        )
                      }
                    </Box>
                  </Box>
                )
                )
              }
            </>)
        }
      </>
    </Box>
  )

}

Calls.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.exact({
      call: PropTypes.exact({
        callNumber: PropTypes.string.isRequired,
        convId: PropTypes.string,
        customer: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired,
        duration: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
      contact: PropTypes.exact({
        activities: PropTypes.array.isRequired,
        callNumber: PropTypes.string.isRequired,
        mobileNumber: PropTypes.string.isRequired,
        contact: PropTypes.string,
        companyName: PropTypes.string,
        companyUrl: PropTypes.string,
        contacts: PropTypes.array.isRequired,
        customer: PropTypes.string,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        tags: PropTypes.array.isRequired,
      }),
      customer: PropTypes.exact({
        activities: PropTypes.array.isRequired,
        calls: PropTypes.array.isRequired,
        id: PropTypes.number.isRequired,
        wazoUserUuid: PropTypes.string.isRequired,
      }),
      id: PropTypes.number.isRequired,
      closed: PropTypes.bool.isRequired,
      note: PropTypes.string.isRequired,
      priority: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    }),
  ),
  fetchActivities: PropTypes.func.isRequired,
  isFetchingActivities: PropTypes.bool.isRequired,
  isClosed: PropTypes.bool.isRequired,
  isFetchingToken: PropTypes.bool.isRequired,
}
export default Calls;