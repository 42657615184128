import { connect } from "react-redux";
import CallsHeld from "../components/CallsHeld";

// Actions

const mapStateToProps = (state) => ({
  calls: state.calls.calls,
})

// const mapDispatchToProps = (dispatch) => ({
// })

export default connect(mapStateToProps)(CallsHeld)
