import { SAVE_USERS, SAVE_IS_FETCHING_USERS, DELETE_USERS } from "./actionUsers";

const initialStateTest = {
  users: [],
  isFetchingUsers: false,
}

const reducerUsers = (state = initialStateTest, action) => {

  switch (action.type) {
    case SAVE_USERS:
      return {
        ...state,
        users: action.users,
      }

    case SAVE_IS_FETCHING_USERS:
      return {
        ...state,
        isFetchingUsers: action.isFetchingUsers,
      }

    case DELETE_USERS:
      return {
        ...state,
        users: [],
      }

    default: return state
  }
}
export default reducerUsers;