// Styles
import { styled } from '@mui/material/styles';

// Box
import Box from '@mui/material/Box';

// Background image
import imageFond from '../img/norway-winter.webp';

// Link
import { Link } from '@mui/material';

// Custom Box login
export const StyledBoxLogin = styled(Box)(() => ({
  height: '100vh',
  display: 'flex',
  overflow: 'hidden',
}));

// Custom Box with background image
export const StyledBoxWithBackground = styled(Box)(({ theme }) => ({
  width: '40%',
  backgroundImage: `url(${imageFond})`,
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  display: 'none',
  backgroundPositionY: '20%',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

// Custom Box with logo avelia 
export const StyledBoxWithLogoAvelia = styled(Box)(({ theme }) => ({
  verticalAlign: 'middle',
  maxHeight: { xs: 233, md: 167 },
  maxWidth: { xs: 350, md: 250 },
  [theme.breakpoints.up('md')]: {
    width: '300px',
    display: 'block',
  },
  [theme.breakpoints.up('xl')]: {
    width: '450px',
    display: 'block',
  },
}));

// Custom Logo avelia box
export const StyledLogoAveliaBox = styled(Box)(({ theme }) => ({
  padding: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
  height: '35%',
  display: 'flex',
}));

// Custom home box
export const StyledHomeBox = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  flex: '0 0 auto',
  justifyContent: 'center',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
}));

// Custom Welcome box
export const StyledWelcomeBox = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-start',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  width: '80%',
  borderRadius: '5px',
}));

// Custom welcome link
export const StyledWelcomeLink = styled(Link)(({ theme }) => ({
  color: 'white',
  textDecoration: 'underline',
  paddingLeft: '5px',
}));

// Custom form box
export const StyledFormBox = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'left',
  width: '80%',
}));