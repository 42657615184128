import React from 'react'
import PropTypes from 'prop-types'

// components
// containers
import CallContainerCti from "../containers/CallContainerCti";
// material
import { Typography, Box } from '@mui/material';
// Styled component
import { StyledPaperWithoutHover as StyledCallPaper } from "../styles/CallStyle";

const CallInProgress = ({ 
  calls,
}) => {
  const currentCall = calls.filter(call => call.status === "call_answered")[0];

  return (
    <>
      { undefined !== currentCall 
        ? (
          <CallContainerCti {...currentCall} />
        )
        : (    
          <StyledCallPaper>          
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}>
              <Typography variant='h2' sx={{ color: "palette.primary.main" }}>Pas d'appel en cours</Typography>
            </Box>
          </StyledCallPaper>
        )
      }
    </>
  )
}

CallInProgress.propTypes = {
  calls: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // Identifiant du client Genapi correspondant a cet appel
      clientId: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
}

export default CallInProgress