// React
import React, { useEffect, useCallback } from 'react'

// Proptypes
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';
// components
import CallContainerCti from '../containers/CallContainerCti'
import CallsSearchDialogContainer from '../containers/CallsSearchDialogContainer';

// MUI
import { Box, Typography } from '@mui/material';

// Const
import { HISTORY_STATUS_CLOSED } from '../redux/calls/reducerCalls';
import MoreCallsButton from './MoreCallsButton';

const CallsHistory = ({
  fetchCallsByCollaboratorUuid,
  // est obtenue via callsByCollaboratorUuid, le status de ces historiques et le tabIndex
  // cf HistoryCallsContainer filterCallByStatus()
  callsByCollaboratorUuid,
  isFetchingCalls,
  tabsIndex,
  connectedUserUuid,
  searchCalls,
  filters,
  historyCalls,
  deleteCallsByUuid,
  addMoreCallPeriod,
}) => {
  const location = useLocation();

  const tabIndex = useCallback(() => (
    tabsIndex[location.pathname]
  ), [location.pathname, tabsIndex])

  const callsByCollaboratorUuidByStatus = useCallback(() => (
    callsByCollaboratorUuid.filter((call) => historyCalls.filter(
      (historyCall) => historyCall.id === call.historyId)[0].status === tabIndex()
    )
  ), [callsByCollaboratorUuid, historyCalls, tabIndex])

  // permet de trier les appels par jours
  const formatDateToFr = (date) => date.toLocaleString('fr-Fr').split(' ')[0]
  const getCurrentCalls = useCallback(() => {

    let sortedCallsByCollaboratorUuidByStatus = callsByCollaboratorUuidByStatus().sort(function (a, b) {

      // Retrieve historyCall by ID
      let historyCallA = historyCalls.filter((historyCall) => historyCall.id === a.historyId)[0]
      let historyCallB = historyCalls.filter((historyCall) => historyCall.id === b.historyId)[0]

      // String to Timestamp
      if (typeof (historyCallA.updatedAt) === 'string') {
        historyCallA.updatedAt = new Date(historyCallA.updatedAt);
      }
      if (typeof (historyCallB.updatedAt) === 'string') {
        historyCallB.updatedAt = new Date(historyCallB.updatedAt);
      }
      return historyCallB.updatedAt.seconds - historyCallA.updatedAt.seconds;

    });
    return sortedCallsByCollaboratorUuidByStatus.reduce(
      (acc, curr) => {

        let historyCall = historyCalls.filter((historyCall) => historyCall.id === curr.historyId)

        let updatedAt = historyCall[0].updatedAt

        let date = formatDateToFr(new Date(updatedAt.seconds * 1000))

        const newData = acc[date] !== undefined
          ? [...acc[date], curr]
          : [curr]
        return {
          ...acc,
          [date]: newData,
        }
      },
      {},
    )
  }, [callsByCollaboratorUuidByStatus, historyCalls])

  useEffect(() => {
    // si l'onglet est celui des historique cloturé et que ces historiques ne sont pas encore charger depuis la bd
    if (HISTORY_STATUS_CLOSED === tabIndex() && 0 === callsByCollaboratorUuidByStatus().length) {
      fetchCallsByCollaboratorUuid(connectedUserUuid, HISTORY_STATUS_CLOSED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCallsByCollaboratorUuid])

  return (
    <Box sx={{ textAlign: 'center' }}>
      {/* Dialog recherche avancée d'appel */}
      { true && (
        <CallsSearchDialogContainer
          searchCalls={searchCalls}
          fetchCalls={fetchCallsByCollaboratorUuid}
          defaultFilters={{ collaboratorUuid: connectedUserUuid, historyStatusClosed: tabsIndex['/tickets'] }}
          deleteCalls={deleteCallsByUuid}
        />
      )}
      {callsByCollaboratorUuidByStatus() &&
        Object.keys(getCurrentCalls()).map((dayCalls) => (
          <Box key={dayCalls}>
            {/* Date des appels */}
            <Typography color="secondary" sx={{ fontWeight: 'bold' }}>{dayCalls === formatDateToFr(new Date()) ? "Aujourd'hui" : dayCalls}</Typography>
            {/* Liste des appels par jour */}
            <Box>
              {
                getCurrentCalls()[dayCalls].map(
                  (call) => <CallContainerCti key={call.id} {...call} />
                )
              }
            </Box>
          </Box>
        )
        )
      }
      {/* more button */}
      <MoreCallsButton
        isFetchingCalls={isFetchingCalls}
        filters={filters}
        currentPageName={'PAGE_CALL_HISTORY'}
        addMoreCallPeriod={addMoreCallPeriod}
        searchCalls={searchCalls}
        fetchCalls={() => fetchCallsByCollaboratorUuid(connectedUserUuid, HISTORY_STATUS_CLOSED)}
      />
    </Box>
  )
}

CallsHistory.propTypes = {
  callsByCollaboratorUuid: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // numéro de de téléphone de l'appel
      callNumber: PropTypes.string.isRequired,
      // nom de l'utilisateur wazo
      username: PropTypes.string.isRequired,
      // numéro court de l'utilisateur wazo
      userExtension: PropTypes.string.isRequired,
      // sens de l'appel
      direction: PropTypes.string.isRequired,
      // heure du debut de l'appel format hh:mm:ss
      startTime: PropTypes.string.isRequired,
      // heure du debut de la mise en pause de l'appel format hh:mm:ss
      heldStartTime: PropTypes.string.isRequired,
      // date du debut de l'appel format dd/MM//YY
      date: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // durée total de l'appel
      duration: PropTypes.number.isRequired,
      // status de l'appel. ex : call_created, call_held etc...
      status: PropTypes.string.isRequired,
      // identifiant wazo de l'utilisateur
      userId: PropTypes.string.isRequired,
      // identifiant de l'historique correspondant a cet appel
      historyId: PropTypes.string.isRequired,
      // client Genapi cf Clients
      clientId: PropTypes.number.isRequired
    }).isRequired,
  ).isRequired,
  // telecharge les appels depuis firestore
  fetchCallsByCollaboratorUuid: PropTypes.func.isRequired,
  // si le telechargement des appels depuis firestore est en cours
  isFetchingCalls: PropTypes.bool.isRequired,
  // indique l'onglet selectionner 0 pour les historiques en cours et 1 pour les cloturés
  tabsIndex: PropTypes.object.isRequired,
  // Id de l'utilisateur connecté
  connectedUserUuid: PropTypes.string.isRequired,
  // Recherche d'appel
  searchCalls: PropTypes.func.isRequired,
  // Filters
  filters: PropTypes.object.isRequired,
  // historyCalls
  historyCalls: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      // object de l'historique
      object: PropTypes.string.isRequired,
      // contenue de l'historique
      description: PropTypes.string.isRequired,
      // indique si l'historique est urgent, a rappeler ou normal
      priority: PropTypes.number.isRequired,
      // collabateur en charge de l'historique
      collaboratorUuid: PropTypes.string.isRequired,
      // status du ticket : HISTORY_STATUS_OPENED, HISTORY_STATUS_CLOSED
      status: PropTypes.number.isRequired,
      // date de la derniere mise à jour de l'historique
      updatedAt: PropTypes.exact({
        seconds: PropTypes.number.isRequired,
        nanoseconds: PropTypes.number.isRequired,
      }).isRequired,
      // identifiant du dossier genapi
      folderId: PropTypes.number.isRequired,
    }).isRequired,
  ),
  // delete Calls By Uuid
  deleteCallsByUuid: PropTypes.func.isRequired,
  // Add more calls 
  addMoreCallPeriod: PropTypes.func.isRequired,
}

export default CallsHistory