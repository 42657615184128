import React from 'react'
import PropTypes from 'prop-types'

// Mui Material
import {
  Box,
  Typography,
  IconButton,
  Table,
  TableContainer,
  TableCell,
  TableSortLabel,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';

// Mui Utils
import { visuallyHidden } from '@mui/utils';

import DownloadIcon from '@mui/icons-material/Download';

const FilesTable = ({ fetchFile, handleRequestSort, iconMapping, order, orderBy, filteredFiles }) => {

  return (
    <TableContainer sx={{ height: '45vh', borderRadius: '5px' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell sortDirection={orderBy === 'intitule' ? order : false}>
              <TableSortLabel
                active={orderBy === 'intitule'}
                direction={orderBy === 'intitule' ? order : 'asc'}
                onClick={() => handleRequestSort('intitule')}
              >
                Intitulé
                {orderBy === 'intitule' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'type' ? order : false}>
              <TableSortLabel
                active={orderBy === 'type'}
                direction={orderBy === 'type' ? order : 'asc'}
                onClick={() => handleRequestSort('type')}
              >
                Type
                {orderBy === 'type' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={orderBy === 'dateCreation' ? order : false}>
              <TableSortLabel
                active={orderBy === 'dateCreation'}
                direction={orderBy === 'dateCreation' ? order : 'asc'}
                onClick={() => handleRequestSort('dateCreation')}
              >
                Ajouté le
                {orderBy === 'dateCreation' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {filteredFiles.map((file) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={file.id}>
                <TableCell>
                  <Box display='flex' justifyContent="center" alignItems="center">
                    {iconMapping[file.type]}
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{file.intitule}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{file.type}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{file.dateCreation}</Typography>
                </TableCell>
                <TableCell>
                  <IconButton
                    color="secondary"
                    size="small"
                    aria-label="download file"
                    component="div"
                    title="Télécharger"
                    onClick={() => fetchFile(file.id, file.intitule)}
                  >
                    <DownloadIcon color={'secondary'} sx={{ marginBlock: 'auto', fontSize: ['20px', null, '26px'] }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}

        </TableBody>
      </Table>
    </TableContainer>
  )

}

FilesTable.propTypes = {
  // Récupère les fichiers attachés au dossier
  fetchFile: PropTypes.func.isRequired,
  // Gestion du tri
  handleRequestSort: PropTypes.func.isRequired,
  // Gestion des ICONs
  iconMapping: PropTypes.object.isRequired,
  // Tri des fichier
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  // Fichier filtré
  filteredFiles: PropTypes.arrayOf(
    PropTypes.exact({
      dateCreation: PropTypes.string.isRequired,
      dateModification: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      idClasseurParent: PropTypes.number,
      intitule: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      uploadTermine: PropTypes.bool.isRequired,
    }).isRequired,
  ),
}

export default FilesTable;