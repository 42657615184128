// PropTypes
import PropTypes from 'prop-types';

// MUI
import { Button, Box, ButtonGroup } from '@mui/material';

// CONST
import {
  ACTIVITY_NORMAL_PRIORITY,
  ACTIVITY_PLAN_PRIORITY,
  ACTIVITY_URGENT_PRIORITY,
  ACTIVITY_MODERATE_PRIORITY,
} from '../redux/activities/reducerActivities'

const ActivityPriorityButton = ({
  error,
  setError,
  readOnly,
  activityPriority,
  setActivityPriority,
}) => {
  const handleOnClickHistoryCallModalPriorityButton = (newPriority) => {
    setError({ ...error, priority: undefined});
    setActivityPriority(newPriority);
  }  
  return (
    <>
      {/* Selection de la priorité */}
      < Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <ButtonGroup
          sx={
            error?.priority === undefined
              ? (
                { textAlign: 'center', width: '100%' }
              )
              : (
                {
                  textAlign: 'center',
                  width: '100%',
                  border: 'solid 1px red',
                  borderRadius: '4px'
                }
              )
          }
          variant="outlined"
          aria-label="outlined button group"
          size="small"
        >
          <Button
            sx={{
              width: `calc(100% / 4)`,
              margin: '0!important',
              cursor: !readOnly ? 'pointer' : 'not-allowed',
              border: `1px solid`,
              borderColor: 'secondary.dark',
              borderRightWidth: '0px!important',
              '&:last-of-type': {
                border: `1px solid`,
                borderColor: 'secondary.dark',
                borderRightWidth: '1px!important',
              }
            }}
            variant={activityPriority === ACTIVITY_NORMAL_PRIORITY
              ? 'contained'
              : 'outlined'
            }
            color={"secondary"}
            onClick={() => !readOnly && handleOnClickHistoryCallModalPriorityButton(ACTIVITY_NORMAL_PRIORITY)}
          >
            Normal
          </Button>
          <Button
            sx={{
              width: `calc(100% / 4)`,
              margin: '0!important',
              cursor: !readOnly ? 'pointer' : 'not-allowed',
              border: `1px solid`,
              borderColor: 'warning.dark',
              borderRightWidth: '0px!important',
              '&:last-of-type': {
                border: `1px solid`,
                borderColor: 'warning.dark',
                borderRightWidth: '1px!important',
              }
            }}
            variant={activityPriority === ACTIVITY_MODERATE_PRIORITY
              ? 'contained'
              : 'outlined'
            }
            color={"warning"}
            onClick={() => !readOnly && handleOnClickHistoryCallModalPriorityButton(ACTIVITY_MODERATE_PRIORITY)}
          >
            Modéré
          </Button>
          <Button
            sx={{
              width: `calc(100% / 4)`,
              margin: '0!important',
              cursor: !readOnly ? 'pointer' : 'not-allowed',
              border: `1px solid`,
              borderColor: 'error.dark',
              borderRightWidth: '0px!important',
              '&:last-of-type': {
                border: `1px solid`,
                borderColor: 'error.dark',
                borderRightWidth: '1px!important',
              }
            }}
            variant={activityPriority === ACTIVITY_URGENT_PRIORITY
              ? 'contained'
              : 'outlined'
            }
            color={"error"}
            onClick={() => !readOnly && handleOnClickHistoryCallModalPriorityButton(ACTIVITY_URGENT_PRIORITY)}
          >
            Urgent
          </Button>
          <Button
            sx={{
              width: `calc(100% / 4)`,
              margin: '0!important',
              cursor: !readOnly ? 'pointer' : 'not-allowed',
              border: `1px solid`,
              borderColor: 'info.dark',
              borderRightWidth: '0px!important',
              '&:last-of-type': {
                border: `1px solid`,
                borderColor: 'info.dark',
                borderRightWidth: '1px!important',
              }
            }}
            variant={activityPriority === ACTIVITY_PLAN_PRIORITY
              ? 'contained'
              : 'outlined'
            }
            color={"info"}
            onClick={() => !readOnly && handleOnClickHistoryCallModalPriorityButton(ACTIVITY_PLAN_PRIORITY)}
          >
            Planification
          </Button>
        </ButtonGroup>
      </Box >
    </>
  )
}
ActivityPriorityButton.propTypes = {
  // permet de definir le message d'erreur lié a l'absence de priorité
  error: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  // si le select est modifiable ou non
  readOnly: PropTypes.bool.isRequired,
  // Priorité de l'activité
  activityPriority: PropTypes.string.isRequired,
  // Change la priority de l'activité
  setActivityPriority: PropTypes.func.isRequired,
}

export default ActivityPriorityButton;