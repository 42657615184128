export const SAVE_IS_DISPLAYED_WAZO_ODOO_MODAL = 'SAVE_IS_DISPLAYED_WAZO_ODOO_MODAL';
export const SAVE_IS_CONNECTED_TO_ODOO = 'SAVE_IS_CONNECTED_TO_ODOO';
export const FETCH_IS_CONNECTED_TO_ODOO = 'FETCH_IS_CONNECTED_TO_ODOO';
export const SAVE_IS_CONNECTING_TO_ODOO = 'SAVE_IS_CONNECTING_TO_ODOO';

export const saveIsDisplayedWazoOdooModal = (isDisplayedWazoOdooModal) => ({
  type: SAVE_IS_DISPLAYED_WAZO_ODOO_MODAL,
  isDisplayedWazoOdooModal,
})

export const saveIsConnectedToOdoo = (isConnectedToOdoo) => ({
  type: SAVE_IS_CONNECTED_TO_ODOO,
  isConnectedToOdoo,
})

export const fetchIsConnectedToOdoo = (email, uuid) => ({
  type: FETCH_IS_CONNECTED_TO_ODOO,
  email,
  uuid,
})

export const saveIsConnectingToOdoo = (isConnectingToOdoo) => ({
  type: SAVE_IS_CONNECTING_TO_ODOO,
  isConnectingToOdoo,
})