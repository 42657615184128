// Styles
import { styled } from '@mui/material/styles';
// material
import { Box } from '@mui/material';

// Custom typography
export const StyledTabsPanelBox = styled(Box)(({ theme }) => ({
  display:'flex',
  justifyContent: 'center',
  paddingTop: '1rem',
  overflowY: 'scroll',
  height: '100%',
  width: '100%',
  background: 'rgb(238 238 238)',
  [theme.breakpoints.up('md')]: {
    borderRadius: '4px',
  },
}));