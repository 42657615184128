// PAGE_CALLS
// containers
import CallInProgressContainer from './containers/CallInProgressContainer';
import CallsEndedContainer from './containers/CallsEndedContainer';
import CallsHeldContainer from './containers/CallsHeldContainer';
import CallsCreatedContainer from './containers/CallsCreatedContainer';
// icons
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';
import PhoneCallbackOutlinedIcon from '@mui/icons-material/PhoneCallbackOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
// PAGE_CLIENTS
// containers
import ClientsContainer from './containers/ClientsContainer'
// icons
import PersonIcon from '@mui/icons-material/Person';
// PAGE_CLIENT
// containers
import ClientContainer from './containers/ClientContainer';
import ClientCallsContainer from './containers/ClientCallsContainer';
import ClientFoldersContainer from './containers/ClientFoldersContainer';
// icons
import HistoryIcon from '@mui/icons-material/History';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FolderIcon from '@mui/icons-material/Folder';
// PAGE_CALL_HISTORY
// containers
import CallsHistoryContainer from './containers/CallsHistoryContainer';
// icones
import DraftsIcon from '@mui/icons-material/Drafts';
import DoneAllIcon from '@mui/icons-material/DoneAll';

// PAGE_ACTIVITIES
import CallsContainer from './containers/CallsContainer';

// ROUTES
export const PAGE_DEFAULT = '/clients';
export const PAGE_CALLS = '/appels';
export const PAGE_CLIENTS = '/clients';
export const PAGE_LOGIN = '/login';
export const PAGE_CALL_HISTORY = '/tickets';
export const PAGE_CLIENT = '/clients/:clientId';
export const PAGE_ACTIVITIES = '/activites';


export const pages = (clientId) => ({
  [PAGE_CALLS]: {
    tabs: [
      {
        name: 'En cours',
        icon: <LocalPhoneOutlinedIcon />,
        badge: 'call_answered',
        content: <CallInProgressContainer />,
      },
      {
        name: 'En attente',
        icon: <PhonePausedOutlinedIcon />,
        badge: 'call_held',
        content: <CallsHeldContainer />,
      },
      {
        name: 'Entrant',
        icon: <PhoneCallbackOutlinedIcon />,
        badge: 'call_created',
        content: <CallsCreatedContainer />,
      },
      {
        name: 'Récent',
        icon: <HistoryOutlinedIcon />,
        content: <CallsEndedContainer />,
      },
    ]
  },
  [PAGE_CLIENTS]: {
    tabs: [
      {
        name: 'Clients',
        icon: <PersonIcon />,
        content: <ClientsContainer />,
      },
    ]
  },
  [PAGE_CALL_HISTORY]: {
    tabs: [
      {
        name: 'En cours',
        icon: <DraftsIcon />,
        content: <CallsHistoryContainer />,
      },
      {
        name: 'Clôturés',
        icon: <DoneAllIcon />,
        content: <CallsHistoryContainer />,
      },
    ]
  },
  [`/clients/${clientId}`]: {
    tabs: [
      {
        name: 'Fiche Client',
        icon: <HistoryIcon />,
        content: <ClientContainer />,
      },
      {
        name: 'Historiques',
        icon: <AccountBoxIcon />,
        content: <ClientCallsContainer />,
      },
      {
        name: 'Dossiers',
        icon: <FolderIcon />,
        content: <ClientFoldersContainer />,
      },
    ]
  },
  [PAGE_ACTIVITIES]: {
    tabs: [
      {
        name: 'En cours',
        icon: <DraftsIcon />,
        content: <CallsContainer isClosed={false}/>,
      },
      {
        name: 'Clôturés',
        icon: <DoneAllIcon />,
        content: <CallsContainer isClosed={true}/>,
      },
    ]
  },
})