import React from "react";

import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types'
import { TreeItem, useTreeItem } from '@mui/x-tree-view/TreeItem';

import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Box } from "@mui/material";

// Composant récursif pour afficher l'arborescence
const TreeNode = ({ tree, saveSelectedBinder, countFilesByBinder }) => {
  const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
    } = props;

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
      preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    // Gestion selection d'un classeur
    const handleMouseDown = (event) => {
      preventSelection(event);
    };
    const handleExpansionClick = (event) => {
      handleExpansion(event);
    };
    const handleSelectionClick = (event) => {
      handleSelection(event);
      saveSelectedBinder(event.toString())
    };

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div onClick={handleExpansionClick} className={classes.iconContainer}>
          <Box color={'secondary'}>
            {icon}
          </Box>

        </div>
        
        {/* Icon plein ou vide */}
        {(countFilesByBinder[nodeId]) ?
          (<FolderIcon color="secondary" onClick={() => handleSelectionClick(nodeId)} />)
          :
          (<FolderOpenIcon color="secondary" onClick={() => handleSelectionClick(nodeId)} />)
        }
        {/* Nom du classeur */}
        <Typography
          onClick={() => handleSelectionClick(nodeId)}
          component="div"
          variant="body1"
          className={classes.label}
        >
          {label}
        </Typography>
      </div>
    );
  });

  const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    return <TreeItem ContentComponent={CustomContent} {...props} ref={ref} />;
  });
  var data = tree

  return (
    <>
      {(data && Object.keys(data).length !== 0) ?
        <>
         {/* Recursive arborescence */}
          {data.map(node => (
            <CustomTreeItem key={node.id} nodeId={String(node.id)} label={node.nom}>
              {node.children && <TreeNode tree={node.children} saveSelectedBinder={saveSelectedBinder} countFilesByBinder={countFilesByBinder} />}
            </CustomTreeItem>
          ))}
        </>
        :
        null
      }
    </>
  )
}

TreeNode.propTypes = {
  // Arbre de donnée
  tree: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      idClasseurParent: PropTypes.number.isRequired,
      idSousDossierParent: PropTypes.number.isRequired,
      nom: PropTypes.string.isRequired,
      ordre: PropTypes.number.isRequired,
      children: PropTypes.array,
    }).isRequired,
  ).isRequired,
  // Sauvegarde du classeur séléctionné
  saveSelectedBinder: PropTypes.func.isRequired,
  // Liste du nombre de fichier par classeur
  countFilesByBinder: PropTypes.object.isRequired,
}
export default TreeNode;