import React from 'react';
import PropTypes from 'prop-types';

// Mui Material
import {
  Box,
  Typography,
} from '@mui/material';

const HeaderFilesTypes = ({ handleChange, listeTypeDoc, type }) => {

  return (
    <Box display="flex" justifyContent="space-around" bgcolor="primary.light" sx={{ width: '100%', paddingBlock: "5px" }}>
      {
        listeTypeDoc.map((typeDoc) => {
          if (type === typeDoc.intitule) {
            return (
              <Box display="flex" key={typeDoc.id} sx={{ borderBottom: '2px solid #ffffff' }}>
                {typeDoc.icon}
                <Typography variant="body1" noWrap component="div" color="#ffffff" sx={{ fontWeight: 'bold', paddingInline: '2px' }} >
                  {typeDoc.nom}
                </Typography>
              </Box>
            )
          }
          return (
            <Box display="flex" key={typeDoc.id} onClick={() => handleChange(typeDoc.intitule)} sx={{ cursor: "pointer" }}>
              {typeDoc.icon}
              <Typography key={typeDoc.id} variant="subtitle1" noWrap component="div" color="#ffffff" sx={{ paddingInline: '2px' }}>
                {typeDoc.nom}
              </Typography>
            </Box>
          )
        })
      }
    </Box>
  )
}

HeaderFilesTypes.propTypes = {
  // Gestion changement de filtre (Type de fichier)
  handleChange: PropTypes.func.isRequired,
  // liste des types de fichier
  listeTypeDoc: PropTypes.array.isRequired,
  // Type de fichier
  type: PropTypes.string.isRequired,
}

export default HeaderFilesTypes;