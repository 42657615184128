export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const FETCH_FULL_CLIENT = 'FETCH_FULL_CLIENT';
export const SAVE_FULL_CLIENT = 'SAVE_FULL_CLIENT';
export const SAVE_CLIENTS = 'SAVE_CLIENTS';
export const REPLACE_CLIENTS = 'REPLACE_CLIENTS';
export const DELETE_CLIENTS = 'DELETE_CLIENTS';
export const GET_TOKEN = 'GET_TOKEN';
export const SAVE_GENAPI_TOKEN = 'SAVE_GENAPI_TOKEN';
export const FETCH_FOLDERS = 'FETCH_FOLDERS';
export const SAVE_FOLDERS = 'SAVE_FOLDERS';
export const DELETE_FOLDERS = 'DELETE_FOLDERS';
export const SAVE_IS_FETCHING_FOLDERS = 'SAVE_IS_FETCHING_FOLDERS';
export const SAVE_IS_FETCHING_CLIENTS = 'SAVE_IS_FETCHING_CLIENTS';
export const FETCH_FILES = 'FETCH_FILES';
export const SAVE_FILES = 'SAVE_FILES';
export const FETCH_FILE = 'FETCH_FILE';
export const SAVE_FILE = 'SAVE_FILE';
export const DELETE_FILES = 'DELETE_FILES';
export const SAVE_IS_FETCHING_FILES = 'SAVE_IS_FETCHING_FILES';
export const SAVE_IS_DISPLAYED_CLIENT_FOLDER_DIALOG = 'SAVE_IS_DISPLAYED_CLIENT_FOLDER_DIALOG';
export const SAVE_FOLDER_DISPLAYED = 'SAVE_FOLDER_DISPLAYED';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const SAVE_IS_UPLOADING_FILE = 'SAVE_IS_UPLOADING_FILE';
export const FETCH_BINDERS = 'FETCH_BINDERS';
export const SAVE_BINDERS = 'SAVE_BINDERS';
export const DELETE_BINDERS = 'DELETE_BINDERS';
export const SAVE_SELECTED_BINDER = 'SAVE_SELECTED_BINDER'
export const SAVE_IS_FETCHING_FULL_CLIENT = 'SAVE_IS_FETCHING_FULL_CLIENT';
export const SAVE_FETCHING_FULL_CLIENT_ERROR = 'SAVE_FETCHING_FULL_CLIENT_ERROR';

// Recuperation des clients
export const fetchClients = token => ({
  type: FETCH_CLIENTS,
  token
})

// Recuperation d'un client
export const fetchFullClient = (clientId) => ({
  type: FETCH_FULL_CLIENT,
  clientId,
})

// sauvegarde d'un client
export const saveFullClient = fullClient => ({
  type: SAVE_FULL_CLIENT,
  fullClient
})

// Sauvegarde des clients dans le state
export const saveClients = (data) => ({
  type: SAVE_CLIENTS,
  data
})

// remplace la liste des clients par data
export const replaceClients = (data) => ({
  type: REPLACE_CLIENTS,
  data
})

export const deleteClients = () => ({
  type: DELETE_CLIENTS,
})

// Recuperation du token
export const getToken = () => ({
  type: GET_TOKEN,
})

// Sauvegarde du token
export const saveGenapiToken = (accessToken, expires_in, refresh_token, token_type) => ({
  type: SAVE_GENAPI_TOKEN,
  accessToken,
  expires_in,
  refresh_token,
  token_type
})

// Récuperation des dossiers
export const fetchFolders = (clientId) => ({
  type: FETCH_FOLDERS,
  clientId,
})

// Suppression des dossiers
export const deleteFolders = () => ({
  type: DELETE_FOLDERS,
})

// Sauvegarde des dossiers dans le state
export const saveFolders = (data) => ({
  type: SAVE_FOLDERS,
  data
})

// si l'app est en train de recupérer les dossiers de Genapi
export const saveIsFetchingFolders = (isFetchingFolders) => ({
  type: SAVE_IS_FETCHING_FOLDERS,
  isFetchingFolders
})

// si l'app est en train de recupérer les clients de Genapi
export const saveIsFetchingClients = (isFetchingClients) => ({
  type: SAVE_IS_FETCHING_CLIENTS,
  isFetchingClients
})

// Récuperation des fichiers
export const fetchFiles = (folderId) => ({
  type: FETCH_FILES,
  folderId,
})

// Sauvegarde des fichiers dans le state
export const saveFiles = (folderFiles) => ({
  type: SAVE_FILES,
  folderFiles
})

// Suppression des fichiers
export const deleteFiles = () => ({
  type: DELETE_FILES,
})

// Récuperation d'un fichier
export const fetchFile = (fileId, fileIntitule) => ({
  type: FETCH_FILE,
  fileId,
  fileIntitule,
})

// Sauvegarde du fichier dans le state
export const saveFile = (downloadedFile) => ({
  type: SAVE_FILE,
  downloadedFile
})

// si l'app est en train de recupérer les fichiers de Genapi
export const saveIsFetchingFiles = (isFetchingFiles) => ({
  type: SAVE_IS_FETCHING_FILES,
  isFetchingFiles
})

// Affichage de la modal de dossier client
export const saveIsDisplayedClientFolderDialog = (isDisplayedClientFolderDialog) => ({
  type: SAVE_IS_DISPLAYED_CLIENT_FOLDER_DIALOG,
  isDisplayedClientFolderDialog,
})

// Dossier affiché dans la modal
export const saveFolderDisplayed = (folderDisplayed) => ({
  type: SAVE_FOLDER_DISPLAYED,
  folderDisplayed,
})

// Envoyer un fichier vers le CRM
export const uploadFile = (uploadingFile, folderId, binderId) => ({
  type: UPLOAD_FILE,
  uploadingFile,
  folderId,
  binderId,
})

// l'app envoie un fichier dans le CRM
export const saveIsUploadingFile = (isUploadingFile) => ({
  type: SAVE_IS_UPLOADING_FILE,
  isUploadingFile
})

// Récuperation des classeurs
export const fetchBinders = (folderId) => ({
  type: FETCH_BINDERS,
  folderId,
})

// Sauvegarde des classeurs dans le state
export const saveBinders = (binders) => ({
  type: SAVE_BINDERS,
  binders
})

// Suppression des classeurs
export const deleteBinders = () => ({
  type: DELETE_BINDERS,
})

// Sauvegarde du classeur séléctionné dans le state
export const saveSelectedBinder = (selectedBinder) => ({
  type: SAVE_SELECTED_BINDER,
  selectedBinder
})

// si l'app est en train de recupérer un clients de Genapi
export const saveIsFetchingFullClient = (isFetchingFullClient) => ({
  type: SAVE_IS_FETCHING_FULL_CLIENT,
  isFetchingFullClient
})

// si l'app est en train de recupérer un clients de Genapi
export const saveIsFetchingClientError = (isFetchingClientError) => ({
  type: SAVE_FETCHING_FULL_CLIENT_ERROR,
  isFetchingClientError

})