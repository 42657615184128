import React, { useEffect, useState, useCallback} from 'react'
import PropTypes from 'prop-types'

// material
import { Typography } from '@mui/material'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

// Utils
import { getTwoDigitTime } from "../utils";

const Timer = ({ startTime, heldStartTime, status }) => {
  const [time, setTime] = useState(0);

  // Hours calculation
  let hours = Math.floor((time % 86400 ) / 3600);
  hours = getTwoDigitTime(hours);
  // Minutes calculation
  let minutes = Math.floor((time % 3600) / 60);
  minutes = getTwoDigitTime(minutes);
  // Seconds calculation
  let seconds = Math.floor((time % 60));
  seconds = getTwoDigitTime(seconds);

  const getDuration = useCallback(() => {
    const today = (new Date()).toLocaleDateString("en-En", {day: 'numeric', month: 'numeric', year: 'numeric'});
    let newStartTime = (new Date(`${today} ${startTime}`));

    if ("call_held" === status ) {
      newStartTime = (new Date(`${today} ${heldStartTime}`));
    }
    // new Date().getTime() devrait être égale startTime
    // mais il y a un décalage qui fait que le premier résultat peut être néagatif
    const currentTimestamp = new Date().getTime() - newStartTime.getTime() > 0
      ? new Date().getTime() - newStartTime.getTime()
      : 0
    ;

    return currentTimestamp / 1000;
  }, [startTime, heldStartTime, status])

  // pour initialiser le timer dès le 1er rendu du composant
  useEffect(() => {
    setTime(getDuration())
  }, [getDuration])

  useEffect(() => {
    let intervalId;
    intervalId = setInterval(() => setTime(getDuration()), 1000);
    
    return () => clearInterval(intervalId);
  }, [time, startTime, getDuration]);

  return (
    <>
      {
        "call_created" === status && (
          <NotificationsActiveIcon color="secondary" />
        )
      }
      {
        "call_held" === status && (
          <PhonePausedIcon color="secondary" />
        )
      }
      {
        "call_answered" === status && (
          <AccessAlarmIcon color="secondary" />
        )
      }
      <Typography sx={{ color: "primary.light" }}>{hours}:{minutes}:{seconds}</Typography>
    </>
  )
}

Timer.propTypes = {
  // heure du début du timer
  startTime: PropTypes.string.isRequired,
  // status de l'appel. ex : call_created, call_held etc...
  status: PropTypes.string.isRequired,
}

export default Timer