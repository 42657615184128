import { connect } from "react-redux";
import CallsHistory from "../components/CallsHistory";
// actions
import { fetchCallsByCollaboratorUuid, searchCalls, deleteCallsByUuid, addMoreCallPeriod } from "../redux/calls/actionCalls";

const mapStateToProps = (state) => ({
  callsByCollaboratorUuid: state.calls.filters['/tickets']?.historyStatusClosed === state.tabs.tabIndex['/tickets'] ? state.calls.searchedCallsByCollaboratorUuid : state.calls.callsByCollaboratorUuid,
  isFetchingCalls: state.calls.isFetchingCalls,
  tabsIndex: state.tabs.tabIndex,
  connectedUserUuid: state.login.uuid,
  filters: state.calls.filters,
  historyCalls: state.calls.historyCalls,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCallsByCollaboratorUuid: (collaboratorUuid, historyCallStatus) => dispatch(fetchCallsByCollaboratorUuid(collaboratorUuid, historyCallStatus)),
  searchCalls: (loadMore) => dispatch(searchCalls(loadMore)),
  deleteCallsByUuid: () => dispatch(deleteCallsByUuid()),
  addMoreCallPeriod: (currentPageName) => dispatch(addMoreCallPeriod(currentPageName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CallsHistory)