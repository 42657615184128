import React, { useState } from 'react'
import PropTypes from 'prop-types'
// material
import {
  Box,
  TextField,
  IconButton,
  Grid,
  Collapse,
  Typography,
} from '@mui/material';
// icons
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const HistoryCallNewMessage = ({
  postHistoryCallMessage,
  historyCallId,
  userUuid,
}) => {
  const [message, setMessage] = useState('');
  const [isWritingMessage, setIsWritingMessage] = useState(false);
  const [isDisplayedActions, setIsDisplayedActions] = useState(false);

  // affiche le message d'erreur
  const MAX_LENGTH_MESSAGE = 500;
  const ErrorTypography = () => (
    message.length > MAX_LENGTH_MESSAGE && (
      <Typography component="p" sx={{ "textAlign": "left", mb: 2, display: 'flex', alignItems: 'center' }} color="error">
        <ErrorOutlineIcon sx={{ mr: 1 }} />
        {`Le message ne peut pas dépasser ${MAX_LENGTH_MESSAGE} caractères`}
      </Typography>
    )
  )
  const handleOnFocusTextField = () => {
    setIsWritingMessage(true)
    setTimeout(() => setIsDisplayedActions(true), 176)
  }

  const closeActions = () => {
    setTimeout(() => setIsDisplayedActions(false), 5)
    setTimeout(() => setIsWritingMessage(false), 176)
  }

  const handleOnBlurTextField = () => {
    if (message.length === 0) {
      closeActions()
    }
  }

  const handleOnCancelMessage = () => {
    setMessage('')
    closeActions()
  }

  const handleOnClickSendNewMessage = () => {
    if (message.length < MAX_LENGTH_MESSAGE) {
      setMessage('');
      closeActions()
      postHistoryCallMessage({
        message,
        historyCallId,
        date: new Date(),
        userUuid
      })
    }
  }
  
  return (      
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
      position: 'relative',
      minHeight: '110px',
    }}>
      <Grid container alignItems={'center'}>
        <Grid item xs={isWritingMessage ? 11 : 12} sx={{
          display: 'flex',
          alignItems: 'center',
          transition: 'flex-basis 0.175s, max-width 0.175s',
        }}>
          <AccountCircleIcon color='primary' sx={{ mr: 2 }} />
          <Box sx={{ width: '100%' }}>
            <ErrorTypography />
            <TextField
              id="newMessageInput"
              label="Ecrire un nouveau message..."
              fullWidth
              minRows={3}
              maxRows={6}
              multiline
              onFocus={handleOnFocusTextField}
              onBlur={handleOnBlurTextField}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Box>
        </Grid>
        {
          <Grid item xs={isDisplayedActions ? 1 : 0} >
            <Collapse in={isDisplayedActions}>
              {
                isDisplayedActions &&
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    mb: '0.5rem', // to center
                  }}>
                    <IconButton
                      color="success"
                      size="large"
                      aria-label="save message"
                      component="div"
                      title="valider"
                      disabled={message.length === 0 || message.length > MAX_LENGTH_MESSAGE}
                      onClick={handleOnClickSendNewMessage}
                    >
                      <SendIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      size="large"
                      aria-label="cancel message"
                      component="div"
                      title="annuler"
                      onClick={handleOnCancelMessage}
                    >
                      <CancelOutlinedIcon />
                    </IconButton>
                  </Box>   
              }
            </Collapse>
          </Grid>
        }
      </Grid>
    </Box>
  )
}

HistoryCallNewMessage.propTypes = {
  // permet de rajouter un historyCallMessage en bdd
  postHistoryCallMessage: PropTypes.func.isRequired,
  historyCallId: PropTypes.string.isRequired,
  userUuid: PropTypes.string.isRequired,
}

export default HistoryCallNewMessage