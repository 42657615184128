import { 
  SAVE_IS_DISPLAYED_WAZO_ODOO_MODAL,
  SAVE_IS_CONNECTED_TO_ODOO,
  SAVE_IS_CONNECTING_TO_ODOO,
} from "./actionWazoOdoo";

const initialStateTest = {
  isDisplayedWazoOdooModal: false,
  isConnectedToOdoo: true,
  isConnectingToOdoo: false,
}

const reducerWazoOdoo = (state = initialStateTest, action) => {
  switch (action.type) {
    case SAVE_IS_DISPLAYED_WAZO_ODOO_MODAL:
      return {
        ...state,
        isDisplayedWazoOdooModal: action.isDisplayedWazoOdooModal,
      }

    case SAVE_IS_CONNECTED_TO_ODOO:
      return {
        ...state,
        isConnectedToOdoo: action.isConnectedToOdoo,
      }
    
    case SAVE_IS_CONNECTING_TO_ODOO:
      return {
        ...state,
        isConnectingToOdoo: action.isConnectingToOdoo,
      }

    default: return state
  }
}
export default reducerWazoOdoo;