// React Redux
import { connect } from "react-redux";

// Components
import CallCti from "../components/CallCti";
// actions
import { 
  saveIsDisplayedHistoryCallModal, 
} from "../redux/calls/actionCalls";
// const
import { HISTORY_DEFAULT_PRIORITY } from '../redux/calls/reducerCalls'

const mapStateToProps = (state, ownProps) => {
  const historycall = state.calls.historyCalls.filter((callHistory) => callHistory.id === ownProps.historyId)[0]

  return ({
    clients: state.clients.clients,
    historyCallPriority: historycall?.priority ?? HISTORY_DEFAULT_PRIORITY,
    historyCallObject: historycall?.object === '' ? 'Autres...' : historycall?.object,
    users: state.users.users,
  })
}

const mapDispatchToProps = (dispatch) => ({
  saveIsDisplayedHistoryCallModal: (callId, isDisplayedCallHisoryModal) => dispatch(saveIsDisplayedHistoryCallModal(callId, isDisplayedCallHisoryModal)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CallCti)