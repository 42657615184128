// React redux
import { connect } from "react-redux";

// Components
import Landing from "../components/Landing";

// Actions
import { fetchUsers } from "../redux/users/actionUsers";
import { logOut } from "../redux/login/actionLogin";
import { saveIsDisplayedWazoOdooModal } from "../redux/wazoOdoo/actionWazoOdoo";

const mapStateToProps = (state) => ({
  users: state.users.users,
  isAuthenticationLoading: state.login.isAuthenticationLoading,
  isConnected: state.login.isConnected,
  isConnectingWebsocket: state.websocketWazo.isConnectingWebsocket,
  isFetchingUsers: state.users.isFetchingUsers,
  isDisconnecting: state.login.isDisconnecting,
  userEmail: state.login.email,
  isConnectedToOdoo: state.wazoOdoo.isConnectedToOdoo,
  isFetchingToken: state.login.isFetchingToken,
})

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(fetchUsers()),
  logOut: () => dispatch(logOut()),
  saveIsDisplayedWazoOdooModal: (callId, isDisplayedWazoOdooModal) => dispatch(saveIsDisplayedWazoOdooModal(callId, isDisplayedWazoOdooModal)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Landing)