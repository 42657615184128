import { useCallback } from 'react'
import PropTypes from 'prop-types'
// components
import Timer from './Timer';
import Duration from './Duration';
import HistoryCallObjectIcon from './HistoryCallObjectIcon';
import CallNumberMenu from './CallNumberMenu';
// containers
import HistoryCallModalContainer from '../containers/HistoryCallModalContainer';
import TypographyTooltipContainer from '../containers/TypographyTooltipContainer';
// Styled Components
import { StyledPaper } from "../styles/CallStyle";

// MUI
import {
  Typography,
  Box,
  Grid,
} from '@mui/material';

// MUI ICONS
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EastIcon from '@mui/icons-material/East';

// CONST
import {
  HISTORY_NORMAL_PRIORITY,
  HISTORY_CALLBACK_PRIORITY,
  HISTORY_URGENT_PRIORITY,
  HISTORY_DEFAULT_PRIORITY,
} from '../redux/calls/reducerCalls'

const Call = (props) => {
  const {
    id,
    callNumber,
    startTime,
    direction,
    status,
    historyId,
    saveIsDisplayedHistoryCallModal,
    historyCallPriority,
    historyCallObject,
    clients,
    users,
    clientId,
  } = props;

  const borderColor = {
    [HISTORY_DEFAULT_PRIORITY]: 'white',
    [HISTORY_NORMAL_PRIORITY]: 'secondary.main',
    [HISTORY_CALLBACK_PRIORITY]: 'warning.main',
    [HISTORY_URGENT_PRIORITY]: 'error.main',
  }

  // ouvre la creation du ticket sauf si l'utilisateur click sur le numéro de telephone
  const handleOnClickOpenHistoryCall = (e) => {
    if (e.target.classList.contains('notTriggerOpenHistoryCall')) return
    saveIsDisplayedHistoryCallModal(id, true)
  }

  const getUser = useCallback(() => (
    users.filter(({ lines }) => lines.includes(callNumber))[0]
  ),
    [callNumber, users],
  );

  const getClient = useCallback(() => (
    clients.filter((client) => client.id === clientId)[0]
  ),
    [clientId, clients],
  );

  const user = getUser();
  const client = getClient();

  return (
    <>
      {/* Call */}
      <StyledPaper
        sx={{
          borderColor: `${borderColor[historyCallPriority]}`,
          '&:hover': {
            borderColor: `${borderColor[historyCallPriority]}`,
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginY: 'auto',
            height: '100%'
          }}
          onClick={handleOnClickOpenHistoryCall}
        >
          <Grid container sx={{ alignItems: 'center' }}>
            {/* History Call Object Icon &  Caller's name/number */}
            <Grid container item xs={12} md={6} lg={5}
              sx={{
                alignItems: 'center',
                flexDirection: ['row-reverse', null, 'row'],
                marginBottom: [2, null, 0]
              }}
            >
              {/* History Call Object Icon */}
              <Box
                sx={{
                  textAlign: 'end',
                  mt: [client ? '-7px' : '0px', null, '0px'],
                  alignItems: 'center',
                  display: ['block', null, 'flex']
                }}
              >
                <HistoryCallObjectIcon iconeName={historyCallObject} sx={{
                  color: 'secondary.main',
                  fontSize: ['24px', null, '30px'],
                  p: 0,
                }} />
              </Box>
              {/* Caller's name/number */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: ['center', 'center', 'flex-start'],
                  textAlign: ['center', 'center', 'left'],
                  maxWidth: ['80%', '80%', '85%'],
                  width: 'auto',
                  marginX: ['auto', null, 0],
                  ml: ['auto', null, 2, 4]
                }}
              >
                {
                  client ? (
                    <TypographyTooltipContainer
                      typographySx={{
                        color: "primary.light",
                        fontSize: '15px',
                      }}
                      direction={direction}
                      client={client}
                    >
                      {/* Si le client est présent en BDD et que ce n'est pas un appel interne */}
                      {
                        client && 'internal' !== direction && client?.denomination
                      }
                      {/* Si le client est présent en BDD et que c'est un appel interne */}
                      {
                        client && 'internal' === direction && client?.denomination
                      }
                    </TypographyTooltipContainer>
                  )
                    :
                    (
                      <Typography sx={{
                        display: 'inline-block',
                        maxWidth: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden !important',
                        textOverflow: 'ellipsis',
                        color: 'primary.light',
                        fontSize: '15px',
                        fontWeight: '550',
                      }}>
                        {/* Si le client n'est pas présent en BDD et que c'est un appel interne */}
                        {
                          !client && 'internal' === direction && `${user?.firstname ?? ''} ${user?.lastname ?? ''}`
                        }
                        {/* Si le client n'est pas présent en BDD et que ce n'est pas un appel interne */}
                        {
                          !client && 'internal' !== direction && <CallNumberMenu callNumber={callNumber} />
                        }
                      </Typography>
                    )
                }
                {/* Client Name */}

                {/* Client CallNumber */}
                {(client || 'internal' === direction) && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    className='notTriggerOpenHistoryCall'
                  >
                    <LocalPhoneIcon
                      sx={{
                        fontSize: '14px',
                      }}
                      color="secondary"
                      className='notTriggerOpenHistoryCall'
                    />
                    <Typography
                      variant='body1'
                      sx={{
                        color: "secondary.main",
                        fontSize: '12px!important',
                        mb: '2px',
                      }}
                      className='notTriggerOpenHistoryCall'
                    >
                      <CallNumberMenu callNumber={callNumber} />
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            {/* Call duration, direction and startime */}
            <Grid container item xs={12} md={6} lg={7}
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              {/* Call duration */}
              <Grid item xs={3} md={3} lg={2} xl={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {"call_ended" !== status
                  ? (
                    <Timer {...props} />
                  )
                  : (
                    <Duration {...props} />
                  )
                }
              </Grid>
              {/* Call direction */}
              <Grid item xs={6} md={4} lg={3} xl={3} xxl={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center",
                }}>
                {
                  direction === 'inbound' && (
                    <>
                      <CallReceivedIcon color="secondary" sx={{ m: 0, p: 0 }} />
                      <Typography sx={{ color: "primary.light" }}>Appel Entrant</Typography>
                    </>
                  )
                }
                {
                  direction === 'outbound' && (
                    <>
                      <CallMadeIcon color="secondary" />
                      <Typography sx={{ color: "primary.light" }}>Appel Sortant</Typography>
                    </>
                  )
                }
                {
                  direction === 'internal' && (
                    <>
                      <EastIcon color="secondary" />
                      <Typography sx={{ color: "primary.light" }}>Appel Interne</Typography>
                    </>
                  )
                }
              </Grid>
              {/* Date */}
              <Grid item xs={3} md={3} lg={2} xl={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: "center",
                }}
              >
                <>
                  <DateRangeIcon color="secondary" />
                  <Typography sx={{ color: "primary.light" }}>{startTime.replace(':', 'h').slice(0, -3)}</Typography>
                </>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </StyledPaper>
      <HistoryCallModalContainer id={historyId} callId={id} client={client} />
    </>
  )
}

Call.propTypes = {
  id: PropTypes.string.isRequired,
  // numéro de de téléphone de l'appel
  callNumber: PropTypes.string.isRequired,
  // nom de l'utilisateur wazo
  username: PropTypes.string.isRequired,
  // numéro court de l'utilisateur wazo
  userExtension: PropTypes.string.isRequired,
  // sens de l'appel
  direction: PropTypes.string.isRequired,
  // heure du debut de l'appel format hh:mm:ss
  startTime: PropTypes.string.isRequired,
  // date du debut de l'appel format dd/MM//YY
  // heure du debut de la mise en pause de l'appel format hh:mm:ss
  heldStartTime: PropTypes.string.isRequired,
  date: PropTypes.exact({
    seconds: PropTypes.number.isRequired,
    nanoseconds: PropTypes.number.isRequired,
  }).isRequired,
  // status de l'appel. ex : call_created, call_held etc...
  status: PropTypes.string.isRequired,
  // valeur utilisée pour calculé la durée du timer
  duration: PropTypes.number.isRequired,
  // identifiant wazo de l'utilisateur
  userId: PropTypes.string.isRequired,
  // affiche/cache la modal pour les historiques d'appels
  saveIsDisplayedHistoryCallModal: PropTypes.func.isRequired,
  // identifiant de l'historique correspondant a cet appel
  historyId: PropTypes.string.isRequired,
  // client Genapi cf Clients
  clientId: PropTypes.number.isRequired,
  // liste des clients present sur le CRM du client
  clients: PropTypes.arrayOf(
    PropTypes.exact({
      denomination: PropTypes.string.isRequired,
      email: PropTypes.string,
      id: PropTypes.number.isRequired,
      telephones: PropTypes.arrayOf(
        PropTypes.exact({
          typeTelephone: PropTypes.string.isRequired,
          numero: PropTypes.string.isRequired,
        }),
      ),
      tenantId: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  // indique si l'historique est urgent, a rappeler ou normal
  historyCallPriority: PropTypes.number.isRequired,
  // liste des utilisateurs wazo
  users: PropTypes.arrayOf(
    PropTypes.exact({
      uuid: PropTypes.string.isRequired,
      // email de l'utilisateur
      email: PropTypes.string,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      // numéros de ligne appartenant à l'utilisateur
      lines: PropTypes.arrayOf(
        PropTypes.string.isRequired,
      ).isRequired,
      // si la licence CTI est activé
      isActive: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
}

export default Call
