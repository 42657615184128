export const CONNECT_WEBSOCKET_FIREBASE_CALLS = 'CONNECT_WEBSOCKET_FIREBASE_CALLS';
export const CONNECT_WEBSOCKET_FIREBASE_CALLS_BY_HISTORY_CALLS = 'CONNECT_WEBSOCKET_FIREBASE_CALLS_BY_HISTORY_CALLS'
export const CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALLS = 'CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALLS';
export const CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID = 'CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID';
export const CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_MESSAGES = 'CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_MESSAGES';

export const connectWebsocketFirebaseCalls = (userUuid) => ({
  type: CONNECT_WEBSOCKET_FIREBASE_CALLS,
  userUuid,
})

export const connectWebsocketFirebaseCallsByHistoryCalls = (historyCalls) => ({
  type: CONNECT_WEBSOCKET_FIREBASE_CALLS_BY_HISTORY_CALLS,
  historyCalls,
})

export const connectWebsocketFirebaseHistoryCalls = (historyCalls) => ({
  type: CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALLS,
  historyCalls,
})

export const connectWebsocketFirebaseHistoryCallCollaboratorUuid = (userUuid) => ({
  type: CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_COLLABORATOR_UUID,
  userUuid,
})

export const connectWebsocketFirebaseHistoryCallMessages = (historyCallIds) => ({
  type: CONNECT_WEBSOCKET_FIREBASE_HISTORY_CALL_MESSAGES,
  historyCallIds,
})
