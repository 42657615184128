import React from 'react'
import PropTypes from 'prop-types'
import './loader.css'
// Materia
import { Box, Typography, CircularProgress} from '@mui/material';

const Loader = ({ size, message, top, color }) => (
  <Box className="loader" sx={{ marginTop: top, pb: 2 }}>
    { message && (
      <Typography variant="h4" className="loader_message" sx={{ p: 0}}>
        { message }
      </Typography>
    )}
    <CircularProgress size={size} sx={{ p: 1, color }}/>
  </Box>
)

Loader.propTypes = {
  // Taille de l'element
  size: PropTypes.number.isRequired,
  // permet d'afficher un message de chargement en plus du loader
  message: PropTypes.string,
  // changer la couleur du logo (default: primary)
  color: PropTypes.string,
  // decale le loader depuis le top
  top: PropTypes.string,
}

export default Loader