import { connect } from "react-redux";
import HistoryCallClientDenomination from "../components/HistoryCallClientDenomination";
// actions
import { 
  fetchFullClient,
} from "../redux/clients/actionClients";

const mapStateToProps = (state) => ({
  calls: state.calls.calls,
  callsByCollaboratorUuid: state.calls.callsByCollaboratorUuid,
  callsByClientId: state.calls.callsByClientId,
  fullClients: state.clients.fullClients,
  users: state.users.users,
  isFetchingFullClient: state.clients.isFetchingFullClient,
})

const mapDispatchToProps = (dispatch) => ({
  fetchFullClient: (clientId) => dispatch(fetchFullClient(clientId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCallClientDenomination)