// actions
import {
  FETCH_IS_CONNECTED_TO_ODOO,
  saveIsConnectedToOdoo,
  saveIsConnectingToOdoo,
} from "./actionWazoOdoo";

const middlewareWazoOdoo = (store) => (next) => (action) => {
  
  const handleErrors = (tryName, error) => {
    console.log(`Oups ! Une erreur inattendue s'est produite : ${tryName} ~ ${error.message} ~ ${error.status}`, 'error');
  }

  switch (action.type) {
    case FETCH_IS_CONNECTED_TO_ODOO:
      store.dispatch(saveIsConnectingToOdoo(true));

      (async () => {
        try {
          let token = store.getState().login.authToken;
          // Options for the fetch request
          const options = {
            method: 'GET',
            headers: {
              'accept': 'application/ld+json',
              'Content-Type': 'application/ld+json', // Specify the content type as JSON
              'Authorization': 'Bearer ' + token,
            },
          };
  
          const urlParams = new URLSearchParams({
            email: action.email,
            wazoUserUuid: action.uuid,
          });
  
          const rawIsConnected = await fetch(`https://${process.env.REACT_APP_API_SERVER}/isConnectedToOdoo?${urlParams}`, options);


          if (404 === rawIsConnected.status) {
            const errorJson = await rawIsConnected.json();
            const error = new Error();
            
            error.message = errorJson['detail'];
            error.status = errorJson.status;

            throw error;
          }
          
          const isConnectedJson = await rawIsConnected.json();
          const isConnected = isConnectedJson['hydra:member']?.[0]?.['isConnected'];

          if (isConnected) {
            store.dispatch(saveIsConnectedToOdoo(true));
          }
        } catch (error) {
          handleErrors(FETCH_IS_CONNECTED_TO_ODOO, error);
          store.dispatch(saveIsConnectedToOdoo(false));
        } finally {
          store.dispatch(saveIsConnectingToOdoo(false));
        }
      })()

      next(action)
      break


    default: next(action)
  }
}

export default middlewareWazoOdoo;