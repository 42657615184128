import { connect } from "react-redux";
import CallsEnded from "../components/CallsEnded";
// actions
import { fetchCalls, fetchMoreCalls, searchCalls, deleteCalls, addMoreCallPeriod } from "../redux/calls/actionCalls";

const mapStateToProps = (state) => ({
    calls: state.calls.calls,
    isFetchingCalls: state.calls.isFetchingCalls,
    filters: state.calls.filters,
})

const mapDispatchToProps = (dispatch) => ({
    fetchCalls: () => dispatch(fetchCalls()),
    fetchMoreCalls: () => dispatch(fetchMoreCalls()),
    searchCalls: (loadMore) => dispatch(searchCalls(loadMore)),
    deleteCalls: () => dispatch(deleteCalls()),
    addMoreCallPeriod: (currentPageName) => dispatch(addMoreCallPeriod(currentPageName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CallsEnded)