export const testCalls = [
  {
      "startTime": "11:54:58",
      "historyId": "1706525697.1366",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "username": "Sophyane Tameldou",
      "date": {
          "seconds": 1706525701,
          "nanoseconds": 81000000
      },
      "heldStartTime": "0",
      "duration": 3,
      "status": "call_ended",
      "id": "1706525698.1367",
      "userExtension": "209",
      "clientId": 20021883,
      "callNumber": "0628558963",
      "direction": "inbound"
  },
  {
      "status": "call_ended",
      "userExtension": "209",
      "date": {
          "seconds": 1706518694,
          "nanoseconds": 490000000
      },
      "username": "Sophyane Tameldou",
      "clientId": 20021934,
      "historyId": "1706518656.574",
      "startTime": "09:58:09",
      "callNumber": "0660237869",
      "direction": "internal",
      "heldStartTime": "0",
      "duration": 10,
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "id": "1706518691.584"
  },
  {
      "id": "1706518380.551",
      "clientId": 0,
      "heldStartTime": "0",
      "callNumber": "310",
      "date": {
          "seconds": 1706518387,
          "nanoseconds": 263000000
      },
      "direction": "internal",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "username": "Sophyane Tameldou",
      "historyId": "1706518380.550",
      "status": "call_ended",
      "duration": 61,
      "userExtension": "209",
      "startTime": "09:52:58"
  },
  {
      "id": "1706518328.546",
      "historyId": "1706518328.545",
      "userExtension": "209",
      "date": {
          "seconds": 1706518334,
          "nanoseconds": 34000000
      },
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "callNumber": "310",
      "username": "Sophyane Tameldou",
      "clientId": 0,
      "direction": "internal",
      "duration": 27,
      "heldStartTime": "09:52:20",
      "startTime": "09:52:06",
      "status": "call_ended"
  },
  {
      "callNumber": "0660237869",
      "duration": 17,
      "date": {
          "seconds": 1706518249,
          "nanoseconds": 51000000
      },
      "startTime": "09:50:43",
      "username": "Sophyane Tameldou",
      "userExtension": "209",
      "status": "call_ended",
      "historyId": "1706518227.486",
      "direction": "outbound",
      "heldStartTime": "0",
      "id": "1706518245.512",
      "clientId": 20021934,
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c"
  },
  {
      "clientId": 20021883,
      "historyId": "1706178262.8224",
      "userExtension": "209",
      "status": "call_ended",
      "direction": "inbound",
      "date": {
          "seconds": 1706178266,
          "nanoseconds": 630000000
      },
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "callNumber": "0628558963",
      "startTime": "11:24:22",
      "duration": 2,
      "id": "1706178263.8225",
      "username": "Sophyane Tameldou",
      "heldStartTime": "0"
  },
  {
      "id": "1706178236.8222",
      "heldStartTime": "0",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "callNumber": "0628558963",
      "startTime": "11:24:02",
      "historyId": "1706178236.8222",
      "duration": 15,
      "clientId": 20021883,
      "username": "Sophyane Tameldou",
      "status": "call_ended",
      "direction": "outbound",
      "date": {
          "seconds": 1706178243,
          "nanoseconds": 165000000
      },
      "userExtension": "209"
  },
  {
      "callNumber": "331",
      "historyId": "1706178124.8218",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "status": "call_ended",
      "duration": 45,
      "heldStartTime": "11:22:21",
      "startTime": "11:22:09",
      "userExtension": "209",
      "id": "1706178125.8219",
      "username": "Sophyane Tameldou",
      "direction": "internal",
      "date": {
          "seconds": 1706178130,
          "nanoseconds": 702000000
      },
      "clientId": 0
  },
  {
      "duration": 28,
      "clientId": 20021883,
      "status": "call_ended",
      "historyId": "1706177787.8178",
      "userExtension": "209",
      "callNumber": "0628558963",
      "heldStartTime": "0",
      "startTime": "11:16:50",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "direction": "outbound",
      "date": {
          "seconds": 1706177811,
          "nanoseconds": 17000000
      },
      "id": "1706177805.8180",
      "username": "Sophyane Tameldou"
  },
  {
      "clientId": 20021934,
      "startTime": "08:18:11",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "heldStartTime": "0",
      "username": "Sophyane Tameldou",
      "callNumber": "0660237869",
      "status": "call_ended",
      "id": "1706167090.7415",
      "duration": 5,
      "userExtension": "209",
      "historyId": "1706167075.7409",
      "direction": "internal",
      "date": {
          "seconds": 1706167092,
          "nanoseconds": 821000000
      }
  },
  {
      "userExtension": "209",
      "date": {
          "seconds": 1705910912,
          "nanoseconds": 492000000
      },
      "callNumber": "0660237869",
      "id": "1705910903.186",
      "duration": 1,
      "direction": "outbound",
      "historyId": "1705910903.186",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "username": "Sophyane Tameldou",
      "status": "call_ended",
      "startTime": "09:08:31",
      "clientId": 20022159,
      "heldStartTime": "0"
  },
  {
      "direction": "outbound",
      "heldStartTime": "0",
      "callNumber": "0660237869",
      "username": "Sophyane Tameldou",
      "startTime": "09:07:59",
      "date": {
          "seconds": 1705910880,
          "nanoseconds": 207000000
      },
      "id": "1705910869.183",
      "clientId": 20021934,
      "userExtension": "209",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "status": "call_ended",
      "duration": 1,
      "historyId": "1705910869.183"
  },
  {
      "direction": "outbound",
      "id": "1705910792.181",
      "callNumber": "0628558963",
      "date": {
          "seconds": 1705910798,
          "nanoseconds": 56000000
      },
      "heldStartTime": "0",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "duration": 15,
      "clientId": 20021934,
      "historyId": "1705910792.181",
      "username": "Sophyane Tameldou",
      "status": "call_ended",
      "userExtension": "209",
      "startTime": "09:06:37"
  },
  {
      "status": "call_ended",
      "direction": "outbound",
      "callNumber": "0628558963",
      "id": "1705910614.177",
      "startTime": "09:03:38",
      "clientId": 20021883,
      "historyId": "1705910614.177",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "date": {
          "seconds": 1705910619,
          "nanoseconds": 352000000
      },
      "username": "Sophyane Tameldou",
      "duration": 5,
      "userExtension": "209",
      "heldStartTime": "0"
  },
  {
      "clientId": 20021883,
      "startTime": "09:02:26",
      "id": "1705910538.151",
      "heldStartTime": "0",
      "status": "call_ended",
      "callNumber": "0628558963",
      "userExtension": "209",
      "duration": 11,
      "historyId": "1705910538.151",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "date": {
          "seconds": 1705910547,
          "nanoseconds": 243000000
      },
      "direction": "outbound",
      "username": "Sophyane Tameldou"
  },
  {
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "username": "Sophyane Tameldou",
      "id": "1705910506.150",
      "historyId": "1705910505.149",
      "duration": 5,
      "callNumber": "0628558963",
      "clientId": 20021883,
      "date": {
          "seconds": 1705910508,
          "nanoseconds": 834000000
      },
      "status": "call_ended",
      "direction": "inbound",
      "userExtension": "209",
      "heldStartTime": "0",
      "startTime": "09:01:47"
  },
  {
      "heldStartTime": "0",
      "startTime": "09:01:16",
      "status": "call_ended",
      "clientId": 20021883,
      "id": "1705910474.146",
      "userExtension": "209",
      "username": "Sophyane Tameldou",
      "callNumber": "0628558963",
      "direction": "inbound",
      "duration": 7,
      "date": {
          "seconds": 1705910477,
          "nanoseconds": 146000000
      },
      "historyId": "1705910473.145",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c"
  },
  {
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "username": "0186905510",
      "callNumber": "0442469999",
      "startTime": "11:34:30",
      "duration": 494,
      "id": "1703673272.1666",
      "date": {
          "seconds": 1703673274,
          "nanoseconds": 0
      },
      "direction": "outbound",
      "status": "call_ended",
      "userExtension": "0186905510",
      "historyId": "1703673272.1666",
      "clientId": 20020084,
      "heldStartTime": "0"
  },
  {
      "callNumber": "0628558963",
      "heldStartTime": "0",
      "historyId": "1702651662.11632",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "id": "1702651663.11633",
      "direction": "inbound",
      "userExtension": "209",
      "duration": 0,
      "status": "call_ended",
      "startTime": "15:47:43",
      "clientId": 20021934,
      "date": {
          "nanoseconds": 861000000,
          "seconds": 1702651663
      },
      "username": "Sophyane Tameldou"
  },
  {
      "direction": "outbound",
      "userId": "54fcb554-576e-4f94-94ba-af695857b45c",
      "duration": 6,
      "startTime": "14:13:14",
      "date": {
          "nanoseconds": 420000000,
          "seconds": 1702386798
      },
      "userExtension": "0186905510",
      "username": "0186905510",
      "callNumber": "0628558963",
      "heldStartTime": "0",
      "clientId": 20022159,
      "id": "1702386793.4408",
      "historyId": "1702386793.4408",
      "status": "call_ended"
  }
]