import {
  SAVE_ACTIVITIES,
  SAVE_ACTIVITY,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY,
  SAVE_IS_DISPLAYED_ACTIVITY_MODAL,
  SAVE_IS_FETCHING_ACTIVITIES,
} from "./actionActivities";

/** Priorité de l'activité' */
export const ACTIVITY_NORMAL_PRIORITY = '0';
export const ACTIVITY_MODERATE_PRIORITY = '1';
export const ACTIVITY_URGENT_PRIORITY = '2';
export const ACTIVITY_PLAN_PRIORITY = '3';

/** status de l'activité */
// export const ACTIVITY_STATUS_DEFAULT = -1;
// export const ACTIVITY_STATUS_OPENED = 0;
// export const ACTIVITY_STATUS_CLOSED = 1;

/** Objet de l'activité' */
export const ACTIVITY_DEFAULT_OBJECT = '0';
export const ACTIVITY_MISSED_OBJECT = '1';
export const ACTIVITY_FINANCIAL_OBJECT = '2';
export const ACTIVITY_PERSONAL_OBJECT = '3';
export const ACTIVITY_APPOINTMENT_OBJECT = '4';
export const ACTIVITY_INFORMATION_OBJECT = '5';

const initialState = {
  activities: [],
  isFetchingActivities: false,
  isFetchingActivitiesError: false,
  isDisplayedActivityModal: false,
}

const reducerActivities = (state = initialState, action) => {
  switch (action.type) {

    // Sauvegarde des activités dans le state
    case SAVE_ACTIVITIES: {
      return {
        ...state,
        activities: action.activities,
      }
    }

    // Sauvegarde d'une activité
    case SAVE_ACTIVITY: {
      return {
        ...state,
        activities: [
          ...state.activities,
          action.activity,
        ]
      }
    }

    case UPDATE_ACTIVITY:
      const newActivities = state.activities.reduce(
        (accumulator, currentActivity) => {
          // s'il s'agit de l'activité à update
          if (action.activity.id === currentActivity.id) {
            // recupère le calls en construction et met à jour ce call
            return [...accumulator, { ...currentActivity, ...action.activity }]
          }
          // sinon continue de contruire calls
          return [...accumulator, currentActivity]
        },
        // lors de la 1ere itération le calls en contruction est un tableau vide
        [],
      )

      return {
        ...state,
        activities: newActivities,
      }

    case DELETE_ACTIVITY:
      return {
        ...state,
        activities: state.activities.filter(({ id }) => id !== action.activity.id)
      }

    // Affichage de la modal activité
    case SAVE_IS_DISPLAYED_ACTIVITY_MODAL: {
      return {
        ...state,
        isDisplayedActivityModal: {
          ...state.isDisplayedActivityModal,
          [action.callId]: action.isDisplayedActivityModal,
        },
      }
    }

    // Chargement des activités
    case SAVE_IS_FETCHING_ACTIVITIES: {
      return {
        ...state,
        isFetchingActivities: action.isFetchingActivities,
      }
    }
    default: return state
  }
}
export default reducerActivities;