import { legacy_createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 

// reducers
import reducerActivities from './activities/reducerActivities';
import reducerLogin from './login/reducerLogin';
import reducerCalls from './calls/reducerCalls';
import reducerTabs from './tabs/reducerTabs';
import reducerUsers from './users/reducerUsers';
import reducerWebsocketWazo from './websocketWazo/reducerWebsocketWazo';
import reducerContacts from './contacts/reducerContacts';
import reducerWazoOdoo from './wazoOdoo/reducerWazoOdoo';

// middlewares
import middlewareActivities from './activities/middlewareActivities';
import middlewareLogin from './login/middlewareLogin';
import middlewareCalls from './calls/middlewareCalls';
import middlewareUsers from './users/middlewareUsers'
import middlewareContacts from './contacts/middlewareContacts';
import middlewareWazoOdoo from './wazoOdoo/middlewareWazoOdoo';

const enhancers = composeWithDevTools(
  applyMiddleware(
    middlewareActivities,
    middlewareLogin,
    middlewareCalls,
    middlewareUsers,
    middlewareContacts,
    middlewareWazoOdoo,
  ),
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'tabs',
    'updateApp',
  ],
  blacklist: [
    'activities',
    'login',
    'calls',
    'users',
    'websocketWazo',
    'middlewareWazoOdoo',
  ]
}

const activitiesPersistConfig = {
  key: 'activities',
  storage: storage,
  blacklist: [
    'isFetchingActivities',
    'activities'
  ],
}

const contactsPersistConfig = {
  key: 'contacts',
  storage: storage,
  blacklist: [
    'isFetchingContacts',
    'contacts'
  ],
}

const callsPersistConfig = {
  key: 'calls',
  storage: storage,
  blacklist: [
    'isFetchingCalls',
    'isFetchingMoreCalls',
    'calls',
  ],
}

const usersPersistConfig = {
  key: 'users',
  storage: storage,
  blacklist: [
    'isFetchingUsers',
  ],
}

const loginPersistConfig = {
  key: 'login',
  storage: storage,
  blacklist: [
    'isConnectingToWazoApp',
    'isPageLoading',
    'isCheckingWazoToken',
    'isConnectingWithContext',
    'isDisconnectWazoApp',
    'isConnected',
  ],
}

const websocketWazoConfig = {
  key: 'websocketWazo',
  storage: storage,
  blacklist: [
    'isConnectingWebsocket',
  ],
}

const wazoOdooConfig = {
  key: 'wazoOdoo',
  storage: storage,
  blacklist: [
    'isConnectedToOdoo'
  ],
}

const rootReducer = combineReducers({
  activities: persistReducer(activitiesPersistConfig, reducerActivities),
  contacts: persistReducer(contactsPersistConfig, reducerContacts),
  login: persistReducer(loginPersistConfig, reducerLogin),
  calls: persistReducer(callsPersistConfig, reducerCalls),
  tabs: reducerTabs,
  users: persistReducer(usersPersistConfig, reducerUsers),
  websocketWazo: persistReducer(websocketWazoConfig, reducerWebsocketWazo),
  wazoOdoo: persistReducer(wazoOdooConfig, reducerWazoOdoo),
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = legacy_createStore(persistedReducer, enhancers);
export const persistor = persistStore(store)
