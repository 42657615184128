import React from 'react'
import PropTypes from 'prop-types'

// icons
import EuroIcon from '@mui/icons-material/Euro';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// CONST
import {
  ACTIVITY_DEFAULT_OBJECT,
  ACTIVITY_MISSED_OBJECT,
  ACTIVITY_FINANCIAL_OBJECT,
  ACTIVITY_PERSONAL_OBJECT,
  ACTIVITY_APPOINTMENT_OBJECT,
  ACTIVITY_INFORMATION_OBJECT,
} from '../redux/activities/reducerActivities'

const HistoryCallObjectIcon = ({ iconeName, sx }) => {
  const componentsMap = {
    [ACTIVITY_FINANCIAL_OBJECT]:
      { 'icon': EuroIcon }
    ,
    [ACTIVITY_PERSONAL_OBJECT]:
      { 'icon': AccountCircleOutlinedIcon }
    ,
    [ACTIVITY_APPOINTMENT_OBJECT]:
      { 'icon': CalendarMonthOutlinedIcon }
    ,
    [ACTIVITY_INFORMATION_OBJECT]:
      { 'icon': InfoOutlinedIcon }
    ,
    [ACTIVITY_DEFAULT_OBJECT]:
      { 'icon': HelpOutlineOutlinedIcon }
    ,
    [ACTIVITY_DEFAULT_OBJECT]:
      { 'icon': MoreHorizIcon }
    ,
    [ACTIVITY_MISSED_OBJECT]:
      { 'icon': CallMissedIcon }
    ,
  }
  const Icon = componentsMap[iconeName]?.icon;

  return (
    <>
      {Icon && <Icon sx={{ ...sx, width: 'empty' !== iconeName ? 'inherit' : ['24px', '24px', 0] }} />}
    </>
  )
}

HistoryCallObjectIcon.defaultProps = {
  iconeName: 'empty',
};

HistoryCallObjectIcon.propTypes = {
  // nom de l'icone
  iconeName: PropTypes.string.isRequired,
  // style de l'icone
  sx: PropTypes.object.isRequired,
}

export default HistoryCallObjectIcon