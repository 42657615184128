import React from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Box,
  Grid,
} from "@mui/material";

// Icon
import ContactPageIcon from '@mui/icons-material/ContactPage';
import FolderIcon from '@mui/icons-material/Folder';

// Styles
import {
  StyledTypo,
  StyledInlinePaddingGrid,
} from "../styles/ClientsStyle";

const ClientFolderBody = ({ folder }) => (
  <>
    {(folder.id)
      ? (
        <Box sx={{ mb: 2 }}>
          <Box display='flex' alignItems='center'>
            <FolderIcon sx={{ color: 'secondary.light' }} />
            <Typography variant="h2" color='primary.light'>Informations principales</Typography>
          </Box>
          <Grid
            direction="row"
            justifyContent="space-between"
            container
          >
            {/* Type */}
            {
              folder.typeNature ?
                <StyledInlinePaddingGrid item xs={12} lg={6}>
                  <StyledTypo variant="body1" color={'primary'}>Type :</StyledTypo>
                  <StyledTypo variant="body1" color={'secondary'}>{folder.typeNature}</StyledTypo>
                </StyledInlinePaddingGrid>
                : null
            }
            {/* Etat dossier */}
            {
              folder.valeurMetierEtat?.intitule ?
                <StyledInlinePaddingGrid item xs={12} lg={6}>
                  <StyledTypo variant="body1" color={'primary'}>État :</StyledTypo>
                  <StyledTypo variant="body1" color={'secondary'}>{folder.valeurMetierEtat?.intitule}</StyledTypo>
                </StyledInlinePaddingGrid>
                : null
            }
            {/* Montant Previsionnel */}
            {
              folder.montantPrevisionnel ?
                <StyledInlinePaddingGrid item xs={12} lg={6}>
                  <StyledTypo variant="body1" color={'primary'}>Montant prévisionnel :</StyledTypo>
                  <StyledTypo variant="body1" color={'secondary'}>{folder.montantPrevisionnel}</StyledTypo>
                </StyledInlinePaddingGrid>
                : null
            }
            {/* Service */}
            {
              folder.service ?
                <StyledInlinePaddingGrid item xs={12} lg={6}>
                  <StyledTypo variant="body1" color={'primary'}>Service :</StyledTypo>
                  <StyledTypo variant="body1" color={'secondary'}>{folder.service.intitule}</StyledTypo>
                </StyledInlinePaddingGrid>
                : null
            }
          </Grid>
        </Box>
      )
      : (
        null
      )
    }

    {/* Intervenants */}
    {(folder.notaire || folder.clerc || folder.clercSecondaire || folder.secretaire)
      ? (
        <Box>
          <Box display='flex' alignItems='center'>
            <ContactPageIcon sx={{ color: 'secondary.light' }} />
            <Typography variant="h2" color='primary.light'>Intervenants</Typography>
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
          >
            {/* Notaire */}
            {
              folder.notaire ?
                <StyledInlinePaddingGrid item xs={12} lg={6}>
                  <StyledTypo variant="body1" color={'primary'}>Notaire :</StyledTypo>
                  <StyledTypo variant="body1" color={'secondary'}>{folder.notaire.identite} ({folder.notaire.initiales})</StyledTypo>
                </StyledInlinePaddingGrid>
                : null
            }
            {/* Clerc */}
            {
              folder.clerc ?
                <StyledInlinePaddingGrid item xs={12} lg={6}>
                  <StyledTypo variant="body1" color={'primary'}>Clerc :</StyledTypo>
                  <StyledTypo variant="body1" color={'secondary'}>{folder.clerc.identite}</StyledTypo>
                </StyledInlinePaddingGrid>
                : null
            }
            {/* Clerc Secondaire */}
            {
              folder.clercSecondaire ?
                <StyledInlinePaddingGrid item xs={12} lg={6}>
                  <StyledTypo variant="body1" color={'primary'}>Clerc secondaire :</StyledTypo>
                  <StyledTypo variant="body1" color={'secondary'}>{folder.clercSecondaire.identite}</StyledTypo>
                </StyledInlinePaddingGrid>
                : null
            }
            {/* Secretaire */}
            {
              folder.secretaire ?
                <StyledInlinePaddingGrid item xs={12} lg={6}>
                  <StyledTypo variant="body1" color={'primary'}>Secretaire :</StyledTypo>
                  <StyledTypo variant="body1" color={'secondary'}>{folder.secretaire.identite}</StyledTypo>
                </StyledInlinePaddingGrid>
                : null
            }
          </Grid>
        </Box>
      ) : (
        null
      )
    }
  </>
)

ClientFolderBody.propTypes = {
  folder: PropTypes.exact({
    actions: PropTypes.arrayOf(
      PropTypes.exact({
        nom: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        method: PropTypes.string.isRequired,
        titre: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        schemaUrl: PropTypes.string.isRequired,
      }),
    ),
    clerc: PropTypes.exact({
      id: PropTypes.number.isRequired,
      identite: PropTypes.string.isRequired,
      initiales: PropTypes.string.isRequired,
    }),
    clercSecondaire: PropTypes.exact({
      id: PropTypes.number.isRequired,
      identite: PropTypes.string.isRequired,
      initiales: PropTypes.string.isRequired,
    }),
    clientId: PropTypes.number.isRequired,
    dateCreation: PropTypes.node.isRequired,
    dateDernierAcces: PropTypes.node,
    dateModification: PropTypes.node.isRequired,
    dateOuvertureDossier: PropTypes.node.isRequired,
    datePrevisionnelleSignature: PropTypes.node,
    id: PropTypes.number.isRequired,
    idDossierParent: PropTypes.number.isRequired,
    intitule: PropTypes.string.isRequired,
    liens: PropTypes.arrayOf(
      PropTypes.exact({
        href: PropTypes.string.isRequired,
        relation: PropTypes.string.isRequired,
        verbe: PropTypes.string.isRequired,
      }),
    ),
    montantPrevisionnel: PropTypes.number,
    nature: PropTypes.string.isRequired,
    nom: PropTypes.string.isRequired,
    notaire: PropTypes.exact({
      id: PropTypes.number.isRequired,
      identite: PropTypes.string.isRequired,
      initiales: PropTypes.string.isRequired,
    }),
    numero: PropTypes.string.isRequired,
    secretaire: PropTypes.exact({
      id: PropTypes.number.isRequired,
      identite: PropTypes.string.isRequired,
      initiales: PropTypes.string.isRequired,
    }),
    service: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
    typeNature: PropTypes.string.isRequired,
    valeurMetierEtat: PropTypes.exact({
      id: PropTypes.number.isRequired,
      intitule: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default ClientFolderBody