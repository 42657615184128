import { connect } from "react-redux";
import HistoryCallModalSelectFolder from "../components/HistoryCallModalSelectFolder";
// actions
import { fetchFolders } from "../redux/clients/actionClients";

const mapStateToProps = (state) => ({
  folders: state.clients.folders,
  isFetchingFolders: state.clients.isFetchingFolders,
})

const mapDispatchToProps = (dispatch) => ({
  fetchFolders: (clientId) => dispatch(fetchFolders(clientId))
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCallModalSelectFolder)