/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'

// Components
import Loader from './Loader';
// containers
// import CustomSnackbarContainer from '../containers/CustomSnackbarContainer';
import TabsPanelContainer from '../containers/TabsPanelContainer';
import TabsContainer from '../containers/TabsContainer';

// img
import logoAveliaBlanc from '../img/logo-avelia-blanc.png'

// MUI
import {
  Container,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
// Icons
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';

// Styled components
import {
  StyledImageBox,
} from "../styles/LandingStyle"
import { useTheme } from '@mui/material/styles';

// const
import {
  PAGE_LOGIN,
} from '../pages';
import WazoOdooLinkDialogContainer from '../containers/WazoOdooLinkDialogContainer';

const Landing = ({
  users,
  isAuthenticationLoading,
  isConnected,
  isConnectingWebsocket,
  isFetchingUsers,
  isDisconnecting,
  userEmail,
  fetchUsers,
  logOut,
  saveIsDisplayedWazoOdooModal,
  isConnectedToOdoo,
  isFetchingToken,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  // présent également
  // TODO : si la deconnexion provient du middleware via CONNECT_TO_WAZO_APP.app.logout() l'affichage ne sera pas mis à jour et le loader ne s'affichera pas
  const isLoaderOn = isFetchingUsers || isConnectingWebsocket || isDisconnecting || !isConnected || isFetchingToken;

  // Logout
  const handleLogout = useCallback((e) => {
    // le logout ne fonctionne que si la touche ctrl est utilisée
    if(e.ctrlKey && !isAuthenticationLoading){
      logOut();
      navigate(PAGE_LOGIN);
    }
  }, [navigate, logOut])

  // récupère les utilisateurs wazo
  useEffect(() => {
    if (isConnected && users.length === 0 && !isDisconnecting) {
      fetchUsers();
    }
  }, [fetchUsers, users.length, isConnected])

  return (
    <Container maxWidth="xxl" sx={{
      m: 0,
      px: '0!important',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    }}>
      {/* <CustomSnackbarContainer /> */}
      {/* Header */}
      <Box sx={{
        borderColor: 'divider',
        background: `linear-gradient(to top, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
        textAlign: 'center',
        color: 'secondary',
      }}>
        <Box sx={{ position: "relative", p: 3 }}>
          {/* logo avelia */}
          <StyledImageBox component="img" alt="Logo Avelia" src={logoAveliaBlanc} />
          {/* user email and hide logout */}
          <Box
            sx={{
              position: 'absolute',
              top: ['5px', '12px'],
              right: ['5px', '12px'],
              cursor: 'default',
              lineHeight: '1rem',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={(e) => handleLogout(e)}
          >
            <IconButton
              onClick={() => saveIsDisplayedWazoOdooModal(true)}
              aria-label="connectToOdoo"
              title='connexion à Odoo'
              sx={{
                marginRight: ['0.1rem', '0.1rem', '0.25rem'],
                padding: ['0.25rem', '0.5rem'],
                '&:hover': {
                  borderRadius: '100%',
                  background: "#FFFFFF30",
                }
              }}
            >
              { isConnectedToOdoo 
                ? <WifiIcon 
                    sx={{ 
                      color : "common.white",
                    }}
                  /> 
                : <WifiOffIcon 
                    sx={{ 
                      color : "red",
                    }}
                  /> 
              }
            </IconButton>
            <Typography
              color="common.white"
            >
              {userEmail}
            </Typography>
          </Box>

        </Box>
        {/* onglet de navigation */}
        {!isLoaderOn && (
          <TabsContainer />
        )}
      </Box>
      {/* Main */}
      <Box sx={{
        backgroundColor: 'bg.main',
        overflow: 'hidden',
        paddingBottom: '2rem',
        height: '100%',
      }}>
        <>
          {/* contenu de l'onglet de navigation */}
          {!isLoaderOn
            ? (
              <TabsPanelContainer />
            )
            :
            (
              <Loader size={200} color="secondary" />
            )
          }
        </>
      </Box>
      {/* footer */}
      <Box sx={{
        backgroundColor: 'bg.main',
        position: 'absolute',
        bottom: 0,
        right: '21px',
      }}>
        <Typography sx={{
          textAlign: 'right',
          color: 'bg.dark',
          display: ['none', 'inherit'],
          '&:hover': {
            color: 'primary.light',
          },
        }}>
          {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
      <WazoOdooLinkDialogContainer />
    </Container>
  )
}

Landing.propTypes = {
  // contient la liste des historique attribué a l'utilisateur connecté
  // openCallsByCollaboratorUuid: PropTypes.array.isRequired,
  // liste des utilisateurs wazo
  users: PropTypes.arrayOf(
    PropTypes.exact({
      uuid: PropTypes.string.isRequired,
      // email de l'utilisateur
      email: PropTypes.string,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      // numéros de ligne appartenant à l'utilisateur
      lines: PropTypes.arrayOf(
        PropTypes.string.isRequired,
      ).isRequired,
      // si la licence CTI est activé
      isActive: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  // si l'authentification est en cours
  isAuthenticationLoading: PropTypes.bool.isRequired,
  // si l'utilisateur est connecté
  isConnected: PropTypes.bool.isRequired,
  // indique si le processus de connection aux websocket est en cours
  isConnectingWebsocket: PropTypes.bool.isRequired,
  // si les Calls sont en cours de chargements
  // isFetchingCalls: PropTypes.bool.isRequired,
  // si les Users sont en cours de chargements
  isFetchingUsers: PropTypes.bool.isRequired,
  // si les Clients sont en cours de chargements
  // isFetchingClients: PropTypes.bool.isRequired,
  // si la deconnexion est en cours
  isDisconnecting: PropTypes.bool.isRequired,
  // email de l'utilisateur connecté
  userEmail: PropTypes.string.isRequired,
  // permet de changer d'onglet
  // saveTabIndex: PropTypes.func.isRequired,
  // liste des utilisateurs Wazo
  fetchUsers: PropTypes.func.isRequired,
  // telecharge les appels depuis firestore
  // fetchCalls: PropTypes.func.isRequired,
  // liste des clients present sur le CRM du client
  // fetchClients: PropTypes.func.isRequired,
  // permet de lancer la deconnexion de l'application
  logOut: PropTypes.func.isRequired,
  // affiche la modal pour se connecter à Odoo
  saveIsDisplayedWazoOdooModal: PropTypes.func.isRequired,
  // indique si l'utilisateur est connecter à odoo
  isConnectedToOdoo: PropTypes.bool.isRequired,
  isFetchingToken: PropTypes.bool.isRequired,
}

export default Landing
