import React from 'react'
import PropTypes from 'prop-types'
// material
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  Box,
  TextField,
  Button,
  Popover,
  Typography,
} from '@mui/material';

const HistoryCallMessage = ({
  users,
  historyCallMessage,
  connectedUserUuid,
  removeHistoryCallMessage,
}) => {
  // get user
  const user = users.filter(({uuid}) => uuid === historyCallMessage.userUuid)[0]

  // format date and time
  const date = new Date(historyCallMessage.date.seconds*1000);
  const formatDateTime = date.toLocaleDateString("fr-Fr", {day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}).split(' ');
  const formatDate = formatDateTime[0];
  const formatTime = formatDateTime[1].replace(':', 'h');

  // Popover pour supprimer
  const [contextMenu, setContextMenu] = React.useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };
  const handleOnClickDeleteHistoryCallMessage = () => {
    removeHistoryCallMessage(historyCallMessage)
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      mb: 10,
      position: 'relative',
    }}>
      <AccountCircleIcon color={connectedUserUuid === historyCallMessage.userUuid ? 'primary' : 'secondary'} sx={{ mr: 2 }} />
      <TextField
        id="messageInput"
        label={
          <>
            <span style={{
              display: 'inline-block',
              marginRight: '8px',
              fontWeight: 'bold',
            }}>
              {user.firstname} {user.lastname}
            </span>
            {/* Date */}
            <span style={{
              transform: 'scale(0.9)',
              color: 'secondary.main'
            }}>
              { formatDate } à {formatTime}
            </span>
          </>
        }
        fullWidth
        readOnly
        multiline
        minRows={1}
        maxRows={4}
        value={historyCallMessage.message}
        InputProps={{
          readOnly: true,
        }}
      />
      { connectedUserUuid === historyCallMessage.userUuid && (
        <>
          <Button 
            sx={{
              color: 'primary.main',
              width: 'fit-content',
              p: 0,
              textDecoration: 'underline',
              textTransform: 'capitalize',
              position: 'absolute',
              bottom: '-22px',
              left: '36px',
            }}
            onClick={handleContextMenu}
            >
            Supprimer
          </Button>
          <Popover
            // id={id}
            open={contextMenu !== null}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            sx={{ boxShadow: 25 }}
          >
            <Box sx={{
              px: 4,
              py: 2,
              background: '#EEE',
              textAlign: 'center',
              boxShadow: 25,
            }}>
              <Typography sx={{ mb: 2 }}>La suppression d'un commentaire est définitive</Typography>
              <Button 
                size='small'
                variant='contained'
                color='error'
                sx={{ textAlign: 'center' }}
                onClick={handleOnClickDeleteHistoryCallMessage}
              >
                Supprimer
              </Button>
            </Box>
          </Popover>
        </>
      )}
    </Box>
  )
}

HistoryCallMessage.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.exact({
      uuid: PropTypes.string.isRequired,
      // email de l'utilisateur
      email: PropTypes.string,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      // numéros de ligne appartenant à l'utilisateur
      lines: PropTypes.arrayOf(
        PropTypes.string.isRequired
      ).isRequired,
      // si la licence CTI est activé
      isActive: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
}

export default HistoryCallMessage