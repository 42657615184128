// Materia
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

// Custom StyledPaper for calls
export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  margin: theme.spacing(2),
  borderLeft: '10px solid white',
  boxShadow: theme.shadows[3],
  transition: 'border 0.2s',
  minWidth: '400px',
  height: '100px',
  '&:hover': {
    cursor: 'pointer',
    borderLeft: '15px solid white',
    boxShadow: theme.shadows[10],
  },
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    fontSize: '18px',

  },
  [theme.breakpoints.up('md')]: {
    height: '39px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
}));


// Custom StyledPaper for calls without hover
export const StyledPaperWithoutHover = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  margin: theme.spacing(2),
  borderLeft: '10px solid white',
  boxShadow: theme.shadows[3],
  transition: 'border 0.2s',
  minWidth: '400px',
  height: '70px',
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    fontSize: '18px',
  },
  [theme.breakpoints.up('md')]: {
    height: '39px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
}));
