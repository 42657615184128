import { connect } from "react-redux";
import HistoryCallModal from "../components/HistoryCallModal";
// actions
import { 
  saveIsDisplayedHistoryCallModal,
  postHistoryCall,
  saveHistoryCall,
  deleteCallByCollaboratorUuid,
  updateHistoryCall,
} from "../redux/calls/actionCalls";
import {
  saveTabIndex,
} from "../redux/tabs/actionTabs";
import { saveSnackbar } from '../redux/snackbar/actionSnackbar';

const mapStateToProps = (state, ownProps) => ({
  isDisplayedHistoryCallModal: state.calls.isDisplayedHistoryCallModal?.[ownProps.callId] ?? false,
  historyCalls: state.calls.historyCalls,
  userUuid: state.login.uuid,
  historyCallMessages: state.calls.historyCallMessages?.[ownProps.id] ?? [],
  connectedUserUuid: state.login.uuid,
  // chercher l'appel dans calls.calls
  callStatus: state.calls.calls.filter(
    (call) => call.id === ownProps.callId
  )?.[0]?.status
  // sinon dans calls.callsByCollaboratorUuid
  ?? state.calls.callsByCollaboratorUuid.filter(
    (call) => call.id === ownProps.callId
  )?.[0]?.status
    // sinon dans calls.callsByClient
  ?? state.calls.callsByClientId.filter(
    (call) => call.id === ownProps.callId
  )?.[0]?.status
  // sinon affiche par defaut ''
  ?? '',
})

const mapDispatchToProps = (dispatch) => ({
  saveIsDisplayedHistoryCallModal: (callId, isDisplayedHistoryCallModal) => dispatch(saveIsDisplayedHistoryCallModal(callId, isDisplayedHistoryCallModal)),
  postHistoryCall: (historyCall) => dispatch(postHistoryCall(historyCall)),
  updateHistoryCall: (historyCall) => dispatch(updateHistoryCall(historyCall)),
  saveHistoryCall: (historyCall) => dispatch(saveHistoryCall(historyCall)),
  deleteCallByCollaboratorUuid: (call) => dispatch(deleteCallByCollaboratorUuid(call)),
  saveTabIndex: (currentPageName, tabIndex) => dispatch(saveTabIndex(currentPageName, tabIndex)),
  saveSnackbar: (message, variant) => dispatch(saveSnackbar(message, variant)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCallModal)