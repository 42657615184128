import { connect } from "react-redux";
import HistoryCallMessage from "../components/HistoryCallMessage";
// actions
import {
  removeHistoryCallMessage,
} from "../redux/calls/actionCalls";

const mapStateToProps = (state) => ({
  users: state.users.users,
  connectedUserUuid: state.login.uuid,
})

const mapDispatchToProps = (dispatch) => ({
  removeHistoryCallMessage: (historyCallMessage) => dispatch(removeHistoryCallMessage(historyCallMessage))
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCallMessage)
