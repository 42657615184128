
import PropTypes from 'prop-types'
import { useLocation, useParams } from "react-router-dom";
import { pages } from '../pages';
// material
import { Box } from '@mui/material';
// Styled Component
import {StyledTabsPanelBox} from '../styles/TabsPanelStyle';

const TabsPanel = ({ tabIndex }) => {
  const location = useLocation()
  const routeParams = useParams();
  const clientId = routeParams.clientId;
  const page = pages(clientId)[location.pathname];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box  
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        sx={{ 
          width: ['90%', '80%'],
        }}
        {...other}
      >
        {value === index && (
          children
        )}
      </Box>
    );
  }

  return (
    <StyledTabsPanelBox>
      {
        page.tabs.map((tab, index) => (
          <TabPanel value={tabIndex} index={index} key={index}>
            { tab.content }
          </TabPanel>
        ))
      }
    </StyledTabsPanelBox>
  )
}

TabsPanel.propTypes = {
  // indique sur quel onglet doivent se trouver les pages
  tabIndex: PropTypes.number.isRequired,
}

export default TabsPanel