// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Components
import Loader from './Loader'

// Styled component
import {
  StyledBoxWithBackground,
  StyledLogoAveliaBox,
  StyledHomeBox,
  StyledWelcomeBox,
  StyledFormBox,
  StyledBoxWithLogoAvelia,
  StyledBoxLogin,
} from '../styles/LoginStyle';

// MUI
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
} from '@mui/material';

// Icons
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// Img
import logoAveliaBlanc from '../img/logo-avelia-blanc.png'

// consts
import { PAGE_DEFAULT } from '../pages';

const Login = ({
  email,
  password,
  server,
  error,
  isConnected,
  isAuthenticationLoading,
  saveEmail,
  savePassword,
  saveServer,
  saveIsAuthenticationLoading,
  signInWazo,
  saveTabIndex,
}) => {
  // states precedements sauvegardés
  const navigate = useNavigate();

  // verifie si l'autocompletion du mot de passe et de nom d'utilisateur a été effectuée
  const [isMuiAutoFill, setIsMuiAutoFill] = useState(true)

  // si l'utilisateur est connecté affiche Landing
  useEffect(() => {
    if (isConnected) {
      saveTabIndex(PAGE_DEFAULT, 0);
      navigate(PAGE_DEFAULT);
    }
    return () => saveIsAuthenticationLoading(false)
  }, [isConnected, navigate, saveIsAuthenticationLoading, saveTabIndex])

  const handleOnClickSignIn = (event) => {
    event.preventDefault();
    signInWazo(email, password, server);
  };

  return (
    <>
      <StyledBoxLogin>
        {/* Background Image */}
        <StyledBoxWithBackground>
          <StyledLogoAveliaBox>
            {/* Logo Avelia */}
            <StyledBoxWithLogoAvelia component="img" alt="Logo Avelia" src={logoAveliaBlanc} />
          </StyledLogoAveliaBox>
        </StyledBoxWithBackground>
        {/* Right side  */}
        <StyledHomeBox className='home_login'>
          {/* Welcome message */}
          <StyledWelcomeBox className='welcome_login'>
            <Typography variant='h6' color="white">Bienvenue</Typography>
            <Typography variant='body1' color="white" sx={{ marginTop: "1rem" }}>
              Nous sommes ravis de vous accueillir sur notre plateforme.
            </Typography>
            <Typography variant='body1' color="white">
              AveliaLink vous permet de connecter facilement votre softphone à votre CRM afin d'améliorer l'efficacité de votre entreprise.
            </Typography>
            <Typography variant='body1' color="white" sx={{ marginTop: "1rem" }}>
              Pour plus d'informations vous pouvez nous contacter au 04 92 04 05 53.
            </Typography>
          </StyledWelcomeBox>
          {/* Login form */}
          <StyledFormBox className='form_login'>
            <Container component="main" >
              <CssBaseline />
              {/* Form title and icon */}
              <Box
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Se connecter
                </Typography>
                <Box component="form" onSubmit={handleOnClickSignIn} noValidate sx={{ mt: 1 }}>
                  {undefined !== error?.authentication && (
                    <Typography component="span" sx={{ "textAlign": "center" }} color="error">
                      {error.authentication}
                    </Typography>
                  )}
                  {/* Email Adress */}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Adresse e-mail"
                    name="email"
                    autoComplete="email"
                    onChange={(e) => saveEmail(e.currentTarget.value)}
                    value={email || ''}
                    autoFocus
                    // pour eviter l'overlaps entre muiAutofill et le placeholder
                    InputLabelProps={{ shrink: isMuiAutoFill || !!email }}
                    onAnimationStart={(e) => 'mui-auto-fill' === e.animationName ? setIsMuiAutoFill(true) : setIsMuiAutoFill(false)}
                    // error
                    error={undefined !== error?.email}
                    helperText={undefined !== error?.email ? error.email : ''}
                  />
                  {/* Password */}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => savePassword(e.currentTarget.value)}
                    value={password}
                    // pour eviter l'overlaps entre muiAutofill et le placeholder
                    InputLabelProps={{ shrink: isMuiAutoFill || !!password }}
                    onAnimationStart={(e) => 'mui-auto-fill' === e.animationName ? setIsMuiAutoFill(true) : setIsMuiAutoFill(false)}
                    // error
                    error={undefined !== error?.password}
                    helperText={undefined !== error?.password ? error.password : ''}
                  />
                  {/* Domain name */}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="server"
                    label="Nom de domaine"
                    autoComplete="server"
                    onChange={(e) => saveServer(e.currentTarget.value)}
                    value={server}
                    // error
                    error={undefined !== error?.server}
                    helperText={undefined !== error?.server ? error.server : ''}
                  />
                  {isAuthenticationLoading
                    ? (
                      <Loader size={50} color="secondary"/>
                    )
                    : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                      >
                        Se connecter
                      </Button>
                    )
                  }
                </Box>
              </Box>
            </Container>

          </StyledFormBox>
        </StyledHomeBox>
      </StyledBoxLogin>
    </>
  );
}

Login.propTypes = {
  // input pour l'email de l'utilisateur
  email: PropTypes.string.isRequired,
  saveEmail: PropTypes.func.isRequired,
  // input pour le mot de passe de l'utilisateur
  password: PropTypes.string.isRequired,
  savePassword: PropTypes.func.isRequired,
  // input pour le nom de domaine du tenant Wazo de l'utilisateur
  server: PropTypes.string.isRequired,
  saveServer: PropTypes.func.isRequired,
  // message d'erreur en cas d'echec d'authentification
  error: PropTypes.exact({
    authentication: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    server: PropTypes.string,
  }).isRequired,
  // si l'utilisateur est connecté
  isConnected: PropTypes.bool.isRequired,
  // si l'authentification wazo est en cours de chargement
  isAuthenticationLoading: PropTypes.bool.isRequired,
  saveIsAuthenticationLoading: PropTypes.func.isRequired,
  // permet de lancer l'authetification auprès de wazo
  signInWazo: PropTypes.func.isRequired,
  // tabIndex indique qu'elle onglet sera affiché
  saveTabIndex: PropTypes.func.isRequired,
}

export default Login;