import { sampleContacts } from "../../sampleContacts";
// Actions
import {
  FETCH_CONTACTS,
  saveIsFetchingContacts,
  saveContacts,
  replaceContacts,
  FETCH_TAGS,
  saveTags,
  saveIsFetchingTags,
} from "./actionContacts";

// Affichage / Gestion des erreurs
import { saveSnackbar } from '../snackbar/actionSnackbar';

import { saveAuthToken } from "../login/actionLogin";
import { customFetchApi } from "../../customFetchApi";
import { deleteUselessObjectKeysFromApi } from "../../utils";

const middlewareContacts = (store) => (next) => (action) => {

  const handleErrors = (tryName, error) => {
    const currMessage = error.message ?? 'inconnu';
    const currStatus = error.status ?? error.code ?? 'inconnu';

    store.dispatch(saveSnackbar(`Oups ! Une erreur inatendue s'est produite : message : ${currMessage} ~ status : ${currStatus}`, 'error'));
    console.log(`Oups ! Une erreur inatendue s'est produite : ${tryName} ~ message : ${currMessage} ~ status : ${currStatus}`);
  }

  switch (action.type) {

    case FETCH_CONTACTS:
      const request = async () => {
        try {
          if (store.getState().contacts.contacts.length === 0) {
            console.log('SAVE CONTACTS');
            store.dispatch(saveContacts(sampleContacts))
          } else {
            console.log('REPLACE CONTACTS');
            store.dispatch(replaceContacts(sampleContacts))
          }
        } catch (error) {
          handleErrors('FETCH_CLIENTS - req', error);
        } finally {
          store.dispatch(saveIsFetchingContacts(false))
        }
      }
      store.dispatch(saveIsFetchingContacts(true));
      request();
      next(action);
      break;

    case FETCH_TAGS:
      const getTags = async () => {
        let token = store.getState().login.authToken;

        let params = {
          page: 0,
          start: 0,
          length: 50,
        }
        let allTags = [];
        do {
          try {
            params.page += 1;
            // Options for the fetch request
            const options = {
              method: 'GET',
              headers: {
                'accept': 'application/ld+json',
                'Content-Type': 'application/ld+json', // Specify the content type as JSON
                'Authorization': 'Bearer ' + token,
              },
            };
            const rawTags = await customFetchApi(store, saveAuthToken, `/tags?page=${params.page}`, options, handleErrors);
            
            const tagsJson = await rawTags.json();
            let tags = tagsJson['hydra:member'];
            params.start += tags.length;
            params.length = tagsJson['hydra:totalItems'];
            tags.map((tag) => {
              tag = deleteUselessObjectKeysFromApi(tag);
              return tag
            });

            allTags = allTags.concat(tags);
          } catch (error) {
            handleErrors('FETCH_TAGS - req', error);
            break;
          } finally {
            store.dispatch(saveIsFetchingTags(false))
          }
        } while (params.start < params.length)
        store.dispatch(saveTags(allTags))
      }
      store.dispatch(saveIsFetchingTags(true));
      getTags();
      next(action);
      break;

    default: next(action)
  }
}
export default middlewareContacts;