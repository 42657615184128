// Redux
import { connect } from "react-redux";

// Component
import ClientFolders from "../components/ClientFolders";

// actions
import { 
  fetchFolders, 
  saveIsDisplayedClientFolderDialog, 
  saveFolderDisplayed, 
  fetchFiles, 
  fetchBinders, 
  saveSelectedBinder, 
} from "../redux/clients/actionClients";

const mapStateToProps = (state) => ({
  folders: state.clients.folders,
  isFetchingFolders: state.clients.isFetchingFolders,
})
const mapDispatchToProps = (dispatch) => ({
  fetchFolders: clientId => dispatch(fetchFolders(clientId)),
  saveIsDisplayedClientFolderDialog: (isDisplayedClientFolderDialog) => dispatch(saveIsDisplayedClientFolderDialog(isDisplayedClientFolderDialog)),
  saveFolderDisplayed: (folderDisplayed) => dispatch(saveFolderDisplayed(folderDisplayed)),
  fetchFiles: (folderId) => dispatch(fetchFiles(folderId)),
  fetchBinders: (folderId) => dispatch(fetchBinders(folderId)),
  saveSelectedBinder: (binderId) => dispatch(saveSelectedBinder(binderId)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ClientFolders)