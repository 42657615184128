import {
  DELETE_CONTACTS,
  REPLACE_CONTACTS,
  SAVE_CONTACTS,
  SAVE_IS_FETCHING_CONTACTS,
  SAVE_FETCHING_CONTACTS_ERROR,
  SAVE_TAGS,
  SAVE_IS_FETCHING_TAGS,
} from "./actionContacts";

const initialState = {
  contacts: [],
  isFetchingContacts: false,
  isFetchingContactsError: false,
  tags: [],
  isFetchingTags: false,
}

const reducerContacts = (state = initialState, action) => {
  switch (action.type) {

    // Suppression des contactes
    case DELETE_CONTACTS: {
      return {
        ...state,
        contacts: []
      }
    }

    // Remplacement des contacts dans le state
    case REPLACE_CONTACTS: {
      return {
        ...state,
        contacts: action.data,
      }
    }

    // Sauvegarde des contactes dans le state
    case SAVE_CONTACTS: {
      return {
        ...state,
        contacts: [
          ...state.contacts,
          ...action.data,
        ]
      }
    }

    case SAVE_IS_FETCHING_CONTACTS: {
      return {
        ...state,
        isFetchingContacts: action.isFetchingContacts,
      }
    }

    case SAVE_FETCHING_CONTACTS_ERROR: {
      return {
        ...state,
        isFetchingContactsError: action.isFetchingContactsError,
      }
    }

    // Sauvegarde des tags dans le state
    case SAVE_TAGS: {
      return {
        ...state,
        tags: action.data,
      }
    }

    case SAVE_IS_FETCHING_TAGS: {
      return {
        ...state,
        isFetchingTags: action.isFetchingTags,
      }
    }

    default: return state
  }
}
export default reducerContacts;