import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useParams } from "react-router-dom";
// material
import {
  ThemeProvider,
  createTheme,
  Typography,
} from '@mui/material';
// Styled Component
import { StyledTab, StyledMateriaTabs, StyledBadge } from '../styles/TabsStyle';
import { pages } from '../pages';

const Tabs = ({
  tabsIndex,
  saveTabIndex,
}) => {
  const location = useLocation()
  const routeParams = useParams();
  const clientId = routeParams.clientId;

  const getPage = useCallback(() => {
    return pages(clientId )?.[location.pathname];
  }, [clientId, location.pathname])

  const getTabIndex = useCallback(() => {
    return tabsIndex?.[location.pathname] ?? 0;
  }, [location.pathname, tabsIndex])

  const CustomIconTab = ({ children }) => {
    const theme = createTheme({
      components: {
        'MuiSvgIcon': {
          styleOverrides: {
            root: {
              margin: '0',
            }
          },
        }
      }
    });

    return (<ThemeProvider theme={theme}>{children}</ThemeProvider>)
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  return (
    <StyledMateriaTabs value={getTabIndex() ?? 0} onChange={(e, newTabIndex) => saveTabIndex(location.pathname, newTabIndex)} aria-label="tabs" centered variant="fullWidth" TabIndicatorProps={{ style: { display: 'none' } }}>
      {
        getPage().tabs.map(
          (tab, index) => (
            <StyledTab 
              key={index}
              icon={<CustomIconTab>{tab.icon}</CustomIconTab>}
              {...a11yProps(index)}
              label={(
                <StyledBadge color="error">
                  <Typography sx={{ lineHeight: 'normal'}} variant='h3'>{tab.name}</Typography>      
                </StyledBadge>
              )}
            />
          )
        )
      }
    </StyledMateriaTabs>
  )
}

Tabs.propTypes = {
  // indique sur quel onglet doivent se trouver les pages
  tabsIndex: PropTypes.object.isRequired,
  saveTabIndex: PropTypes.func.isRequired,
}

export default Tabs