import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types'

// mui
import { Tooltip, Typography } from '@mui/material';

const TypographyTooltip = ({
  typographySx,
  children,
  direction,
  client,
  fontWeight,
}) => {
  const defaultFontWeight = fontWeight ?? '550';
  const textElementRef = useRef();
  const [isOverflowed, setIsOverflow] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [textDecoration, setTextDecoration] = useState('none');
  const [hoverFontWeight, setHoverFontWeight] = useState(defaultFontWeight);

  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, [children]);

  // Gestion FontWeight et textdecoration on Hover
  useEffect(() => {
    if (isHovered) {
      setTextDecoration('underline');
      setHoverFontWeight('700');
    } else {
      setTextDecoration('none');
      setHoverFontWeight(defaultFontWeight);
    }
  }, [isHovered, direction, client?.name, defaultFontWeight]);

  // Gestion Popover
  const handleMouseEnter = (e) => {
    setHovered(true);
  };

  return (
    <>
      <Tooltip
        title={children}
        disableHoverListener={!isOverflowed}
        arrow
      >
        <Typography
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setHovered(false)}
          // onClick={openPopOver}
          variant='body1'
          noWrap
          ref={textElementRef}
          sx={{
            display: 'inline-block',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
            fontWeight: hoverFontWeight,
            textDecoration: textDecoration,
            ...typographySx,
          }}
        >
          {children}
        </Typography>

      </Tooltip>
    </>
  );
};

TypographyTooltip.propTypes = {
  // pour modifier le style de la Typography
  typographySx: PropTypes.object,
  client: PropTypes.exact({
    activities: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    callNumber: PropTypes.string.isRequired,
    mobileNumber: PropTypes.string.isRequired,
    contact: PropTypes.string,
    companyName: PropTypes.string,
    companyUrl: PropTypes.string,
    contacts: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
    customer: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
  }).isRequired,
  direction: PropTypes.string.isRequired,
  fontWeight: PropTypes.number,
}

export default TypographyTooltip;