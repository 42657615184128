// React redux
import { connect } from "react-redux";

// Component
import CallsSearchDialog from "../components/CallsSearchDialog";

// Actions
import { saveIsDisplayedCallsSearchDialog, saveCallFilters, deleteFilter } from "../redux/calls/actionCalls";

const mapStateToProps = (state) => ({
  isDisplayedCallsSearchDialog: state.calls.isDisplayedCallsSearchDialog,
  clients: state.clients.clients,
  tabIndex: state.tabs.tabIndex[state.tabs.currentPageName],
  users: state.users.users,
})

const mapDispatchToProps = (dispatch) => ({
  saveIsDisplayedCallsSearchDialog: (isDisplayedCallsSearchDialog) => dispatch(saveIsDisplayedCallsSearchDialog(isDisplayedCallsSearchDialog)),
  saveCallFilters: (defaultFilters, filters, currentPageName) => dispatch(saveCallFilters(defaultFilters, filters, currentPageName)),
  deleteFilter: (currentPageName) => dispatch(deleteFilter(currentPageName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CallsSearchDialog)