// React
import React from 'react'

// Proptypes
import PropTypes from 'prop-types'

// MUI
import { Button } from '@mui/material';

const HistoryCallModalPriorityButton = ({
  historyCall,
  setHistoryCall,
  color,
  newPriority,
  children,
  error,
  setError,
  readOnly,
}) => {
  const handleOnClickHistoryCallModalPriorityButton = (newPriority) => {
    setError({ ...error, priority: undefined})
    setHistoryCall({ ...historyCall, priority: newPriority})
  }  
  
  return (
    <Button 
      sx={{
        width: `calc(100% / 3)`,
        margin: '0!important',
        cursor: !readOnly ? 'pointer' : 'not-allowed',
        border: `1px solid`,
        borderColor: `${color}.dark`,
        borderRightWidth: '0px!important',
        '&:last-of-type': {
          border: `1px solid`,
          borderColor: `${color}.dark`,
          borderRightWidth: '1px!important',
        }
      }}
      variant={historyCall.priority === newPriority 
        ? 'contained' 
        : 'outlined'
      }
      color={color} 
      onClick={() => !readOnly && handleOnClickHistoryCallModalPriorityButton(newPriority)}
    >
      {children}
    </Button>
  )
}

HistoryCallModalPriorityButton.propTypes = {
  // historique d'un appel
  historyCall: PropTypes.exact({
    id: PropTypes.string.isRequired,
    // object de l'historique
    object: PropTypes.string.isRequired,
    // contenue de l'historique
    description: PropTypes.string.isRequired,
    // indique si l'historique est urgent, a rappeler ou normal
    priority: PropTypes.number.isRequired,
    // collabateur en charge de l'historique
    collaboratorUuid: PropTypes.string.isRequired,
    // status de l'historique : HISTORY_STATUS_OPENED, HISTORY_STATUS_CLOSED
    status: PropTypes.number.isRequired,
    // date de la derniere mise à jour de l'historique
    updatedAt: PropTypes.exact({
      seconds: PropTypes.number.isRequired,
      nanoseconds: PropTypes.number.isRequired,
    }).isRequired,
    // identifiant du dossier genapi
    folderId: PropTypes.number.isRequired,
  }).isRequired,
  setHistoryCall: PropTypes.func.isRequired,
  // color
  color: PropTypes.string.isRequired,
  // indique si l'historique est urgent, a rappeler ou normal 
  newPriority: PropTypes.number.isRequired,
  // Label du bouton
  children: PropTypes.string.isRequired,
  // permet de definir le message d'erreur lié a l'absence de collaborateur
  error: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  // si le select est modifiable ou non
  readOnly: PropTypes.bool.isRequired,
}

export default HistoryCallModalPriorityButton;