import React from 'react'
import PropTypes from 'prop-types'
// material
import { 
  TextField,
  Autocomplete,
  CircularProgress,
  Paper,
} from '@mui/material';

const HistoryCallModalSelectCollaborator = ({
  users,
  historyCall,
  setHistoryCall,
  isFetchingUsers,
  error,
  setError,
  readOnly,
}) => {
  const currentCollaborator = users.filter((user) => user.uuid === historyCall.collaboratorUuid)[0] ?? null;

  const options = users.map((option) => {
    const firstLetter = option.firstname[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) 
        ? '0-9'
        : firstLetter,
        ...option,
    };
  });

  // rajoute le hover quand on passe la souris sur une option
  // le collaboteur selectionné sera aussi en couleur
  const CustomPaper = ({ children }) => {
    return (
      <Paper 
        sx={{
          "& .MuiAutocomplete-listbox": {
            "& .MuiAutocomplete-option[aria-selected='true']": {
              bgcolor: "secondary.main",
              "&.Mui-focused": {
                bgcolor: "secondary.dark",
              }
            }
          },
          "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
            bgcolor: "secondary.light",
          }
        }}
      >
        {children}
      </Paper>
    );
  }

  return (
    <Autocomplete
      fullWidth
      value={currentCollaborator}
      onChange={(e, collaborator) => {
        setError({ ...error, collaboratorUuid: undefined })
        setHistoryCall({ ...historyCall, collaboratorUuid: collaborator?.uuid ?? '' })
      }}
      isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      getOptionLabel={(option) => `${option.firstname ?? ''} ${option.lastname ?? ''}`}
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option) => option.firstLetter}
      loading={isFetchingUsers}
      autoSelect
      readOnly={readOnly}
      PaperComponent={CustomPaper}
      renderInput={(params, option) => (
        <TextField
          {...params}
          label="Collaborateur en charge"
          sx={{ 
            '&:hover .MuiInputBase-root': { cursor: !readOnly ? 'text' : 'not-allowed' },
            '&:hover .MuiSvgIcon-root': { cursor: !readOnly ? 'text' : 'not-allowed' },
            input : { cursor: !readOnly ? 'text' : 'not-allowed' }, 
            label: { cursor: !readOnly ? 'text' : 'not-allowed', background: 'white', padding: '0 4px' },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetchingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

HistoryCallModalSelectCollaborator.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.exact({
      uuid: PropTypes.string.isRequired,
      // email de l'utilisateur
      email: PropTypes.string,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string,
      // numéros de ligne appartenant à l'utilisateur
      lines: PropTypes.arrayOf(
        PropTypes.string.isRequired,
      ).isRequired,
      // si la licence CTI est activé
      isActive: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  // historique d'un appel
  historyCall: PropTypes.exact({
    id: PropTypes.string.isRequired,
    // object de l'historique
    object: PropTypes.string.isRequired,
    // contenue de l'historique
    description: PropTypes.string.isRequired,
    // indique si l'historique est urgent, a rappeler ou normal
    priority: PropTypes.number.isRequired,
    // collabateur en charge de l'historique
    collaboratorUuid: PropTypes.string.isRequired,
    // status du ticket : 0=HISTORY_STATUS_OPENED, 1=HISTORY_STATUS_CLOSED
    status: PropTypes.number.isRequired,
    // date de la derniere mise à jour de l'historique
    updatedAt: PropTypes.exact({
      seconds: PropTypes.number.isRequired,
      nanoseconds: PropTypes.number.isRequired,
    }).isRequired,
    // identifiant du dossier genapi
    folderId: PropTypes.number.isRequired,
  }).isRequired,
  // permet de definir le message d'erreur lié a l'absence de collaborateur
  error: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  // si le select est modifiable ou non
  readOnly: PropTypes.bool.isRequired,
}

export default HistoryCallModalSelectCollaborator;
