// React redux
import { connect } from "react-redux";

// Components
import ClientCalls from "../components/ClientCalls";

// Actions
import {
  fetchCallsByClientId,
  fetchMoreCallsByClientId,
  searchCalls,
  deleteCallsByClientId,
  addMoreCallPeriod,
} from "../redux/calls/actionCalls";

const mapStateToProps = (state) => ({
  callsByClientId: state.calls.callsByClientId,
  isFetchingCalls: state.calls.isFetchingCalls,
  currentPageName: state.tabs.currentPageName,
  filters: state.calls.filters,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCallsByClientId: clientId => dispatch(fetchCallsByClientId(clientId)),
  fetchMoreCallsByClientId: clientId => dispatch(fetchMoreCallsByClientId(clientId)),
  searchCalls: (loadMore) => dispatch(searchCalls(loadMore)),
  deleteCallsByClientId: () => dispatch(deleteCallsByClientId()),
  addMoreCallPeriod: (currentPageName) => dispatch(addMoreCallPeriod(currentPageName)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientCalls)