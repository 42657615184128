// React redux
import { connect } from "react-redux";

// Components
import Login from "../components/Login";

// Actions
import { 
    saveEmail,
    savePassword,
    saveServer,
    signInWazo,
    saveIsAuthenticationLoading,
} from "../redux/login/actionLogin";
import { saveTabIndex } from "../redux/tabs/actionTabs";

const mapStateToProps = (state) => ({
    email: state.login.email,
    password: state.login.password,
    server: state.login.server,
    error: state.login.error,
    isConnected: state.login.isConnected,
    isAuthenticationLoading: state.login.isAuthenticationLoading,
})

const mapDispatchToProps = (dispatch) => ({
    saveEmail: (email) => dispatch(saveEmail(email)),
    savePassword: (password) => dispatch(savePassword(password)),
    saveServer: (server) => dispatch(saveServer(server)),
    saveIsAuthenticationLoading: (isAuthenticationLoading) => dispatch(saveIsAuthenticationLoading(isAuthenticationLoading)),
    signInWazo: (email, password, server) => dispatch(signInWazo(email, password, server)),
    saveTabIndex: (currentPageName, tabIndex) => dispatch(saveTabIndex(currentPageName, tabIndex)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)